import React, { ReactNode } from "react";
import DrawerBar from "../Navigation/DrawerBar";
import { BaseContainer, ContentContainer } from "./Base.style";

export type BaseProps = {
    children: ReactNode;
};

export default function Base({ children }: BaseProps) {
    return (
        <BaseContainer>
            <DrawerBar />
            <ContentContainer>{children}</ContentContainer>
        </BaseContainer>
    );
}

export const base = (Component: React.ComponentType) => (props: BaseProps) =>
    (
        <Base {...props}>
            <Component />
        </Base>
    );
