import "../../css/ipa_chart_vowels.css";
import { Grid, Typography } from "@mui/material";
import Image from "../../Shared/Images/vowel_space_Chrome.png";

type VowelProps = {
    setValue: (i: string) => void;
};
export default function Vowels({ setValue }: VowelProps) {
    return (
        <Grid container>
            <div
                className="bckg"
                style={{
                    background: `url(${Image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    height: "100%",
                    borderWidth: 0
                }}>
                <table className="table_plain_vs">
                    <body>
                        <tr>
                            <td className="vysm5" colSpan={9} style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>

                            <td className="vdescr7" colSpan={8}>
                                <div>
                                    <Typography>fremre</Typography>
                                </div>
                            </td>
                            <td className="vysm5" colSpan={6} style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>

                            <td className="vdescr7" colSpan={10}>
                                <div>
                                    <Typography>midtre</Typography>
                                </div>
                            </td>
                            <td className="vysm5" colSpan={12} style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vdescr7" colSpan={8}>
                                <div>
                                    <Typography>bakre</Typography>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="vdescr5" colSpan={4}>
                                <div className="sym_descr_v">
                                    <Typography className="symSL2">Trang</Typography>
                                </div>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vsym3" colSpan={1} onClick={() => setValue("i")}>
                                <div className="symSR2">
                                    <Typography>i,</Typography>
                                </div>
                            </td>
                            <td className="vsym3" colSpan={1} onClick={() => setValue("iː")}>
                                <div className="symSL2">
                                    <Typography>iː</Typography>
                                </div>
                            </td>
                            <td className="vysm" colSpan={2} style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vsym3" colSpan={1} onClick={() => setValue("y")}>
                                <div className="symSR2">
                                    <Typography>y,</Typography>
                                </div>
                            </td>
                            <td className="vsym3" colSpan={3} onClick={() => setValue("yː")}>
                                <div className="symSL2">
                                    <Typography>yː</Typography>
                                </div>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" colSpan={2} style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            s
                            <td className="vsym3" colSpan={2} onClick={() => setValue("ʉ")}>
                                <div className="symSL2">
                                    <Typography>ʉ,</Typography>
                                </div>
                            </td>
                            <td className="vsym3" colSpan={2} onClick={() => setValue("ʉː")}>
                                <div className="symSR2">
                                    <Typography>ʉː</Typography>
                                </div>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vsym3" colSpan={3} onClick={() => setValue("u")}>
                                <div className="symSR2">
                                    <Typography>u,</Typography>
                                </div>
                            </td>
                            <td className="vsym3" colSpan={4} onClick={() => setValue("uː")}>
                                <div className="symSL2">
                                    <Typography>uː</Typography>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="vdescr" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                        </tr>
                        <tr>
                            <td className="vdescr9" colSpan={9} style={{ borderWidth: 0 }}>
                                <div className="sym_descr_v">
                                    <Typography className="symSL2">Halvtrang</Typography>
                                </div>
                            </td>
                            <td className="vdescr" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vdescr" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" colSpan={3} style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" colSpan={3} style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vdescr" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" colSpan={3} style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vdescr" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                        </tr>
                        <tr>
                            <td className="vdescr" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" colSpan={3} onClick={() => setValue("e")} style={{ borderWidth: 0 }}>
                                <div className="symSR2">
                                    <Typography>e,</Typography>
                                </div>
                            </td>
                            <td className="vysm3" colSpan={3} onClick={() => setValue("eː")} style={{ borderWidth: 0 }}>
                                <div className="symSL2">
                                    <Typography>eː</Typography>
                                </div>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" colSpan={3} onClick={() => setValue("ø")} style={{ borderWidth: 0 }}>
                                <div className="symSR2">
                                    <Typography>ø,</Typography>
                                </div>
                            </td>
                            <td className="vysm3" colSpan={2} onClick={() => setValue("øː")} style={{ borderWidth: 0 }}>
                                <div className="symSL2">
                                    <Typography>øː</Typography>
                                </div>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" style={{ borderWidth: 0 }} colSpan={3} onClick={() => setValue("ə")}>
                                <div className="symS">
                                    <Typography>ə</Typography>
                                </div>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" style={{ borderWidth: 0 }} onClick={() => setValue("o")}>
                                <div className="symSL2">
                                    <Typography>o,</Typography>
                                </div>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" style={{ borderWidth: 0 }} onClick={() => setValue("oː")}>
                                <div className="symSR2">
                                    <Typography>oː</Typography>
                                </div>
                            </td>
                        </tr>
                        <tr style={{ borderWidth: 0 }}>
                            <td className="vdescr9" style={{ borderWidth: 0 }} colSpan={9}>
                                <div className="sym_descr_v">
                                    <Typography className="symSL2">Halvåpen</Typography>
                                </div>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" style={{ borderWidth: 0 }} colSpan={3}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" style={{ borderWidth: 0 }} colSpan={3}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" style={{ borderWidth: 0 }} colSpan={3}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" style={{ borderWidth: 0 }} colSpan={3}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                        </tr>
                        <tr>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" colSpan={3} style={{ borderWidth: 0 }} onClick={() => setValue("æ")}>
                                <Typography className="symSR2">æ,</Typography>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" colSpan={3} style={{ borderWidth: 0 }} onClick={() => setValue("æː")}>
                                <Typography className="symSL2">æː</Typography>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm3" style={{ borderWidth: 0 }} colSpan={2}>
                                &nbsp;
                            </td>

                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                        </tr>
                        <tr>
                            <td className="vdescr9" style={{ borderWidth: 0 }} colSpan={9}>
                                <div className="sym_descr_v">
                                    <Typography className="symSL2">Åpen</Typography>
                                </div>
                            </td>

                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>

                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>

                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vsym3" style={{ borderWidth: 0 }} colSpan={7} onClick={() => setValue("ɑ")}>
                                <Typography className="symSR2">ɑ,</Typography>
                            </td>

                            <td className="vsym3" style={{ borderWidth: 0 }} colSpan={7} onClick={() => setValue("ɑː")}>
                                <Typography className="symSL2">ɑː</Typography>
                            </td>
                        </tr>
                    </body>
                </table>
                <br />
                <br />
                <br />
                <table>
                    <body>
                        <tr>
                            <td className="vdescr9" style={{ borderWidth: 0 }} colSpan={9}>
                                <div className="sym_descr_v">
                                    <Typography className="symSR2">Diftonger: </Typography>
                                </div>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vsym3" style={{ borderWidth: 0 }} colSpan={4} onClick={() => setValue("øy")}>
                                <Typography className="symS2">øy,</Typography>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td
                                className="vsym3"
                                style={{ borderWidth: 0 }}
                                colSpan={4}
                                onClick={() => setValue(" æi")}>
                                <Typography className="symS2"> æi,</Typography>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vsym3" style={{ borderWidth: 0 }} colSpan={4} onClick={() => setValue("æʉ")}>
                                <Typography className="symS2"> æʉ,</Typography>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vsym3" style={{ borderWidth: 0 }} colSpan={4} onClick={() => setValue("oy")}>
                                <Typography className="symS2"> oy,</Typography>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vsym3" colSpan={4} onClick={() => setValue("ui")}>
                                <Typography className="symS2"> ui,</Typography>
                            </td>
                            <td className="vysm" style={{ borderWidth: 0 }}>
                                &nbsp;
                            </td>
                            <td className="vsym3" colSpan={4} onClick={() => setValue("ʉi")}>
                                <Typography className="symS2"> ʉi</Typography>
                            </td>
                        </tr>
                    </body>
                </table>
            </div>
        </Grid>
    );
}
