import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Transcript from "./Transcript";
import Context from "./Context";
import TestService from "../../../Services/TestService";
import { TestSteps } from "../../../utils/StepperFunctions";
import { Grid, Stepper, Step, StepLabel, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../../theme";

const useStyles = makeStyles({
    contentRoot: (props) => ({
        width: "100em",
        height: "100em"
    }),
    stepper: (props) => ({
        padding: DnfTheme.spacing(2),
        justifyItems: "right",
        marginBottom: 20,
        maxWidth: "100em"
    }),

    paper: (props) => ({
        backgroundColor: "rgba(96,106,179,0.27)",
        padding: "10px",
        border: "0.5px",
        borderColor: "black"
    }),
    transcript: (props) => ({
        backgroundColor: "rgba(114,110,110,0.09)",
        borderBlockColor: "black",
        borderWidth: "12px",
        align: "bottom"
    }),

    cancel: (props) => ({
        backgroundColor: "rgba(238,50,17,0.95)",
        height: "50px",
        width: "100%"
    }),
    text: (props) => ({
        marginLeft: DnfTheme.spacing(3),
        fontSize: 18
    }),

    typography: (props) => ({
        fontSize: "14px",
        color: "#3f50b5",
        padding: "3px",
        paddingLeft: 0
    })
});

export default function Content() {
    const classes = useStyles();
    const steps = TestSteps();
    const [cookies, ,] = useCookies(["jwt", "id_sp"]);
    const [activeStep, setActiveStep] = useState(2);
    const [testInfo, setTestInformation] = useState();
    const [loaded, setLoaded] = useState(false);

    // TODO: This is called everytime `loaded` changes.. why?
    useEffect(() => {
        const testDetails = async () => {
            const id_test = parseInt(localStorage.getItem("id_test") ?? "");
            if (!Number.isNaN(id_test)) {
                TestService.getTestInformation(id_test, cookies.jwt)
                    .then((res) => {
                        if (res.status === 200) {
                            setTestInformation(res.data[0]);
                            setLoaded(true);
                        } else {
                            console.log("Failed retrieving test info");
                        }
                    })
                    .catch((err) => console.log(err));
            } else {
                throw new Error("No active test");
            }
        };
        testDetails();
    }, [cookies.id_sp, cookies.jwt, loaded]);

    return (
        <Context>
            <Grid item lg={9} md={6} xs={3} className={classes.stepper}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            <Grid>
                {loaded && testInfo ? (
                    <div className={classes.contentRoot}>
                        <Grid container direction="row">
                            <Grid item>
                                <Transcript testInfo={testInfo} setActiveStep={setActiveStep} />
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    <Grid>
                        <Skeleton variant="rectangular" />
                    </Grid>
                )}
            </Grid>
        </Context>
    );
}
