import React, { useState } from "react";
import SpeechTherapistService from "../../Services/SpeechTherapistService";
import { LoginText } from "../../text/LoginText";
import { Backdrop, Button, CircularProgress, Divider, Grid, Link, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../theme";
import { useCookies } from "react-cookie";

const useStyles = makeStyles({
    root: (props) => ({
        height: "100vh"
    }),
    backdrop: (props) => ({
        zIndex: DnfTheme.zIndex.drawer + 1,
        color: "#fff"
    }),
    typography: (props) => ({
        fontFamily: DnfTheme.typography.fontFamily,
        color: "#fff",
        textAlign: "center",
        fontSize: 40
    }),
    avatar: (props) => ({
        margin: DnfTheme.spacing(1),
        backgroundColor: DnfTheme.palette.secondary.main
    }),
    form: (props) => ({
        width: "100%",
        marginTop: DnfTheme.spacing(1),
        color: "#fff"
    }),
    cssLabel: (props) => ({
        color: "#fff"
    }),
    cssOutlinedInput: (props) => ({
        color: "#fff",
        "&$cssFocused $notchedOutline": {
            borderColor: "#fff",
            color: "#fff"
        }
    }),
    cssFocused: (props) => ({
        color: "#fff"
    }),
    notchedOutline: (props) => ({
        borderWidth: "1px",
        borderColor: "white !important"
    }),
    divider: (props) => ({
        alignment: "center",
        color: "#000",
        length: 5
    }),
    submit: (props) => ({
        margin: DnfTheme.spacing(3, 0, 2)
    }),
    grid: (props) => ({
        backgroundColor: "#0c1a2d"
    })
});

/**
 * Page for resetting password. NOT WORKING.
 * */
export default function NewPassword(params) {
    const style = useStyles();
    const [email, setEmail] = useState("");
    const [open, setOpen] = useState(false);
    const [password, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [id] = useState("");
    const [firstname] = useState("");
    const [lastname] = useState("");
    const [cookies] = useCookies(["jwt"]);

    const resetPassword = async () => {
        if (id !== "" && password === password2) {
            SpeechTherapistService.editUser(id, email, firstname, lastname, password, cookies.jwt)
                .then(() => {
                    setOpen(true);
                })
                .catch((err) => console.log(err));
        }
    };

    return (
        <div>
            <Typography component="h1" variant="h5" className={style.typography}>
                {LoginText.info.newPasswordTitle}
            </Typography>
            <br />
            <Divider className={style.divider} />
            <br />
            <form className={style.form} noValidate>
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    label="Skriv inn email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    InputLabelProps={{
                        classes: {
                            root: style.cssLabel,
                            focused: style.cssFocused
                        }
                    }}
                    InputProps={{
                        classes: {
                            root: style.cssOutlinedInput,
                            focused: style.cssFocused
                            // outlined: style.notchedOutline,
                        }
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    label="Nytt passord"
                    name="password"
                    autoComplete="password"
                    autoFocus
                    InputLabelProps={{
                        classes: {
                            root: style.cssLabel,
                            focused: style.cssFocused
                        }
                    }}
                    InputProps={{
                        classes: {
                            root: style.cssOutlinedInput,
                            focused: style.cssFocused
                            // outlined: style.notchedOutline,
                        }
                    }}
                    onChange={(e) => setPassword1(e.target.value)}
                />
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Gjenta passord"
                    type="password"
                    autoComplete="current-password"
                    InputLabelProps={{
                        classes: {
                            root: style.cssLabel,
                            focused: style.cssFocused
                        }
                    }}
                    InputProps={{
                        classes: {
                            root: style.cssOutlinedInput,
                            focused: style.cssFocused
                            // notchedOutline: style.notchedOutline,
                        }
                    }}
                    onChange={(e) => setPassword2(e.target.value)}
                />
                <div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={style.submit}
                        onClick={resetPassword}>
                        {LoginText.buttons.newPassword}
                    </Button>
                    <Backdrop className={style.backdrop} open={open}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
                <Grid container>
                    <Grid item xs>
                        <Link
                            variant="body2"
                            style={{ color: "#fff", cursor: "pointer" }}
                            onClick={() => params.setLogin(true)}>
                            <Typography>{LoginText.links.login}</Typography>
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}
