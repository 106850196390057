import { useReducer, useState } from "react";
import { useCookies } from "react-cookie";
import { NavText } from "../../text/NavText";
import Routes from "./Routes";
import DialogContainer from "../../containers/CustomDialog";
import { Divider, Drawer, List, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { DnfTheme } from "../../theme";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useHistory } from "react-router-dom";
import MuiListItemButton from "@mui/material/ListItemButton";

const drawerWidth = 240;

const useStyles = makeStyles({
    root: (props) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        backgroundColor: "#0c1a2d",
        color: "#ffffff",
        fontFamily: "Arial, sans-serif"
    }),
    toolbar: (props) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "30px",
        // necessary for content to be below app bar
        ...DnfTheme.mixins.toolbar
    }),
    home: (props) => ({
        color: "#249691",
        fontSize: " 90px",
        cursor: "pointer"
    }),
    divider: (props) => ({
        borderColor: "#964e24",
        width: "80%",
        borderWidth: "2px",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "8%"
    }),

    selected: (props) => ({
        backgroundColor: "#fff",
        color: "#0c1a2d",
        borderRadius: "60px 0px 0px 60px",
        marginLeft: 10,
        padding: 10
    })
});

const ListItemButton = withStyles({
    root: {
        color: "rgba(255,255,255,0.75)",
        "&$selected": {
            backgroundColor: "#fff",
            color: "#0c1a2d",
            borderRadius: "60px 0px 0px 60px",
            marginLeft: 10,
            padding: 10,
            "& .MuiListItemIcon-root": {
                backgroundColor: "#fff",
                color: "#0c1a2d",
                borderRadius: "60px 0px 0px 60px"
            }
        },
        "&$selected:hover": {
            backgroundColor: "#fff",
            color: "#0c1a2d",
            borderRadius: "60px 0px 0px 60px",
            marginLeft: 10,
            padding: 10,
            "& .MuiListItemIcon-root": {
                backgroundColor: "#fff",
                color: "#0c1a2d",
                borderRadius: "60px 0px 0px 60px"
            }
        },
        "&$selected:active": {
            backgroundColor: "#fff",
            color: "#0c1a2d",
            borderRadius: "60px 0px 0px 60px",
            marginLeft: 10,
            padding: 10,
            "& .MuiListItemIcon-root": {
                backgroundColor: "#fff",
                color: "#0c1a2d",
                borderRadius: "60px 0px 0px 60px"
            }
        },
        "&$selected:focus": {
            backgroundColor: "#fff",
            color: "#0c1a2d",
            borderRadius: "60px 0px 0px 60px",
            marginLeft: 10,
            padding: 10,
            "& .MuiListItemIcon-root": {
                backgroundColor: "#fff",
                color: "#0c1a2d",
                borderRadius: "60px 0px 0px 60px"
            }
        },
        "&:hover": {
            backgroundColor: "#fff",
            color: "#0c1a2d",
            borderRadius: "60px 0px 0px 60px",
            marginLeft: 10,
            padding: 10,
            "& .MuiListItemIcon-root": {
                backgroundColor: "#fff",
                color: "#0c1a2d",
                borderRadius: "60px 0px 0px 60px"
            }
        }
    },
    selected: {}
})(MuiListItemButton);

/**
 * Navigation bar.
 */

let initialState = { selected: 0 };

const reducer = (state, action) => {
    switch (action.type) {
        case "home":
            return { selected: (state.selected = 0) };
        case "test":
            return { selected: (state.selected = 1) };
        case "analyze":
            return { selected: (state.selected = 2) };
        case "manual":
            return { selected: (state.selected = 3) };
        default:
            return state.selected;
    }
};

export default function DrawerBar() {
    const classes = useStyles();
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [, , removeCookies] = useCookies(["jwt", "id_sp"]);

    //Handle user logout. Deletes cookies and clear localstorage.
    function logOut() {
        removeCookies("jwt", { path: "/" });
        removeCookies("id_sp", { path: "/" });
        localStorage.clear();
        window.location.reload();
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: classes.root
            }}>
            <div
                className={classes.toolbar}
                onClick={() => {
                    history.push("/home");
                }}>
                <Typography className={classes.home}>DNF</Typography>
            </div>
            <Divider className={classes.divider} />
            <List style={{ marginTop: 30 }}>
                <ListItemButton
                    onClick={() => {
                        dispatch({ type: "home" });
                        if (state.selected === Routes[0].active) {
                            history.push(Routes[Routes[0].active].path);
                        }
                        history.push(Routes[Routes[0].active].path);
                    }}
                    key={Routes[0].active}
                    selected={state.selected === 0}>
                    <ListItemIcon
                        style={state.selected === 0 ? { color: "#0c1a2d" } : { color: "rgba(255,255,255,0.75)" }}>
                        {Routes[0].icon}
                    </ListItemIcon>
                    <ListItemText>{Routes[0].sidebarName}</ListItemText>
                </ListItemButton>
                <ListItemButton
                    onClick={() => {
                        dispatch({ type: "test" });
                        if (state.selected === Routes[1].active) {
                            history.push(Routes[Routes[1].active].path);
                        }
                        history.push(Routes[Routes[1].active].path);
                    }}
                    key={Routes[1].active}
                    selected={state.selected === 1}>
                    <ListItemIcon
                        style={state.selected === 1 ? { color: "#0c1a2d" } : { color: "rgba(255,255,255,0.75)" }}>
                        {Routes[1].icon}
                    </ListItemIcon>
                    <ListItemText>{Routes[1].sidebarName}</ListItemText>
                </ListItemButton>
                <ListItemButton
                    onClick={() => {
                        dispatch({ type: "analyze" });
                        if (state.selected === Routes[2].active) {
                            localStorage.removeItem("id_test");
                            history.push(Routes[Routes[2].active].path);
                        }
                        history.push(Routes[Routes[2].active].path);
                    }}
                    key={Routes[2].active}
                    selected={state.selected === 2}>
                    <ListItemIcon
                        style={state.selected === 2 ? { color: "#0c1a2d" } : { color: "rgba(255,255,255,0.75)" }}>
                        {Routes[2].icon}
                    </ListItemIcon>
                    <ListItemText>{Routes[2].sidebarName}</ListItemText>
                </ListItemButton>
                <ListItemButton
                    onClick={() => {
                        dispatch({ type: "manual" });
                        if (state.selected === Routes[3].active) {
                            history.push(Routes[Routes[3].active].path);
                        }
                        localStorage.removeItem("test");
                    }}
                    key={Routes[3].active}
                    selected={state.selected === 3}>
                    <ListItemIcon
                        style={state.selected === 3 ? { color: "#0c1a2d" } : { color: "rgba(255,255,255,0.75)" }}>
                        {Routes[3].icon}
                    </ListItemIcon>
                    <ListItemText>{Routes[3].sidebarName}</ListItemText>
                </ListItemButton>
                <ListItemButton onClick={() => setOpenDialog(true)}>
                    <ListItemIcon>
                        <PowerSettingsNewIcon style={{ color: "red" }} />
                    </ListItemIcon>
                    <ListItemText style={{ color: "red" }}>{NavText.buttons.logout}</ListItemText>
                </ListItemButton>
            </List>

            <DialogContainer
                isOpen={openDialog}
                handleClose={() => setOpenDialog(false)}
                extraFunc={logOut}
                extraFuncText={NavText.dialog.yes}
                title={NavText.dialog.title}
                text={NavText.dialog.text}
                buttonTextList={[NavText.dialog.no]}
            />
        </Drawer>
    );
}
