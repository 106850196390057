import { useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../theme";
import { ProcessAnalytics } from "../../mapping/norwegian/ProcessAnalytics";
import { ResultData } from "src/model/ResultData";
import ProcessTables from "./ProcessTables";
import { QuestionData, TestParticipantInfo } from "../Test/Therapist/Context";
import { PhoneticProcess, Process } from "src/model/PhoneticProcess";

const useStyles = makeStyles({
    root: (props) => ({
        padding: DnfTheme.spacing(2),
        marginLeft: "-20%",
        margin: DnfTheme.spacing(2),
        justifyContent: "center",
        alignContent: "center"
    }),
    acc: (props) => ({
        backgroundColor: "rgba(241,241,241,0.56)",
        width: "150%"
    }),
    cellHeader: (props) => ({
        background: "rgb(36, 150, 145)"
    }),
    cell: (props) => ({
        padding: 0,
        width: "33.33%"
    }),
    body: (props) => ({
        background: "rgba(241,241,241,1)",
        verticalAlign: "top"
    }),
    button: (props) => ({
        color: "#0c1a2d",
        "&:focus": {
            backgroundColor: "#3c469f",
            color: "#fff"
        }
    }),

    choice_button: (props) => ({
        color: "#fff",
        backgroundColor: "#4c4f53",
        "&:focus": {
            backgroundColor: "#3c469f",
            color: "#fff"
        }
    }),
    header: (props) => ({
        fontWeight: "normal",
        fontFamily: "sans-serif",
        fontSize: 25,
        color: "rgba(0,0,0,0.87)"
    }),
    matrixButton: (props) => ({
        backgroundColor: "rgb(36, 150, 145)",
        color: "#fff",
        marginTop: 25,
        marginRight: 5,
        borderRadius: 10
    }),
    cancel: (props) => ({
        backgroundColor: "#ff0000",
        color: "#fff",
        marginTop: 25,
        margin: 5,
        borderRadius: 10
    }),
    text: (props) => ({
        fontFamily: "sans-serif",
        marginTop: 25,
        marginRight: 5
    })
});
type ConfusionProps = {
    testInfo?: TestParticipantInfo;
    testToAnalyze: QuestionData[];
};
export default function Confusion({ testInfo, testToAnalyze }: ConfusionProps) {
    const classes = useStyles();
    const [cookies, ,] = useCookies(["jwt"]);
    const test_id = localStorage.getItem("test") || localStorage.getItem("analyze"); // OK
    const [loading, setLoading] = useState(true);
    const [noResults, setNoResults] = useState(true);
    // const [openInitial, setOpenInitial] = useState(false);
    // const [openMedial, setOpenMedial] = useState(false);
    // const [openFinal, setOpenFinal] = useState(false);
    // const [openVowel, setopenVowel] = useState(false);
    const [expand, setExpand] = useState({
        open: false,
        processNumber: -1
    });

    const [results, setResults] = useState<any[]>([]);

    // const history = useHistory();

    useEffect(() => {
        setLoading(true);
        setNoResults(true);

        if (testToAnalyze) {
            setLoading(false);
            if (testToAnalyze.length > 0) {
                const answersOnly: QuestionData[] = testToAnalyze.filter((value) => value.answer !== "");
                if (answersOnly.length > 0) {
                    console.log();

                    setResults(answersOnly);
                    setNoResults(false);
                }
            } else {
                setNoResults(true);
                throw console.error("no result registered");
            }
        } else {
            setLoading(false);
            setNoResults(true);
            throw console.error("failed retriving results");
        }

    }, [cookies.jwt, test_id, testToAnalyze]);


    const analyzeProcess = (phoneticSet: Process[], data: ResultData): Process[] => {
        let analyze = new ProcessAnalytics(phoneticSet, data);
        return analyze.setProcessConfusion();
    };

    const phoneticSet = useMemo(() => {
        return new PhoneticProcess();
    }, []);

    const doPhonetictSet = useMemo(() => {
        const ps = phoneticSet.constructPhoneticProcessSet();

        setLoading(true);
        if (results.length === 0) {
            setNoResults(true);
            return ps;
        } else {
            const doWork = () => {
                results.forEach((value) => {
                    const {
                        id_question,
                        word,
                        orthographic_transcription,
                        phonetic_transcription,
                        image_link,
                        test_id,
                        animation_link,
                        answer_id,
                        answer,
                        ort_answer,
                        video_id,
                        correct,
                        imitation,
                        question_id
                    }: ResultData = value;

                    return analyzeProcess(ps, {
                        id_question,
                        word,
                        orthographic_transcription,
                        phonetic_transcription,
                        image_link,
                        test_id,
                        animation_link,
                        answer_id,
                        answer,
                        ort_answer,
                        video_id,
                        correct,
                        imitation,
                        question_id
                    });
                });
                setLoading(false);
                return ps;
            };
            return doWork();
        }
    }, [results, phoneticSet]);

    const handleExpand = (i: number) => {
        setExpand({ open: false, processNumber: -1 });
        setExpand({
            open: true,
            processNumber: i
        });
    };

    return (
        <>
            {loading ? (
                <Grid item>
                    <CircularProgress />
                </Grid>
            ) : noResults ? (
                <Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        justifyItems={"center"}>
                        <Grid item>
                            <Typography>Finner ingen resultater...</Typography>
                            <Button></Button>
                        </Grid>
                    </Grid>
                </Grid>
            ) : !doPhonetictSet ? (
                <Grid>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item>
                            <Typography>Ingen prosesser å vise...</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid container className={classes.root}>
                    <Grid item>
                        {doPhonetictSet
                            .map((value: Process, index: number) => {
                                return {
                                    index,
                                    value
                                };
                            })
                            .filter((wrapper) => {
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                const { value } = wrapper;
                                return value.collectedScore > 0;
                            })
                            .map((wrapper) => {
                                let { value, index } = wrapper;
                                const initScore = value.position.initial.reduce((prev, curr) => prev + curr.score, 0);
                                const medScore = value.position.medial.reduce((prev, curr) => prev + curr.score, 0);
                                const finalScore = value.position.final.reduce((prev, curr) => prev + curr.score, 0);
                                const totalScore =
                                    value.position.final.length +
                                    value.position.medial.length +
                                    value.position.initial.length;
                                return (
                                    <Grid item key={value.process.name}>
                                        <Accordion
                                            className={classes.acc}
                                            onClick={() => handleExpand(index)}
                                            expanded={expand.processNumber === index}>
                                            <AccordionSummary
                                                sx={
                                                    ((initScore + medScore + finalScore) / totalScore) * 100 >= 50
                                                        ? { backgroundColor: "#bd000026" }
                                                        : { backgroundColor: "none" }
                                                }>
                                                <Typography variant="h4">{value.process.name}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                key={value.process.name + index}
                                                style={{ width: "100%", padding: 0, margin: 0 }}>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead className={classes.cellHeader}>
                                                            <TableCell variant="head" align="center" colSpan={4}>
                                                                INITIALT
                                                            </TableCell>
                                                            <TableCell variant="head" align="center" colSpan={4}>
                                                                MEDIALT
                                                            </TableCell>
                                                            <TableCell variant="head" align="center" colSpan={4}>
                                                                FINALT
                                                            </TableCell>
                                                        </TableHead>
                                                        <TableBody className={classes.body}>
                                                            <TableCell
                                                                variant="body"
                                                                colSpan={4}
                                                                className={classes.cell}>
                                                                {value.position.initial.map((init, index) => {
                                                                    return (
                                                                        <ProcessTables
                                                                            key={"i" + index}
                                                                            table={init}
                                                                            testInfo={testInfo}
                                                                        />
                                                                    );
                                                                })}
                                                            </TableCell>
                                                            <TableCell
                                                                variant="body"
                                                                colSpan={4}
                                                                className={classes.cell}>
                                                                {value.position.medial.map((med, index) => {
                                                                    return (
                                                                        <ProcessTables
                                                                            key={"m" + index}
                                                                            table={med}
                                                                            testInfo={testInfo}
                                                                        />
                                                                    );
                                                                })}
                                                            </TableCell>
                                                            <TableCell
                                                                variant="body"
                                                                colSpan={4}
                                                                className={classes.cell}>
                                                                {value.position.final.map((fin, index) => {
                                                                    return (
                                                                        <ProcessTables
                                                                            key={"f" + index}
                                                                            table={fin}
                                                                            testInfo={testInfo}
                                                                        />
                                                                    );
                                                                })}
                                                            </TableCell>
                                                            <TableRow>
                                                                <TableCell
                                                                    sx={{
                                                                        width: "3.33%",
                                                                        textAlignLast: "right"
                                                                    }}
                                                                    colSpan={4}>
                                                                    delsum: {initScore} /{" "}
                                                                    {value.position.initial.length}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        width: "3.33%",
                                                                        textAlignLast: "right"
                                                                    }}
                                                                    colSpan={4}>
                                                                    delsum: {medScore} / {value.position.medial.length}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        width: "3.33%",
                                                                        textAlignLast: "right"
                                                                    }}
                                                                    colSpan={12}>
                                                                    delsum: {finalScore} / {value.position.final.length}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    sx={{
                                                                        width: "100%",
                                                                        textAlignLast: "right"
                                                                    }}
                                                                    colSpan={12}>
                                                                    Total: {initScore + medScore + finalScore} /{" "}
                                                                    {totalScore}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Grid>
            )}
        </>
    );
}

/*
 <Grid item className={classes.text}>
                        <Typography>Forvekslingsmatriser: </Typography>
                        <Button className={classes.matrixButton} onClick={() => setOpenInitial(true)}>
                            Konsonanter Initialt
                        </Button>
                        <Button className={classes.matrixButton} onClick={() => setOpenMedial(true)}>
                            Konsonanter Medialt
                        </Button>
                        <Button className={classes.matrixButton} onClick={() => setOpenFinal(true)}>
                            Konsonanter Finalt
                        </Button>
                        <Button className={classes.matrixButton} onClick={() => setopenVowel(true)}>
                            Vokaler
                        </Button>
                        <Button
                            className={classes.cancel}
                            onClick={() => {
                                localStorage.removeItem("analyze");
                                history.push("/home");
                            }}>
                            Avslutt
                        </Button>
                    </Grid>
                    <MatrixModal open={openInitial} setOpen={setOpenInitial} position={"initial"} />
                    <MatrixModal open={openMedial} setOpen={setOpenMedial} position={"medial"} />
                    <MatrixModal open={openFinal} setOpen={setOpenFinal} position={"final"} />
                    <MatrixModal open={openVowel} setOpen={setopenVowel} position={"vowel"} />
                    */
