import React from "react";
import SetupView from "../../Views/SetupView";
import AboutView from "../../Views/AboutView";
import HomeView from "../../Views/HomeView";
import { NavText } from "../../text/NavText";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AnalyzeView from "src/Views/AnalyzeView";

const Home: React.FC = () => {
    return <HomeView children={""} />;
};

const SetUpTest: React.FC = () => {
    return <SetupView children={""} />;
};

const Analyze: React.FC = () => {
    return <AnalyzeView children={""} />;
};

const About: React.FC = () => {
    return <AboutView children={""} />;
};
/*
const RuleSettings: React.FC = () => {
    return <RuleSettingsView children={""} />;
};
*/
/**
 * Collection of URL routes, names and icons used in navigation bar.
 */
const Routes = [
    {
        path: "/home",
        sidebarName: NavText.routes.home,
        icon: <HomeOutlinedIcon />,
        component: Home,
        active: 0
    },
    {
        path: "/setuptest",
        sidebarName: NavText.routes.test,
        icon: <KeyboardArrowRightIcon />,
        component: SetUpTest,
        active: 1
    },
    {
        path: "/analyze",
        sidebarName: NavText.routes.analyze,
        icon: <TimelineOutlinedIcon />,
        component: Analyze,
        active: 2
    },
    {
        path: "/about",
        sidebarName: NavText.routes.manual,
        icon: <DescriptionOutlinedIcon />,
        component: About,
        active: 3
    }
    /*
    {
        path: "/rule-settings",
        sidebarName: NavText.routes.rule_settings,
        icon: <RuleFolderOutlinedIcon />,
        component: RuleSettings,
        active: false
    }
    */
];
export default Routes;
