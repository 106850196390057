import {
    Grid,
    Card,
    TextField,
    Button,
    Typography,
    Snackbar,
    Alert,
    AlertTitle,
    SnackbarCloseReason,
    MenuItem
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../../theme";
import React, { Dispatch, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import TestParticipantService from "../../../Services/TestParticipantService";
import { TestText } from "../../../text/TestText";
import SpeechTherapistService from "src/Services/SpeechTherapistService";

const useStyles = makeStyles({
    card: (props) => ({
        width: "450px",
        padding: DnfTheme.spacing(6),
        backgroundColor: "#c7c9c9",
        borderRadius: 10
    }),
    textField: (props) => ({
        margin: DnfTheme.spacing(1)
    }),
    button: (props) => ({
        width: "300px",
        margin: DnfTheme.spacing(2),
        padding: DnfTheme.spacing(4),
        backgroundColor: "#112139",
        borderRadius: 10,
        color: "#ffffff",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#315085",
            color: "#ffffff"
        }
    }),
    buttonDisabled: (props) => ({
        width: "300px",
        margin: DnfTheme.spacing(2),
        padding: DnfTheme.spacing(4),
        backgroundColor: "#666666",
        borderRadius: 10,
        color: "#666666",
        textTransform: "none"
    }),
    buttonActive: (props) => ({
        width: "300px",
        margin: DnfTheme.spacing(2),
        padding: DnfTheme.spacing(4),
        backgroundColor: "#203911",
        borderRadius: 10,
        color: "#ffffff",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#315085",
            color: "#ffffff"
        }
    })
});
/**
 * Page for register new test participants.
 * */

type RegisterNewTpProps = {
    setActiveStep: Dispatch<number>;
    setTestParticipant: Dispatch<number>;
    setStartTest: Dispatch<boolean>;
};
export default function RegisterNewTp({ setActiveStep, setTestParticipant, setStartTest }: RegisterNewTpProps) {
    const classes = useStyles();
    const [cookies, ,] = useCookies(["jwt", "id_sp"]);
    const [firstname, setFirstname] = useState("");
    const [speechTherapistName, setSpeechTherapistName] = useState<string>("");
    const [lastname, setLastname] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [motherTongue, setMotherTongue] = useState<string>("Norsk");
    const [languages, setOtherLanguages] = useState<string>("Annet");
    const [parent, setParent] = useState<string>("");

    const [disabled, setDisabled] = useState(true);
    const [openWarning, setOpenWarning] = useState(false);
    const [feedback, setFeedback] = useState(TestText.feedback.tpExist);

    //date
    const date = new Date();

    //Runs disableButton() if either firstname, lastname or dateOfBirth is updated.
    useEffect(() => {
        //Disable register button if any textField is empty
        const disableButton = () => {
            if (firstname !== "" && lastname !== "" && dateOfBirth !== "") {
                setDisabled(false);
            }
        };

        disableButton();
    }, [firstname, lastname, dateOfBirth]);

    useEffect(() => {
        SpeechTherapistService.getById(cookies.id_sp, cookies.jwt).then(({ data }) => {
            setSpeechTherapistName(data[0][0].firstname + " " + data[0][0].lastname);
        });
    }, [cookies.id_sp, cookies.jwt]);

    //Goes back to content page.
    const goBack = () => {
        setActiveStep(0);
    };

    //Close snackbar
    const handleClose = (event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason): void => {
        if (reason === "clickaway") {
            return;
        }
        setOpenWarning(false);
    };

    //Updates firstname. If empty disables register button
    const handleSetFirstname = (firstname) => {
        setFirstname(firstname);
        if (firstname === "") setDisabled(true);
    };

    //Updates lastname. If empty disables register button
    const handleSetLastname = (lastname) => {
        setLastname(lastname);
        if (lastname === "") setDisabled(true);
    };

    //Updates dateOfBirth. If empty disables register button
    const handleSetDateOfBirth = (date) => {
        setDateOfBirth(date);
    };

    const handleMotherTongue = (event) => {
        setMotherTongue(event.target.value);
    };

    const handleOtherLanguages = (event) => {
        setOtherLanguages(event.target.value);
    };
    const handleParentName = (event) => {
        setParent(event);
    };

    //Trying to register new test participant
    const regNewTestParticipant = async () => {
        TestParticipantService.registerNewTestParticipant(
            firstname,
            lastname,
            dateOfBirth,
            motherTongue,
            languages,
            parent,
            cookies.jwt
        )
            .then((tp) => {
                if (tp.data.message !== "Test participant name is already taken!") {
                    setTestParticipant(tp.data.insertId);
                    setActiveStep(2);
                    setStartTest(true);
                } else {
                    setOpenWarning(true);
                }
            })
            .catch((err) => {
                console.log(err);
                setFeedback(TestText.feedback.invalidBirthDate);
                setOpenWarning(true);
            });
    };

    return (
        <Grid container direction="row">
            <Grid container direction="column" item alignItems="center" xs={4}>
                <Card className={classes.card}>
                    <TextField
                        id="firstname"
                        label={TestText.textFields.label.firstname}
                        placeholder={TestText.textFields.placeHolder.firstname}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => handleSetFirstname(e.target.value)}
                    />

                    <TextField
                        id="lastname"
                        label={TestText.textFields.label.lastname}
                        placeholder={TestText.textFields.placeHolder.lastname}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => handleSetLastname(e.target.value)}
                    />
                    <TextField
                        id="birthdate"
                        label={TestText.textFields.label.dateOfBirth}
                        type="date"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => handleSetDateOfBirth(e.target.value)}
                    />
                    <TextField
                        id="motherTongue"
                        label={TestText.textFields.label.motherTongue}
                        value={motherTongue}
                        select
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={handleMotherTongue}>
                        {TestText.textFields.language.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="language"
                        label={TestText.textFields.label.otherLanguages}
                        helperText={TestText.textFields.placeHolder.otherLanguages}
                        value={languages}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={handleOtherLanguages}></TextField>

                    <TextField
                        id="parents"
                        label={TestText.textFields.label.parent}
                        placeholder={TestText.textFields.placeHolder.parent}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => handleParentName(e.target.value)}
                    />
                    <TextField
                        id="tp"
                        label={TestText.textFields.label.testleder}
                        placeholder={TestText.textFields.placeHolder.testleder}
                        fullWidth
                        value={speechTherapistName}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                    <TextField
                        id="testDato"
                        label={TestText.textFields.label.testDato}
                        placeholder={TestText.textFields.placeHolder.testDato}
                        fullWidth
                        value={date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Card>
                <Grid container item direction="column">
                    <Button
                        disabled={disabled}
                        className={disabled ? classes.buttonDisabled : classes.buttonActive}
                        onClick={regNewTestParticipant}>
                        <Typography>{TestText.buttons.register}</Typography>
                    </Button>

                    <Button className={classes.button} onClick={goBack}>
                        <Typography>{TestText.buttons.goBack}</Typography>
                    </Button>
                </Grid>
            </Grid>

            <Snackbar open={openWarning} autoHideDuration={3000} onClose={handleClose}>
                <Alert severity={"warning"}>
                    <AlertTitle>{TestText.alert.title}</AlertTitle>
                    {feedback}
                </Alert>
            </Snackbar>
        </Grid>
    );
}
