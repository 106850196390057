import {
    Button,
    Grid,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Dispatch, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { TestParticipant } from "src/Services/TestParticipantService";
import { TestData } from "../../../model/TestData";
import TestService from "../../../Services/TestService";
import { AnalyzeText } from "../../../text/AnalyzeText";

const useStyles = makeStyles({
    grid: () => ({
        marginLeft: 0,
        paddingLeft: 0
    }),
    button: () => ({
        color: "#0c1a2d",
        "&:hover": {
            backgroundColor: "#3c469f",
            color: "#fff"
        }
    }),
    choice_button: () => ({
        color: "#fff",
        backgroundColor: "rgb(36, 150, 145, 0.5)",
        "&:hover": {
            backgroundColor: "#3c469f",
            color: "#fff"
        }
    }),
    text: () => ({
        textAlign: "center"
    })
});

type ChosenTestProps = {
    testParticipant: TestParticipant | undefined;
    setTest: Dispatch<TestData>;
    setActiveStep: Dispatch<number>;
};

export default function ChooseTest({ testParticipant, setTest, setActiveStep }: ChosenTestProps) {
    const classes = useStyles();
    const [cookies, ,] = useCookies(["jwt", "id_sp"]);
    const [loading, setLoading] = useState(true);
    const [testList, setTestList] = useState<TestData[]>([]);

    useEffect(() => {
        setTestList([]);
        setLoading(true);
        if (!testParticipant) {
            setLoading(true);
        } else {
            TestService.getTestsBySpeechTherapistIDandTestParticipantID(
                testParticipant.id_tp,
                cookies.id_sp,
                cookies.jwt
            )
                .then((response) => {
                    if (response.status === 200) {
                        setTestList(response.data);
                        setLoading(false);
                    }
                })
                .catch((e) => console.error(e))
                .finally(() => setLoading(false));
        }
    }, [testParticipant, cookies.id_sp, cookies.jwt]);

    return (
        <>
            {loading ? (
                <Grid item>
                    <Typography>
                        Valgt kandidat:{" "}
                        {!testParticipant
                            ? "Testkandidat er ikke valgt enda "
                            : `${" " + testParticipant.firstname} ${testParticipant.lastname}`}
                    </Typography>
                    <br />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Grid>
            ) : (
                <>
                    <Typography>
                        Valgt kandidat:
                        {!testParticipant
                            ? "Testkandidat er ikke valgt enda"
                            : `${testParticipant.firstname} ${testParticipant.lastname}`}
                    </Typography>

                    <TableContainer>
                        <Table stickyHeader sx={{ background: "transparent", border: "none" }}>
                            <TableHead sx={{ backgroundColor: "rgb(36, 150, 145, 0.5)" }}>
                                <TableRow>
                                    <TableCell>{AnalyzeText.chooseTest.row2}</TableCell>
                                    <TableCell>{AnalyzeText.chooseTest.row3}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {testList[0]
                                    .slice()
                                    .sort((a, b) => a.id_test - b.id_test)
                                    .map((test) => (
                                        <TableRow key={test.test_id}>
                                            <TableCell align="inherit">
                                                {new Date(test.started_at).toLocaleDateString()}
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    key={test.id_test}
                                                    className={classes.choice_button}
                                                    onClick={() => {
                                                        setTest(test);
                                                        setActiveStep(2);
                                                    }}>
                                                    Analyser videre
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </>
    );
}
