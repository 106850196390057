import { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../theme";
import {
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
    TableContainer,
    Paper,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    ButtonGroup,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider
} from "@mui/material";
import RulesService from "../../Services/RulesService";
import { useCookies } from "react-cookie";
import { Rule } from "../../model/Rule";
import { Word } from "../../mapping/norwegian/Rules";

const useStyles = makeStyles({
    root: (props) => ({
        display: "grid",
        height: "inherit",
        marginLeft: DnfTheme.spacing(6)
    }),
    contents: (props) => ({
        width: "100%",
        height: "100%"
    }),
    gridItem: (props) => ({
        margin: DnfTheme.spacing(3)
    }),
    card: (props) => ({
        backgroundColor: "#b7bbbe",
        minHeight: "300px",
        maxWidth: "80%",
        margin: "auto",
        textAlign: "left",
        padding: DnfTheme.spacing(4),
        borderRadius: 10
    }),
    underTitle: (props) => ({
        textTransform: "none",
        fontSize: 20,
        marginBottom: "10px"
    }),
    title: (props) => ({
        fontSize: 50,
        marginBottom: "20px"
    }),
    editButton: (props) => ({
        width: "100px",
        margin: DnfTheme.spacing(1),
        padding: DnfTheme.spacing(2),
        color: "#ffffff",
        backgroundColor: "#5a63b8",
        textTransform: "none",
        borderRadius: 10,
        "&:hover": {
            backgroundColor: "#263e67",
            color: "#FFF"
        }
    }),
    deleteButton: (props) => ({
        color: "#ffffff",
        width: "100px",
        margin: DnfTheme.spacing(1),
        padding: DnfTheme.spacing(2),
        backgroundColor: "#cd1f1f",
        textTransform: "none",
        borderRadius: 10,
        "&:hover": {
            backgroundColor: "#8a0f0f",
            color: "#FFF"
        }
    }),
    textField: (props) => ({
        marginTop: "20px",
        backgroundColor: "#ffffff",
        borderRadius: 5
    }),
    alert: (props) => ({
        paddingBottom: DnfTheme.spacing(3),
        fontSize: "1.3em",
        height: "100px",
        width: "400px"
    })
});

export interface Ruleset {
    file_hash: string;
    is_default: boolean;
    short_description: string;
    uploaded_at: string;
}

export default function RuleSettings() {
    const classes = useStyles();
    const [cookies] = useCookies(["jwt"]);
    const fileUploadInput = useRef<HTMLInputElement>(null);
    const [rulesetList, setRulesetList] = useState<Ruleset[]>([]);
    const [userRules, setUserRules] = useState<Rule[]>();
    const [shortDescription] = useState("");

    const [testInputArray, setTestInputArray] = useState<Array<string>>(["", "", "", "", "", ""]);
    const [testResultArray, setTestResultArray] = useState<Array<string>>(["", "", "", "", "", ""]);

    const [loadingState, setLoadingState] = useState<"loading" | "complete" | "error">("loading");

    const loadUserRules = useCallback(async () => {
        try {
            const loadedUserRuleset = await RulesService.getRuleset(cookies.jwt);
            if (loadedUserRuleset.status === 404) {
                setUserRules([]);
            } else {
                setUserRules(loadedUserRuleset.data);
            }
        } catch (error) {
            // TODO: Handle error
            let message = "";
            if (typeof error === "string") {
                message = error;
            } else if (error instanceof Error) {
                message = error.message;
            }

            throw new Error(message);
        }
    }, [cookies.jwt]);

    const loadAllRulesets = useCallback(async () => {
        try {
            const rulesets = await RulesService.getAllRulesets(cookies.jwt);
            setRulesetList(rulesets.data);
        } catch (error) {
            // TODO: Handle error
            let message = "";
            if (typeof error === "string") {
                message = error;
            } else if (error instanceof Error) {
                message = error.message;
            }

            throw new Error(message);
        }
    }, [cookies.jwt]);

    const loadAll = useCallback(async () => {
        setLoadingState("loading");
        try {
            loadUserRules();
            loadAllRulesets();
            setLoadingState("complete");
        } catch (error) {
            // TODO: handle error
            let message = "";
            if (typeof error === "string") {
                message = error;
            } else if (error instanceof Error) {
                message = error.message;
            }

            throw new Error(message);
            // setLoadingState("error");
        }
    }, [loadAllRulesets, loadUserRules]);

    useEffect(() => {
        try {
            const rawTestInput = localStorage.getItem("testInputs");
            if (rawTestInput !== null) {
                const testInput = JSON.parse(rawTestInput);
                if (rawTestInput.length === 6) {
                    setTestInputArray(testInput);
                }
            }
        } catch (error) {
            console.log(error);
            // Handle error
        }
        loadAll();
    }, [loadAll]);

    useEffect(() => {
        const getTestResult = (testInput) => {
            if (!userRules || userRules.length === 0) return "No rules.";
            if (!testInput || testInput.length === 0) return "No input.";
            if (testInput.startsWith("-") || testInput.endsWith("-")) return "Input can't start or end with -.";

            try {
                const word = new Word(userRules, testInput);
                return word.transcribe();
            } catch (error) {
                return "Error. If this should be a result report input used.";
            }
        };

        const newResults = new Array<string>();
        testInputArray.forEach((val) => {
            console.log(getTestResult(val));

            // newResults.push(getTestResult(val));
        });
        setTestResultArray(newResults);
    }, [testInputArray, userRules]);

    const setActiveRuleset = async (rulesetFileHash: string) => {
        setLoadingState("loading");
        try {
            await RulesService.setActiveRuleset(rulesetFileHash, cookies.jwt);

            // TODO: Handle error
            setLoadingState("complete");
            loadAllRulesets();
        } catch (error) {
            setLoadingState("error");

            // TODO: handle error
            let message = "";
            if (typeof error === "string") {
                message = error;
            } else if (error instanceof Error) {
                message = error.message;
            }

            throw new Error(message);
        }
    };

    const setDefaultRuleset = async (rulesetFileHash: string) => {
        setLoadingState("loading");
        try {
            await RulesService.setDefaultRuleset(rulesetFileHash, cookies.jwt);

            // TODO: Handle error
            setLoadingState("complete");
            loadAllRulesets();
        } catch (error) {
            setLoadingState("error");

            // TODO: handle error
            let message = "";
            if (typeof error === "string") {
                message = error;
            } else if (error instanceof Error) {
                message = error.message;
            }

            throw new Error(message);
        }
    };

    const updateTestInput = (index, value) => {
        const newInputArray = new Array(...testInputArray);
        newInputArray[index] = value;
        setTestInputArray(newInputArray);
        updateTestResult(index, value);

        console.log("newInputArray", newInputArray);
        localStorage.setItem("testInputs", JSON.stringify(newInputArray));
    };

    const updateTestResult = (index, testInput) => {
        const newResultArray = new Array(...testResultArray);

        if (!userRules || userRules.length === 0) {
            newResultArray[index] = "No rules.";
        } else if (!testInput || testInput.length === 0) {
            newResultArray[index] = "No input.";
        } else if (testInput.startsWith("-") || testInput.endsWith("-")) {
            newResultArray[index] = "Input can't start or end with -.";
        } else {
            try {
                const word = new Word(userRules, testInput);
                newResultArray[index] = word.transcribe()[index];
            } catch (error) {
                newResultArray[index] = "Error. If this should be a result report input used.";
            }
        }

        setTestResultArray(newResultArray);
    };

    return (
        <div className={classes.root}>
            <main className={classes.contents}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                    <Grid item className={classes.gridItem}>
                        <Typography variant="h1" className={classes.title}>
                            Rule settings
                        </Typography>
                    </Grid>
                </Grid>
                {loadingState === "loading" && (
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                )}
                {loadingState === "complete" && (
                    <>
                        <Grid item>
                            {/* <TextField
                                value={shortDescription}
                                onChange={(e) =>
                                    setShortDescription(e.target.value || "")
                                }
                                id="standard-basic"
                                label="Short description"
                                variant="standard"
                            /> */}
                            <Button
                                variant="contained"
                                component="label"
                                onChange={async () => {
                                    // TODO: Error handling
                                    setLoadingState("loading");

                                    if (
                                        !fileUploadInput ||
                                        !fileUploadInput.current ||
                                        !fileUploadInput.current.files
                                    ) {
                                        return;
                                    }

                                    const files = fileUploadInput.current.files;
                                    const ruleFile = files[0];

                                    if (!ruleFile) {
                                        throw new Error("No rule file");
                                    }

                                    const response = await RulesService.uploadRuleset(
                                        ruleFile,
                                        shortDescription,
                                        cookies.jwt
                                    );

                                    console.log("Upload response", response);
                                    await loadAll();
                                }}>
                                Upload ruleset
                                <input ref={fileUploadInput} type="file" hidden />
                            </Button>
                        </Grid>
                        <Grid item>
                            <TableContainer component={Paper}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>File hash</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Uploaded at</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rulesetList.map((ruleset) => (
                                        <TableRow>
                                            <TableCell>
                                                {ruleset.is_default === true && <Typography>Default</Typography>}
                                            </TableCell>
                                            <TableCell>{ruleset.file_hash}</TableCell>
                                            <TableCell>{ruleset.short_description}</TableCell>
                                            <TableCell>{ruleset.uploaded_at}</TableCell>
                                            <TableCell>
                                                <ButtonGroup variant="outlined">
                                                    <Button
                                                        color="info"
                                                        onClick={() => setDefaultRuleset(ruleset.file_hash)}>
                                                        Set as default
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        onClick={() => setActiveRuleset(ruleset.file_hash)}>
                                                        Set as active
                                                    </Button>
                                                    {/* <Button color="warning">
                                                        Delete
                                                    </Button> */}
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </TableContainer>
                        </Grid>
                        <Grid item>
                            <Typography variant="h2">Testing</Typography>
                            <Accordion>
                                <AccordionSummary>
                                    <Typography>From database</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>TODO: Ferdig utfylt fra database</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded>
                                <AccordionSummary>
                                    <Typography>Manual input</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {testInputArray.map((val, idx) => (
                                        <>
                                            <TextField
                                                key={`test-input-${idx}`}
                                                value={val}
                                                onChange={(e) => updateTestInput(idx, e.target.value || "")}
                                                id="standard-basic"
                                                label="Test input"
                                                variant="standard"
                                            />
                                            <TextField
                                                key={`test-result-${idx}`}
                                                value={testResultArray[idx]}
                                                disabled
                                                id="standard-basic"
                                                label="Test result"
                                                variant="standard"
                                            />
                                            <Divider />
                                        </>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </>
                )}
            </main>
        </div>
    );
}
