/**
 * Text for home and manual components
 */
const info = {
    title: "Digital Norsk Fonemtest",
    quotation: '"Det digitale verktøyet for å gjennomføre norsk fonemtest."',
    ingress:
        "Digital Norsk Fonemtest er et digitalt verktøy utviklet for til å støtte og effektivisere logopeders" +
        "arbeid i å kartlegge talefeil hos barn.",
    information: ""
};

const startTest = {
    underTitle: "Start test",
    introText: " Velg 'Start test' for å starte en ny test.",
    text: [
        "Oppskrift:",
        "Om du ønsker å finne en tidligere registrert testperson velg 'Eksisterende testkandidat'.",
        "Om du skal redigere en ny testperson velg 'Ny testkandidat'.",
        "Velg testpersonen sin dialekt.",
        "Start test!"
    ]
};

const analyze = {
    underTitle: "Analyser",
    introText: "Velg 'Analyser' for å analysere en test.",
    text: ["Oppskrift:", "Velg ønsket testperson.", "Velg en av testene til valgt testperson", "Analyser!"]
};

const settings = {
    underTitle: "Innstillinger",
    introText: "Velg 'Innstillinger' for å endre på brukerprofil.",
    text: [
        "Mulige valg:",
        "Endre på fornavn og etternavn.",
        "Endre på epost addresse.",
        "Endre på passord.",
        "Slette bruker."
    ]
};

const button = {
    text: "Detaljert brukermanual"
};

export const HomeText = {
    info,
    startTest,
    analyze,
    settings,
    button
};
