//React
import React from "react";

//Component
import Content from "../components/Login/Content";

export default function LoginView() {
    return (
        <div>
            <Content />
        </div>
    );
}
