//@flow
import { axiosSpeechTherapist as axios } from "../Shared/Axios";

class SpeechTherapistService {
    getById = async (id: string, token: string) => {
        return await axios.get("/" + id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    getUserByeEmail = async (email: string) => {
        return await axios.get("/" + email);
    };

    editUser = async (
        id: string,
        email: string,
        firstname: string,
        lastname: string,
        password: string,
        token: string
    ) => {
        return await axios.put(
            "/" + id,
            {
                email,
                firstname,
                lastname,
                password
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    };

    makeUserUnavailable = async (id: string, token: string) => {
        return await axios.put(
            "/unavailable/" + id,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    };

    register = async (email: string, firstname: string, lastname: string, password: string) => {
        return await axios.post("/register", {
            email,
            firstname,
            lastname,
            password
        });
    };

    login = async (email: string, password: string) => {
        return await axios.post("/login", { email, password });
    };

    verifyToken = async (token: string, id: string) => {
        return await axios.post("/verifyToken", { token, id });
    };
}

export default new SpeechTherapistService();
