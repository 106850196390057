import { useEffect, useState } from "react";
import { CircularProgress, Container, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import Confusion from "../../ConfProcess";
import Context, { QuestionData, TestParticipantInfo } from "src/components/Test/Therapist/Context";
import QuestionService from "src/Services/QuestionService";
import { AnalyzeSteps } from "src/utils/StepperFunctions";
import InformationBox from "./InformationBox";
import TestService from "src/Services/TestService";
import { useCookies } from "react-cookie";

export default function ResultsContent() {
    const [emptyList, setEmptylist] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const steps = AnalyzeSteps();
    const test_id = localStorage.getItem("id_test");
    const [cookies, ,] = useCookies(["jwt", "id_sp"]);
    const [testInfo, setTestInformation] = useState<TestParticipantInfo>();
    const [loaded, setLoaded] = useState<boolean>(false);

    const [questionList, setQuestionList] = useState<QuestionData[]>([]);

    useEffect(() => {
        if (test_id) {
            QuestionService.getQuestionListByTestId(Number(test_id))
                .then((res) => {
                    if (res.status === 200 && res.data) {
                        setQuestionList(res.data);
                        setActiveStep(2);
                        setEmptylist(false);
                        TestService.getTestInformation(Number(test_id), cookies.jwt)
                            .then((res) => {
                                if (res.status === 200) {
                                    setTestInformation(res.data[0]);
                                    setLoaded(true);
                                } else {
                                    console.log("Failed retrieving test info");
                                }
                            })
                            .catch((err) => console.log(err));
                    }
                })
                .catch((error) => {
                    setEmptylist(true);
                    console.error(error);
                });
        }
    }, [questionList, test_id, emptyList, cookies.jwt]);

    useEffect(() => {}, [cookies.id_sp, cookies.jwt, loaded, emptyList]);

    return (
        <Context>
            <Container>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Grid item>
                    {emptyList ? (
                        <Grid container item justifyContent="center" alignItems="center">
                            <Typography variant="h5">Laster inn resultater for analyse</Typography>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <Grid container direction={"row"}>
                            <Grid item>
                                <InformationBox
                                    testNumber={test_id}
                                    birthDate={
                                        testInfo
                                            ? new Date(testInfo.date_of_birth).toLocaleDateString()
                                            : "feil ved uthenting av fødselsdato"
                                    }
                                    participantName={
                                        testInfo
                                            ? testInfo.firstname + " " + testInfo.lastname
                                            : "feil ved uthenting av navn"
                                    }
                                    motherTongue={testInfo ? testInfo.motherTongue : "feil ved uthenting av morsmål"}
                                    otherLanguages={testInfo ? testInfo.languages : "feil ved uthenting av språk"}
                                    parent={
                                        testInfo
                                            ? testInfo.parent === ""
                                                ? "ikke registret"
                                                : testInfo.parent
                                            : "feil ved uthenting av foresatte"
                                    }
                                />
                                <Confusion testInfo={testInfo} testToAnalyze={questionList} />
                            </Grid>
                            <Grid item xs={11}></Grid>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </Context>
    );
}
