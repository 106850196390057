import { Dispatch, useEffect, useState } from "react";
import ChooseTP from "./AnalyzeSetup/ChooseTP";
import ChooseTest from "../Analyzing/AnalyzeSetup/ChooseTest";
import { AnalyzeSteps } from "../../utils/StepperFunctions";
import { Container, Divider, Grid, Step, StepLabel, Stepper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TestParticipant } from "src/Services/TestParticipantService";
import { TestData } from "src/model/TestData";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    divider: () => ({
        borderColor: "#000",
        borderWidth: "0.5px",
        border: "solid",
        height: "150vh",
        marginLeft: "2%",
        marginRight: "2%",
        width: 0,
        marginTop: 0
    })
});

export default function Content() {
    //const [openAnalyze, setOpenAnalyze] = useState(false);

    const classes = useStyles();
    const steps = AnalyzeSteps();
    const history = useHistory();

    const [activeStep, setActiveStep] = useState(0);
    const [testParticipant, setTestParticipant]: [TestParticipant | undefined, Dispatch<TestParticipant>] = useState();
    const [test, setTest]: [TestData | undefined, Dispatch<TestData>] = useState();

    useEffect(() => {
        if (test) {
            localStorage.setItem("id_test", test.test_id.toString());
            history.push("/results");
        }
    }, [test, history]);

    return (
        <Container>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Grid container direction={"row"} justifyContent="flex-start" flexWrap={"inherit"} sx={{ marginTop: "5%" }}>
                <Grid direction="column" item xs={4}>
                    <ChooseTP setTestParticipant={setTestParticipant} setActiveStep={setActiveStep} />
                </Grid>
                <Divider orientation="vertical" className={classes.divider} />
                <Grid direction="column" item xs={8}>
                    <ChooseTest testParticipant={testParticipant} setTest={setTest} setActiveStep={setActiveStep} />
                </Grid>
            </Grid>
        </Container>
    );
}
