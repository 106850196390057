import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Grid, Skeleton, CardMedia, Typography, Stepper, Step, StepLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../../theme";
import QuestionService from "src/Services/QuestionService";
import { QuestionData, TestParticipantInfo } from "../../Test/Therapist/Context";
import InformationBox from "./InformationBox";
import TestService from "src/Services/TestService";
import { AnalyzeSteps } from "src/utils/StepperFunctions";
import { AnalyzeText } from "src/text/AnalyzeText";

const useStyles = makeStyles({
    webcam: () => ({
        width: 0,
        align: "bottom",
        maxWidth: 100,
        maxHeight: 400,
        minWidth: 600,
        minHeight: 400,
        marginRight: "5%"
    }),

    cardMedia: (props) => ({
        maxWidth: 200,
        maxHeight: 200,
        minWidth: 200,
        minHeight: 200,
        marginRight: "30px",
        paddingBottom: "10px",

        borderRadius: 20
    }),
    text: (props) => ({
        fontSize: "0.9rem",
        paddingBottom: DnfTheme.spacing(3)
    }),
    image: (props) => ({
        height: 300
    }),
    title: (props) => ({
        padding: DnfTheme.spacing(1),
        fontSize: 20
    }),
    matchingText: (props) => ({
        color: "#358816",
        padding: DnfTheme.spacing(2),
        fontSize: 20
    }),
    notMatchingText: (props) => ({
        color: "#961414",
        padding: DnfTheme.spacing(2),
        fontSize: 20
    })
});

/**
 * Compares transcription sound value against orthographic- and phonetic value.
 */

interface QuestionIDProps {
    id: number | undefined;
    testInfo?: TestParticipantInfo;
}
export default function Results(prop: QuestionIDProps) {
    const [id] = useState<QuestionIDProps>(prop);
    const classes = useStyles();
    const [cookies, ,] = useCookies(["jwt"]);
    const id_test = localStorage.getItem("id_test");
    const [loading, setLoading] = useState(true);
    const [questionList, setQuestionList] = useState<QuestionData[]>([]);
    const [image, setImage] = useState("");
    const [ort_answer, setOrtAnswer] = useState("");
    const [word, setWord] = useState("");
    const [predictedValue, setPredictedValue] = useState("");
    const [phonetic, setPhonetic] = useState("");
    const [, setOrthographic] = useState("");
    const [videoUrl, setVideoUrl] = useState();
    const [index, setIndex] = useState(0);
    // const [userRules, setUserRules] = useState<Rule[]>();
    const [activeStep, setActiveStep] = useState(0);
    const steps = AnalyzeSteps();

    // const loadUserRules = useCallback(async () => {
    //     try {
    //         const loadedUserRuleset = await RulesService.getRuleset(cookies.jwt);
    //         setUserRules(loadedUserRuleset.data);
    //     } catch (error) {
    //         // TODO: Handle error
    //         let message = "";
    //         if (typeof error === "string") {
    //             message = error;
    //         } else if (error instanceof Error) {
    //             message = error.message;
    //         }

    //         throw new Error(message);
    //     }
    // }, [cookies.jwt]);

    // useEffect(() => {
    //     loadUserRules();
    // }, [loadUserRules]);

    const [testInfo, setTestInformation] = useState<TestParticipantInfo>();

    useEffect(() => {
        setLoading(true);
        setActiveStep(2);
        const testDetails = async () => {
            if (!Number.isNaN(id_test)) {
                TestService.getTestInformation(Number(id_test), cookies.jwt)
                    .then((res) => {
                        if (res.status === 200) {
                            setTestInformation(res.data[0]);
                        } else {
                            console.log("Failed retrieving test info");
                        }
                    })
                    .catch((err) => console.log(err));
            }
        };

        if (questionList && questionList.length > 0) {
            if (!id.id) {
                setImage(questionList[index].image_link);
                setWord(questionList[index].word);
                setPredictedValue(questionList[index].answer);
                setOrtAnswer(questionList[index].ort_answer);
                setPhonetic(questionList[index].phonetic_transcription);
                setOrthographic(questionList[index].orthographic_transcription);
            } else {
                setImage(questionList[id.id - 1].image_link);
                setWord(questionList[id.id - 1].word);
                setPredictedValue(questionList[id.id - 1].answer);
                setOrtAnswer(questionList[id.id - 1].ort_answer);
                setPhonetic(questionList[id.id - 1].phonetic_transcription);
                setOrthographic(questionList[id.id - 1].orthographic_transcription);
            }
        } else {
            if (!id_test) {
                throw new Error("No id_test set.");
            }

            QuestionService.getQuestionListByTestId(Number(id_test))
                .then((res) => {
                    if (res.status === 200 && res.data) {
                        setQuestionList(res.data);
                    } else {
                        throw Error("Getting results failed");
                    }
                })
                .catch((err) => console.error(err));

            if (id.id) {
                QuestionService.getAnswer(Number(id_test), id.id, cookies.jwt).then((res) => {
                    if (res.status === 200 && res.data) {
                        setVideoUrl(res.data.videoBlobUrl);
                    }
                });
            } else {
                QuestionService.getAnswer(Number(id_test), index + 1, cookies.jwt).then((res) => {
                    if (res.status === 200 && res.data) {
                        console.log(res.data);
                        console.log(res.data.videoBlobUrl);
                        setVideoUrl(res.data.videoBlobUrl);
                    }
                });
            }
        }
        testDetails();
        setLoading(false);
    }, [index, videoUrl, cookies.jwt, id_test, phonetic, predictedValue, questionList, id]);

    const handleNext = useCallback(() => {
        if (questionList && index < questionList.length - 1) {
            setIndex(index + 1);
        }

        QuestionService.getAnswer(Number(id_test), index + 2, cookies.jwt).then((res) => {
            if (res.status === 200 && res.data) {
                console.log(res.data);
                setVideoUrl(res.data.videoBlobUrl);
            }
        });
    }, [questionList, index, cookies.jwt, id_test]);

    const handlePrevious = useCallback(() => {
        if (index > 0) {
            setIndex(index - 1);
        }

        QuestionService.getAnswer(Number(id_test), index, cookies.jwt).then((res) => {
            if (res.status === 200 && res.data) {
                console.log(res.data);
                setVideoUrl(res.data.videoBlobUrl);
            }
        });
    }, [index, cookies.jwt, id_test]);

    useEffect(() => {
        const handleHotkeyArrowKeys = (event: KeyboardEvent) => {
            if (event.key === "ArrowLeft") {
                handlePrevious();
            }

            if (event.key === "ArrowRight") {
                handleNext();
            }
        };

        document.addEventListener("keydown", handleHotkeyArrowKeys);

        return () => {
            document.removeEventListener("keydown", handleHotkeyArrowKeys);
        };
    }, [handleNext, handlePrevious]);
    /*
    const DisplayText = () => {
        return (
            <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                <Grid>
                    <TranscriptInputAnalyze
                        questionId={id.id || index + 1}
                        expectedText={AnalyzeText.reviewTest.fon}
                        predictedText={AnalyzeText.reviewTest.transcription}
                        loading={false}
                        phonetic={phonetic}
                        orthographic={orthographic}
                        userRules={userRules}
                        predictedWord={predictedValue}
                        orthographicPrediction={ort_answer}
                    />
                </Grid>
            </Grid>
        );
    };
*/
    return (
        <>
            {loading ? (
                <Grid item>
                    <Skeleton />
                </Grid>
            ) : (
                <Grid>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Grid container direction="row">
                        <Grid item container direction="column" className={classes.webcam} style={{ width: "100%" }}>
                            <CardMedia
                                height={500}
                                component="video"
                                image={videoUrl}
                                controls
                                autoPlay
                                loop
                                sx={{ marginBottom: 0, paddingBottom: 0 }}
                            />
                            <Grid item container direction="row" sx={{ marginLeft: 10, marginTop: 10 }}>
                                <CardMedia component="img" image={image} className={classes.cardMedia} />
                                <Grid>
                                    <Typography variant="h2" style={{ fontWeight: "bold" }} color="textPrimary">
                                        {word}
                                    </Typography>
                                    <Typography variant="h2" style={{ fontWeight: "normal" }} color="#117864">
                                        {phonetic}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row">
                            <Grid item xs={6}>
                                {testInfo ? (
                                    <>
                                        <InformationBox
                                            testNumber={id_test}
                                            birthDate={new Date(testInfo.date_of_birth).toLocaleDateString()}
                                            participantName={testInfo.firstname + " " + testInfo.lastname}
                                            motherTongue={testInfo.motherTongue}
                                            otherLanguages={testInfo.languages}
                                            parent={testInfo.parent === "" ? "ikke registret" : testInfo.parent}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Typography>Henter testinformasjon...</Typography>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: "8%" }}>
                                <Typography variant="h5" style={{ fontWeight: "bold" }} color="textPrimary">
                                    {AnalyzeText.reviewTest.fon}
                                </Typography>
                                <Typography variant="h4" style={{ fontWeight: "normal" }} color="#117864">
                                    {word}
                                </Typography>
                                <Typography variant="h5" style={{ fontWeight: "bold" }} color="textPrimary">
                                    Transkribert ortografisk
                                </Typography>
                                <Typography variant="h4" style={{ fontWeight: "normal" }} color="#117864">
                                    {ort_answer}
                                </Typography>

                                <Typography variant="h5" style={{ fontWeight: "bold" }} color="textPrimary">
                                    {AnalyzeText.reviewTest.transcription}
                                </Typography>
                                <Typography variant="h4" style={{ fontWeight: "normal" }} color="#117864">
                                    {predictedValue}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

/**
 * Fungerer ikke som den skal akkurat nå
 *  <DisplayText />
 */
