import {
    Alert,
    AlertTitle,
    Backdrop,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Snackbar,
    SnackbarCloseReason,
    TextField,
    Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../theme";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useHistory } from "react-router-dom";
import SpeechTherapistService from "../../Services/SpeechTherapistService";
import { LoginText } from "../../text/LoginText";

const useStyles = makeStyles({
    root: (props) => ({
        height: "100vh"
    }),
    backdrop: (props) => ({
        zIndex: DnfTheme.zIndex.drawer + 1,
        color: "#fff"
    }),
    typography: (props) => ({
        fontFamily: DnfTheme.typography.fontFamily,
        color: "#fff",
        textAlign: "center",
        fontSize: 40
    }),
    form: (props) => ({
        width: "100%",
        marginTop: DnfTheme.spacing(1),
        color: "#fff"
    }),
    cssLabel: (props) => ({
        color: "#fff",
        WebkitTextFillColor: "#fff"
    }),
    notchedOutline: (props) => ({
        borderWidth: "1px",
        borderColor: "white !important"
    }),
    cssOutlinedInput: (props) => ({
        color: "#fff",
        "&$cssFocused $notchedOutline": {
            borderColor: "#fff",
            color: "#fff"
        }
    }),
    cssFocused: (props) => ({
        color: "#fff"
    }),
    divider: (props) => ({
        alignment: "center",
        color: "#000",
        length: 5
    }),
    submit: (props) => ({
        margin: DnfTheme.spacing(3, 0, 2)
    }),
    grid: (props) => ({
        backgroundColor: "#0c1a2d"
    }),
    alert: (props) => ({
        paddingBottom: DnfTheme.spacing(3),
        fontSize: "1.3em",
        height: "100px",
        width: "400px"
    }),
    link: (props) => ({
        color: "#ffffff",
        cursor: "pointer"
    }),
    progressBar: (props) => ({
        color: "inherit"
    })
});

/**
 * Login page. Needs a valid user to login.
 * */
export default function Login(params: {
    setLogin: (arg0: boolean) => void;
    setResetPassword: (arg0: boolean) => void;
}) {
    const classes = useStyles();
    const history = useHistory();
    const [, setCookie] = useCookies(["jwt", "id_sp"]);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [openWarning, setOpenWarning] = useState(false);

    //Close snackbar
    const handleClose = (event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason) => {
        setOpenWarning(false);
    };

    //Wipes out text in the textFields.
    const wipeOutTextFields = () => {
        setPassword("");
        setEmail("");
    };

    //Validate the input data from the textFields.
    const validateInput = () => {
        let status = false;
        if (email === "" || password === "") {
            setFeedback(LoginText.feedback.emptyTextFields);
            setOpenWarning(true);
        } else {
            setFeedback(LoginText.feedback.unrecognizedCredentials);
            status = true;
        }
        return status;
    };

    //Handle login
    const login = async (event: React.MouseEvent) => {
        event.preventDefault();
        let status = validateInput();
        if (status) {
            setLoading(true);
            setTimeout(() => {
                SpeechTherapistService.login(email, password)
                    .then(({ data }) => {
                        setCookie("jwt", data.token, { path: "/" });
                        setCookie("id_sp", data.id, { path: "/" });
                        history.push("/home");
                        window.location.reload();
                    })
                    .catch(() => {
                        setLoading(false);
                        setOpenWarning(true);
                        wipeOutTextFields();
                    });
            }, 1000);
        }
    };

    return (
        <div>
            <Typography component="h1" variant="h5" className={classes.typography}>
                {LoginText.info.loginTitle}
            </Typography>
            <br />
            <Divider className={classes.divider} />
            <br />
            <form className={classes.form}>
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    label={LoginText.textField.email}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                        }
                    }}
                    InputProps={{
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused
                        },
                        sx: { color: "#fff" }
                    }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    // className={classes.textField}
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={LoginText.textField.password[0]}
                    type="password"
                    autoComplete="current-password"
                    InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                        }
                    }}
                    InputProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                        },
                        sx: { color: "#fff" }
                    }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    // className={classes.submit}
                    onClick={login}>
                    {LoginText.buttons.login}
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link
                            // TODO:
                            to=""
                            className={classes.link}
                            onClick={() => {
                                params.setLogin(false);
                                params.setResetPassword(true);
                            }}>
                            <Typography>{LoginText.links.newPassword}</Typography>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link
                            // TODO:
                            to=""
                            className={classes.link}
                            onClick={() => {
                                params.setLogin(false);
                                params.setResetPassword(false);
                            }}>
                            <Typography>{LoginText.links.register}</Typography>
                        </Link>
                    </Grid>
                </Grid>
            </form>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress className={classes.progressBar} />
            </Backdrop>
            <Snackbar open={openWarning} autoHideDuration={4000} onClose={handleClose}>
                <Alert
                    className={classes.alert}
                    // TODO: onClose={handleClose}
                    severity={"warning"}>
                    {/* <AlertTitle>{LoginText.alert.title}</AlertTitle> */}
                    <AlertTitle>{LoginText.alert.success}</AlertTitle>
                    {feedback}
                </Alert>
            </Snackbar>
        </div>
    );
}
