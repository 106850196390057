import { Dispatch, ReactNode } from "react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";

const ActionsContainer = styled.div`
    flex: 0 0 auto;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 12px;

    > *:not(:last-child) {
        margin-right: 12px;
    }
`;

// 224 er 320 (min-bredde) minus marginene til dialog-komponenten
const PaddedContainer = styled.div`
    padding: 32px;
    margin: 32px;
    background-color: rgba(147, 173, 172, 0.925);
    pointer-events: auto;
`;

// https://www.w3.org/TR/wai-aria-practices/examples/dialog-modal/dialog.html

type ModalProps = {
    id: string;
    open: boolean;
    fullScreen: boolean;
    onClose: Dispatch<any>;
    title?: string;
    renderContent: () => ReactNode;
    renderActions: () => ReactNode;
};

export default function Modal({ id, title, open, fullScreen, onClose, renderContent, renderActions }: ModalProps) {
    const labelId = `${id}-title`;
    const descriptionId = `${id}-description`;
    return (
        <Dialog
            sx={{ backgroundColor: "rgba(147, 173, 172, 0.825)" }}
            open={open}
            aria-labelledby={labelId}
            aria-describedby={descriptionId}
            aria-modal
            fullScreen={fullScreen}
            maxWidth={false}
            onClose={onClose}>
            <PaddedContainer>
                <h2 id={labelId}>{title}</h2>
                <div id={descriptionId}>{renderContent()}</div>
                <ActionsContainer>{renderActions()}</ActionsContainer>
            </PaddedContainer>
        </Dialog>
    );
}
