import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Popover,
    Skeleton,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Rule } from "src/model/Rule";
import { Word } from "src/mapping/norwegian/Rules";
import Consonants from "src/mapping/norwegian/Consonants";
import Vowels from "src/mapping/norwegian/Vowels";
import React from "react";

const useStyles = makeStyles({
    root: (props) => ({
        width: "device-width"
    }),
    live: (props) => ({
        color: "grey",
        fontSize: "30px",
        marginBottom: "20px"
    }),

    input: () => ({
        fontSize: 30,
        fontWeight: "bold",
        color: "#a2a2a2",
        "&::placeholder": {
            fontSize: 30
        }
    }),
    textField: (props) => ({
        width: "80px",
        margin: 8,
        padding: "1px",
        background: "white"
    }),
    textO: (props) => ({
        marginLeft: "0",
        marginBottom: "20px"
    }),
    textP: (props) => ({
        marginLeft: "0.75%",
        marginBottom: "20px"
    }),
    ortContainer: (props) => ({
        background: "linear-gradient(0deg, #249691 80%, rgba(20,91,79,0.75) 100%,  #249691 100%)",
        borderRadius: 20,
        paddingTop: 5,
        paddingBottom: 15,
        marginTop: 30,
        width: "2050px"
    }),
    phonContainer: (props) => ({
        background: "linear-gradient(0deg, #249691 80%, rgba(20,91,79,0.75) 100%,  #249691 80%)",
        borderRadius: 20,
        paddingLeft: 0,
        paddingBottom: 5,
        marginBottom: "7%",
        width: "100%"
    }),

    transcriptionTable: (props) => ({
        height: "5px",
        width: "100%",
        fontSize: 5
    }),
    popover: (props) => ({
        height: "100%",
        width: "100%",
        margin: "3px",
        fontSize: 5
    })
});

type InputProps = {
    ortText: string;
    phonText: string;
    loading: boolean;
    orthographic: string[];
    phonetic: string[];
    userRules: Rule[] | undefined;
    transcribedWord: string[];
    mappedWord: string[];
    ortographicFields: string[];
    setMappedWord: Dispatch<string[]>;
    setOrtographicFields: any;
    input0: string;
    input1: string;
    input2: string;
    input3: string;
    input4: string;
    input5: string;
    input6: string;
    input7: string;
    input8: string;
    input9: string;
    setInput0: Dispatch<string>;
    setInput1: Dispatch<string>;
    setInput2: Dispatch<string>;
    setInput3: Dispatch<string>;
    setInput4: Dispatch<string>;
    setInput5: Dispatch<string>;
    setInput6: Dispatch<string>;
    setInput7: Dispatch<string>;
    setInput8: Dispatch<string>;
    setInput9: Dispatch<string>;
};

export default function TranscriptInputBox({
    ortText,
    phonText,
    loading,
    orthographic,
    phonetic,
    userRules,
    transcribedWord,
    mappedWord,
    ortographicFields,
    setMappedWord,
    setOrtographicFields,
    input0,
    input1,
    input2,
    input3,
    input4,
    input5,
    input6,
    input7,
    input8,
    input9,
    setInput0,
    setInput1,
    setInput2,
    setInput3,
    setInput4,
    setInput5,
    setInput6,
    setInput7,
    setInput8,
    setInput9
}: InputProps) {
    const classes = useStyles();
    const [field, setField] = useState(-1);
    const [expand, setExpand] = useState(false);
    const [showConsonant, isConsonant] = useState<boolean>(false);
    const [showVowel, isVowel] = useState<boolean>(false);
    const [newMappedWord] = useState<string[]>([]);
    const [transcriptionWarning, setTranscriptionWarning] = useState<boolean>(false);

    const [empty0, setEmpty0] = useState<boolean>(false);
    const [empty1, setEmpty1] = useState<boolean>(false);
    const [empty2, setEmpty2] = useState<boolean>(false);
    const [empty3, setEmpty3] = useState<boolean>(false);
    const [empty4, setEmpty4] = useState<boolean>(false);
    const [empty5, setEmpty5] = useState<boolean>(false);
    const [empty6, setEmpty6] = useState<boolean>(false);
    const [empty7, setEmpty7] = useState<boolean>(false);
    const [empty8, setEmpty8] = useState<boolean>(false);
    const [empty9, setEmpty9] = useState<boolean>(false);

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.KeyboardEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTranscriptionWarning(false);
    };

    //Input handlers
    const handleInput = useCallback(
        (input: string, i: number) => {
            // TODO: Fix ruleSet input
            if (!userRules) {
                console.log("No rules set");
                return;
            }
            transcribedWord.splice(i, 1, input);
            for (let i = 0; i < transcribedWord.length; i++) {
                if (transcribedWord[i] === "" && orthographic[i] !== undefined) {
                    transcribedWord[i] = orthographic[i];
                }
            }

            const qw = new Word(userRules, transcribedWord, i, phonetic, orthographic);
            const newWord = qw.transcribe();
            setMappedWord(newWord);
        },
        [transcribedWord, userRules, phonetic, orthographic, setMappedWord]
    );

    // Skriv inn fra IPA-chart - ta med feltnummer:
    const getFieldNumber = useCallback((fieldNumber: number) => {
        setField(fieldNumber);
    }, []);

    const closeAccordion = () => {
        isConsonant(false);
        isVowel(false);
        setExpand(false);
    };

    //Finn ut hvilken ipachart som skal vises:
    const getTokenType = useCallback(
        (s: string) => {
            if (!userRules) {
                console.log("No rules set");
                return;
            }
            if (!s) {
                isConsonant(true);
                isVowel(true);
                return;
            }
            const qw = new Word(userRules, Array.from(s), undefined, phonetic, orthographic);
            if (qw.dataListHead.current.token === ("Vowel" || "Diphthong")) {
                isVowel(true);
            } else {
                isConsonant(true);
            }
        },
        [userRules, isConsonant, isVowel, orthographic, phonetic]
    );

    const removeLastChar = (s: string, i: number): string => {
        if (!s || s === "") {
            return "";
        }
        mappedWord[i] = s.substring(0, s.length - 1);
        console.log(mappedWord[i]);

        return mappedWord[i];
    };

    const setIPAValue = useCallback(
        (i: string) => {
            switch (field) {
                case 0:
                    if (mappedWord[0] === ("" || undefined)) {
                        setInput0(i);
                        console.log(mappedWord, newMappedWord, i);
                        mappedWord[0] = i;
                        setMappedWord(mappedWord);
                    } else {
                        if (mappedWord[0] === phonetic[0]) {
                            setInput0(i);
                            mappedWord[0] = i;

                            setMappedWord(mappedWord);
                        } else {
                            setInput0(mappedWord[0] + i);
                            mappedWord[0] = mappedWord[0] + i;
                            setMappedWord(mappedWord);
                        }
                    }
                    break;
                case 1:
                    if (mappedWord[1] === ("" || undefined)) {
                        setInput1(i);
                        mappedWord[1] = i;

                        setMappedWord(mappedWord);
                    } else {
                        if (mappedWord[1] === phonetic[1]) {
                            setInput1(i);
                            mappedWord[1] = i;

                            setMappedWord(mappedWord);
                        } else {
                            setInput1(mappedWord[1] + i);
                            mappedWord[1] = mappedWord[1] + i;

                            setMappedWord(mappedWord);
                        }
                    }

                    break;
                case 2:
                    if (mappedWord[2] === ("" || undefined)) {
                        setInput2(i);
                        mappedWord[2] = i;

                        setMappedWord(mappedWord);
                    } else {
                        if (mappedWord[2] === phonetic[2]) {
                            setInput2(i);
                            mappedWord[2] = i;

                            setMappedWord(mappedWord);
                        } else {
                            setInput2(mappedWord[2] + i);
                            mappedWord[2] = mappedWord[2] + i;

                            setMappedWord(mappedWord);
                        }
                    }

                    break;
                case 3:
                    if (mappedWord[3] !== ("" || undefined)) {
                        setInput3(i);
                        mappedWord[3] = i;

                        setMappedWord(mappedWord);
                    } else {
                        if (mappedWord[3] === phonetic[3]) {
                            setInput3(i);
                            mappedWord[3] = i;

                            setMappedWord(mappedWord);
                        } else {
                            setInput3(mappedWord[3] + i);
                            mappedWord[3] = mappedWord[3] + i;

                            setMappedWord(mappedWord);
                        }
                    }

                    break;
                case 4:
                    if (mappedWord[4] === ("" || undefined)) {
                        setInput4(i);
                        mappedWord[4] = i;

                        setMappedWord(mappedWord);
                    } else {
                        if (mappedWord[4] === phonetic[4]) {
                            setInput4(i);
                            mappedWord[4] = i;

                            setMappedWord(mappedWord);
                        } else {
                            setInput4(mappedWord[4] + i);
                            mappedWord[4] = mappedWord[4] + i;

                            setMappedWord(mappedWord);
                        }
                    }

                    break;
                case 5:
                    if (mappedWord[5] === ("" || undefined)) {
                        setInput5(i);
                        mappedWord[5] = i;

                        setMappedWord(mappedWord);
                    } else {
                        if (mappedWord[5] === phonetic[5]) {
                            setInput5(i);
                            mappedWord[5] = i;

                            setMappedWord(mappedWord);
                        } else {
                            setInput5(mappedWord[5] + i);
                            mappedWord[5] = mappedWord[5] + i;

                            setMappedWord(mappedWord);
                        }
                    }

                    break;
                case 6:
                    if (mappedWord[6] === ("" || undefined)) {
                        setInput6(i);
                        mappedWord[6] = i;

                        setMappedWord(mappedWord);
                    } else {
                        if (mappedWord[6] === phonetic[6]) {
                            setInput6(i);
                            mappedWord[6] = i;

                            setMappedWord(mappedWord);
                        } else {
                            setInput6(mappedWord[6] + i);
                            mappedWord[6] = mappedWord[6] + i;

                            setMappedWord(mappedWord);
                        }
                    }
                    break;

                case 7:
                    if (mappedWord[7] === ("" || undefined)) {
                        setInput7(i);
                        mappedWord[7] = i;

                        setMappedWord(mappedWord);
                    } else {
                        if (mappedWord[7] === phonetic[7]) {
                            setInput7(i);
                            mappedWord[7] = i;

                            setMappedWord(mappedWord);
                        } else {
                            setInput7(mappedWord[7] + i);
                            mappedWord[7] = mappedWord[7] + i;

                            setMappedWord(mappedWord);
                        }
                    }
                    break;

                case 8:
                    if (mappedWord[8] === ("" || undefined)) {
                        setInput8(i);
                        mappedWord[8] = i;

                        setMappedWord(mappedWord);
                    } else {
                        if (mappedWord[8] === phonetic[8]) {
                            setInput8(i);
                            mappedWord[8] = i;

                            setMappedWord(mappedWord);
                        } else {
                            setInput8(mappedWord[8] + i);
                            mappedWord[8] = mappedWord[8] + i;

                            setMappedWord(mappedWord);
                        }
                    }
                    break;

                case 9:
                    if (mappedWord[9] === ("" || undefined)) {
                        setInput9(i);
                        mappedWord[9] = i;

                        setMappedWord(mappedWord);
                    } else {
                        if (mappedWord[9] === phonetic[9]) {
                            setInput9(i);
                            mappedWord[9] = i;

                            setMappedWord(mappedWord);
                        } else {
                            setInput9(mappedWord[9] + i);
                            mappedWord[9] = mappedWord[9] + i;

                            setMappedWord(mappedWord);
                        }
                    }
                    break;
                default:
                    break;
            }
        },
        [
            field,
            setInput0,
            setInput1,
            setInput2,
            setInput3,
            setInput4,
            setInput5,
            setInput6,
            setInput7,
            setInput8,
            setInput9,
            mappedWord,
            newMappedWord,
            setMappedWord,
            phonetic
        ]
    );

    const update = useCallback(
        (s: string, i: number) => {
            handleInput(s, i);
            console.log(s, i);

            setOrtographicFields((prevState: string[]) => {
                return prevState.map((item, index) => {
                    if (index === i) {
                        return s;
                    }
                    return item;
                });
            });

            for (let y in ortographicFields) {
                if (!ortographicFields[y]) {
                    ortographicFields[y] = orthographic[y];
                }
            }
        },
        [handleInput, setOrtographicFields, ortographicFields, orthographic]
    );

    let temp: string[] = [];

    for (const y in mappedWord) {
        if (newMappedWord[y] && newMappedWord[y] !== "") {
            mappedWord[y] = newMappedWord[y];
            temp = mappedWord;
        }
    }

    if (temp.length > 0) {
        console.log(mappedWord, mappedWord);

        setMappedWord(mappedWord);
    }

    useEffect(() => {}, [update, setIPAValue]);

    return (
        <>
            {loading ? (
                <>
                    <Skeleton variant="rectangular" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </>
            ) : (
                <Grid container>
                    <Grid className={classes.ortContainer}>
                        <Grid container>
                            <Typography
                                variant="h5"
                                style={{
                                    alignItems: "right",
                                    padding: "25px"
                                }}
                                color="#fff"
                                fontStyle={"bold"}
                                fontSize="30px">
                                {ortText}
                            </Typography>
                        </Grid>
                        <Grid container justifyContent="center" className={classes.textO}>
                            <Tooltip
                                arrow
                                open={empty0}
                                placement="top"
                                title={
                                    <Button
                                        sx={{ background: "white", color: "black" }}
                                        onClick={() => {
                                            setInput0("∅");
                                            update("∅", 0);
                                            setEmpty0(false);
                                        }}>
                                        ∅ (tom)?
                                    </Button>
                                }
                                children={
                                    <TextField
                                        id="ortfield0"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={orthographic[0]}
                                        onChange={(e) => {
                                            update(e.target.value, 0);
                                            setInput0(e.target.value);

                                            if (e.target.value === "") {
                                                setEmpty0(true);
                                            } else {
                                                setEmpty0(false);
                                            }
                                        }}
                                        value={ortographicFields[0]}
                                    />
                                }
                            />
                            <Tooltip
                                arrow
                                open={empty1}
                                placement="top"
                                title={
                                    <Button
                                        sx={{ background: "white", color: "black" }}
                                        onClick={() => {
                                            setInput1("∅");
                                            update("∅", 1);
                                            setEmpty1(false);
                                        }}>
                                        ∅ (tom)?
                                    </Button>
                                }
                                children={
                                    <TextField
                                        id="ortfield1"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={orthographic[1]}
                                        onChange={(e) => {
                                            update(e.target.value, 1);
                                            setInput1(e.target.value);

                                            if (e.target.value === "") {
                                                setEmpty1(true);
                                            } else {
                                                setEmpty1(false);
                                            }
                                        }}
                                        value={ortographicFields[1]}
                                    />
                                }
                            />
                            <Tooltip
                                arrow
                                open={empty2}
                                placement="top"
                                title={
                                    <Button
                                        sx={{ background: "white", color: "black" }}
                                        onClick={() => {
                                            setInput2("∅");
                                            update("∅", 2);
                                            setEmpty2(false);
                                        }}>
                                        ∅ (tom)?
                                    </Button>
                                }
                                children={
                                    <TextField
                                        id="ortfield2"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={orthographic[2]}
                                        onChange={(e) => {
                                            update(e.target.value, 2);
                                            setInput2(e.target.value);

                                            if (e.target.value === "") {
                                                setEmpty2(true);
                                            } else {
                                                setEmpty2(false);
                                            }
                                        }}
                                        value={ortographicFields[2]}
                                    />
                                }
                            />
                            <Tooltip
                                arrow
                                open={empty3}
                                placement="top"
                                title={
                                    <Button
                                        sx={{ background: "white", color: "black" }}
                                        onClick={() => {
                                            setInput3("∅");
                                            update("∅", 3);
                                            setEmpty3(false);
                                        }}>
                                        ∅ (tom)?
                                    </Button>
                                }
                                children={
                                    <TextField
                                        id="ortfield3"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={orthographic[3]}
                                        onChange={(e) => {
                                            update(e.target.value, 3);
                                            setInput3(e.target.value);

                                            if (e.target.value === "") {
                                                setEmpty3(true);
                                            } else {
                                                setEmpty3(false);
                                            }
                                        }}
                                        value={ortographicFields[3]}
                                    />
                                }
                            />
                            <Tooltip
                                arrow
                                open={empty4}
                                placement="top"
                                title={
                                    <Button
                                        sx={{ background: "white", color: "black" }}
                                        onClick={() => {
                                            setInput4("∅");
                                            update("∅", 4);
                                            setEmpty4(false);
                                        }}>
                                        ∅ (tom)?
                                    </Button>
                                }
                                children={
                                    <TextField
                                        id="ortfield4"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={orthographic[4]}
                                        onChange={(e) => {
                                            update(e.target.value, 4);
                                            setInput4(e.target.value);

                                            if (e.target.value === "") {
                                                setEmpty4(true);
                                            } else {
                                                setEmpty4(false);
                                            }
                                        }}
                                        value={ortographicFields[4]}
                                    />
                                }
                            />
                            <Tooltip
                                arrow
                                open={empty5}
                                placement="top"
                                title={
                                    <Button
                                        sx={{ background: "white", color: "black" }}
                                        onClick={() => {
                                            setInput5("∅");
                                            update("∅", 5);
                                            setEmpty5(false);
                                        }}>
                                        ∅ (tom)?
                                    </Button>
                                }
                                children={
                                    <TextField
                                        id="ortfield5"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={orthographic[5]}
                                        onChange={(e) => {
                                            update(e.target.value, 5);
                                            setInput5(e.target.value);

                                            if (e.target.value === "") {
                                                setEmpty5(true);
                                            } else {
                                                setEmpty5(false);
                                            }
                                        }}
                                        value={ortographicFields[5]}
                                    />
                                }
                            />
                            <Tooltip
                                arrow
                                open={empty6}
                                placement="top"
                                title={
                                    <Button
                                        sx={{ background: "white", color: "black" }}
                                        onClick={() => {
                                            setInput6("∅");
                                            update("∅", 6);
                                            setEmpty6(false);
                                        }}>
                                        ∅ (tom)?
                                    </Button>
                                }
                                children={
                                    <TextField
                                        id="ortfield6"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={orthographic[6]}
                                        onChange={(e) => {
                                            update(e.target.value, 6);
                                            setInput6(e.target.value);

                                            if (e.target.value === "") {
                                                setEmpty6(true);
                                            } else {
                                                setEmpty6(false);
                                            }
                                        }}
                                        value={ortographicFields[6]}
                                    />
                                }
                            />
                            <Tooltip
                                arrow
                                open={empty7}
                                placement="top"
                                title={
                                    <Button
                                        sx={{ background: "white", color: "black" }}
                                        onClick={() => {
                                            setInput7("∅");
                                            update("∅", 7);
                                            setEmpty7(false);
                                        }}>
                                        ∅ (tom)?
                                    </Button>
                                }
                                children={
                                    <TextField
                                        id="ortfield7"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={orthographic[7]}
                                        onChange={(e) => {
                                            update(e.target.value, 7);
                                            setInput7(e.target.value);

                                            if (e.target.value === "") {
                                                setEmpty7(true);
                                            } else {
                                                setEmpty7(false);
                                            }
                                        }}
                                        value={ortographicFields[7]}
                                    />
                                }
                            />
                            <Tooltip
                                arrow
                                open={empty8}
                                placement="top"
                                title={
                                    <Button
                                        sx={{ background: "white", color: "black" }}
                                        onClick={() => {
                                            setInput8("∅");
                                            update("∅", 8);
                                            setEmpty8(false);
                                        }}>
                                        ∅ (tom)?
                                    </Button>
                                }
                                children={
                                    <TextField
                                        id="ortfield8"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={orthographic[8]}
                                        onChange={(e) => {
                                            update(e.target.value, 8);
                                            setInput8(e.target.value);

                                            if (e.target.value === "") {
                                                setEmpty8(true);
                                            } else {
                                                setEmpty8(false);
                                            }
                                        }}
                                        value={ortographicFields[8]}
                                    />
                                }
                            />
                            <Tooltip
                                arrow
                                open={empty9}
                                placement="top"
                                title={
                                    <Button
                                        sx={{ background: "white", color: "black" }}
                                        onClick={() => {
                                            setInput9("∅");
                                            update("∅", 9);
                                            setEmpty9(false);
                                        }}>
                                        ∅ (tom)?
                                    </Button>
                                }
                                children={
                                    <TextField
                                        id="ortfield9"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={orthographic[9]}
                                        onChange={(e) => {
                                            update(e.target.value, 9);
                                            setInput9(e.target.value);

                                            if (e.target.value === "") {
                                                setEmpty9(true);
                                            } else {
                                                setEmpty9(false);
                                            }
                                        }}
                                        value={ortographicFields[9]}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <Accordion
                        style={{ borderRadius: 20 }}
                        disableGutters
                        expanded={expand}
                        className={classes.phonContainer}>
                        <AccordionSummary>
                            <Grid>
                                <Grid container>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            alignItems: "right",
                                            padding: "25px"
                                        }}
                                        color="#fff"
                                        fontStyle={"bold"}
                                        fontSize="30px">
                                        {phonText}
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent="center" className={classes.textP}>
                                    <TextField
                                        id="field0"
                                        type="text"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={phonetic[0]}
                                        value={input0}
                                        onFocus={(e) => {
                                            getTokenType(orthographic[0]);
                                            setExpand(true);
                                            getFieldNumber(0);
                                            if (input0 === "" || !input0) {
                                                input0 = phonetic[0];
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Backspace") {
                                                setInput0(removeLastChar(mappedWord[0], 0));
                                            } else {
                                                handleClick(e);
                                                setTranscriptionWarning(true);
                                            }
                                        }}
                                    />
                                    <Popover
                                        className={classes.popover}
                                        open={transcriptionWarning}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left"
                                        }}>
                                        <Typography sx={{ background: "#383535", color: "#fff" }}>
                                            Transkribering med tastatur er ikke mulig. Bruk IPA chart under
                                        </Typography>
                                    </Popover>
                                    <TextField
                                        id="field1"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={phonetic[1]}
                                        value={input1}
                                        onFocus={() => {
                                            getTokenType(orthographic[1]);
                                            setExpand(true);
                                            getFieldNumber(1);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Backspace") {
                                                setInput1(removeLastChar(mappedWord[1], 1));
                                            } else {
                                                handleClick(e);
                                                setTranscriptionWarning(true);
                                            }
                                        }}
                                    />
                                    <Popover
                                        className={classes.popover}
                                        open={transcriptionWarning}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left"
                                        }}>
                                        <Typography sx={{ background: "#383535", color: "#fff" }}>
                                            Transkribering med tastatur er ikke mulig. Bruk IPA chart under.
                                        </Typography>
                                    </Popover>
                                    <TextField
                                        id="field2"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={phonetic[2]}
                                        value={input2}
                                        onFocus={() => {
                                            getTokenType(orthographic[2]);
                                            setExpand(true);
                                            getFieldNumber(2);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Backspace") {
                                                setInput2(removeLastChar(mappedWord[2], 2));
                                            } else {
                                                handleClick(e);
                                                setTranscriptionWarning(true);
                                            }
                                        }}
                                    />
                                    <Popover
                                        className={classes.popover}
                                        open={transcriptionWarning}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left"
                                        }}>
                                        <Typography sx={{ background: "#383535", color: "#fff" }}>
                                            Transkribering med tastatur er ikke mulig. Bruk IPA chart under.
                                        </Typography>
                                    </Popover>
                                    <TextField
                                        id="field3"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={phonetic[3]}
                                        value={input3}
                                        onFocus={() => {
                                            getTokenType(orthographic[3]);
                                            setExpand(true);
                                            getFieldNumber(3);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Backspace") {
                                                setInput3(removeLastChar(mappedWord[3], 3));
                                            } else {
                                                handleClick(e);
                                                setTranscriptionWarning(true);
                                            }
                                        }}
                                    />
                                    <Popover
                                        className={classes.popover}
                                        open={transcriptionWarning}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left"
                                        }}>
                                        <Typography sx={{ background: "#383535", color: "#fff" }}>
                                            Transkribering med tastatur er ikke mulig. Bruk IPA chart under.
                                        </Typography>
                                    </Popover>
                                    <TextField
                                        id="field4"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={phonetic[4]}
                                        value={input4}
                                        onFocus={(e) => {
                                            getTokenType(orthographic[4]);
                                            setExpand(true);
                                            getFieldNumber(4);
                                        }}
                                        onKeyDown={(e) => {
                                            console.log();
                                            if (e.key === "Backspace") {
                                                setInput4(removeLastChar(mappedWord[4], 4));
                                            } else {
                                                handleClick(e);
                                                setTranscriptionWarning(true);
                                            }
                                        }}
                                    />
                                    <Popover
                                        className={classes.popover}
                                        open={transcriptionWarning}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left"
                                        }}>
                                        <Typography sx={{ background: "#383535", color: "#fff" }}>
                                            Transkribering med tastatur er ikke mulig. Bruk IPA chart under.
                                        </Typography>
                                    </Popover>
                                    <TextField
                                        id="field5"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={phonetic[5]}
                                        value={input5}
                                        onFocus={() => {
                                            getTokenType(orthographic[5]);
                                            setExpand(true);
                                            getFieldNumber(5);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Backspace") {
                                                setInput5(removeLastChar(mappedWord[5], 5));
                                            } else {
                                                handleClick(e);
                                                setTranscriptionWarning(true);
                                            }
                                        }}
                                    />
                                    <Popover
                                        className={classes.popover}
                                        open={transcriptionWarning}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left"
                                        }}>
                                        <Typography sx={{ background: "#383535", color: "#fff" }}>
                                            Transkribering med tastatur er ikke mulig. Bruk IPA chart under.
                                        </Typography>
                                    </Popover>
                                    <TextField
                                        id="field6"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={phonetic[6]}
                                        value={input6}
                                        onFocus={() => {
                                            getTokenType(orthographic[6]);
                                            setExpand(true);
                                            getFieldNumber(6);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Backspace") {
                                                setInput6(removeLastChar(mappedWord[6], 6));
                                            } else {
                                                handleClick(e);
                                                setTranscriptionWarning(true);
                                            }
                                        }}
                                    />
                                    <Popover
                                        className={classes.popover}
                                        open={transcriptionWarning}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left"
                                        }}>
                                        <Typography sx={{ background: "#383535", color: "#fff" }}>
                                            Transkribering med tastatur er ikke mulig. Bruk IPA chart under.
                                        </Typography>
                                    </Popover>
                                    <TextField
                                        id="field7"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={phonetic[7]}
                                        value={input7}
                                        onFocus={() => {
                                            getTokenType(orthographic[7]);
                                            setExpand(true);
                                            getFieldNumber(7);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Backspace") {
                                                setInput7(removeLastChar(mappedWord[7], 7));
                                            } else {
                                                handleClick(e);
                                                setTranscriptionWarning(true);
                                            }
                                        }}
                                    />
                                    <Popover
                                        className={classes.popover}
                                        open={transcriptionWarning}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left"
                                        }}>
                                        <Typography sx={{ background: "#383535", color: "#fff" }}>
                                            Transkribering med tastatur er ikke mulig. Bruk IPA chart under.
                                        </Typography>
                                    </Popover>
                                    <TextField
                                        id="field8"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={phonetic[8]}
                                        value={input8}
                                        onFocus={() => {
                                            getTokenType(orthographic[8]);
                                            setExpand(true);
                                            getFieldNumber(8);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Backspace") {
                                                setInput8(removeLastChar(mappedWord[8], 8));
                                            } else {
                                                handleClick(e);
                                                setTranscriptionWarning(true);
                                            }
                                        }}
                                    />
                                    <Popover
                                        className={classes.popover}
                                        open={transcriptionWarning}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left"
                                        }}>
                                        <Typography sx={{ background: "#383535", color: "#fff" }}>
                                            Transkribering med tastatur er ikke mulig. Bruk IPA chart under.
                                        </Typography>
                                    </Popover>
                                    <TextField
                                        id="field9"
                                        className={classes.textField}
                                        variant={"outlined"}
                                        size={"small"}
                                        placeholder={phonetic[9]}
                                        value={input9}
                                        onFocus={() => {
                                            getTokenType(orthographic[6]);
                                            setExpand(true);
                                            getFieldNumber(9);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Backspace") {
                                                setInput9(removeLastChar(mappedWord[9], 9));
                                            } else {
                                                handleClick(e);
                                                setTranscriptionWarning(true);
                                            }
                                        }}
                                    />
                                    <Popover
                                        className={classes.popover}
                                        open={transcriptionWarning}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left"
                                        }}>
                                        <Typography sx={{ background: "#383535", color: "#fff" }}>
                                            Transkribering med tastatur er ikke mulig. Bruk IPA chart under.
                                        </Typography>
                                    </Popover>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails hidden={!showConsonant}>
                            <Grid container direction="row" justifyContent={"space-between"}>
                                <Grid item>
                                    <Button
                                        style={{ background: "rgb(205, 31, 31)", color: "white" }}
                                        variant="outlined"
                                        onClick={closeAccordion}>
                                        X
                                    </Button>
                                    {!showVowel && showConsonant ? (
                                        <Button
                                            variant="outlined"
                                            style={{ background: "rgb(255, 251, 55)", color: "Grey" }}
                                            onClick={() => isVowel(true)}>
                                            Vokaler
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Typography color={"#fff"} fontWeight={"bold"}>
                                        KONSONANTER
                                    </Typography>
                                </Grid>
                                <Consonants setIPAValue={setIPAValue} />
                            </Grid>
                        </AccordionDetails>
                        <AccordionDetails hidden={!showVowel}>
                            <Grid container direction="row" justifyContent={"space-between"}>
                                <Grid item>
                                    <Button
                                        style={{ background: "rgb(205, 31, 31)", color: "white" }}
                                        variant="outlined"
                                        onClick={closeAccordion}>
                                        X
                                    </Button>
                                    {showVowel && !showConsonant ? (
                                        <Button
                                            variant="outlined"
                                            style={{ background: "rgb(255, 251, 55)", color: "Grey" }}
                                            onClick={() => isConsonant(true)}>
                                            Konsonant
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Typography color={"#fff"} fontWeight={"bold"}>
                                        VOKALER
                                    </Typography>
                                </Grid>
                                <Vowels setValue={setIPAValue} />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
        </>
    );
}

/*

*/
