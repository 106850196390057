import { Button, Container } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import Results from "src/components/Analyzing/Results/Results";
import { TestParticipantInfo } from "src/components/Test/Therapist/Context";
import Modal from "../Modal/Modal";

type AnalyzeModalProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    id: number | undefined;
    testInfo?: TestParticipantInfo;
};
export default function AnalyzeModal({ open, setOpen, id, testInfo }: AnalyzeModalProps) {
    return (
        <Container>
            <Modal
                id="analyze-modal"
                open={open}
                fullScreen={false}
                onClose={setOpen}
                renderContent={() => (
                    <Container
                        style={{ width: "2000px" }}
                        onClickCapture={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}>
                        <Results testInfo={testInfo} id={id} />
                    </Container>
                )}
                renderActions={() => <Button onClick={() => setOpen(false)}>lukk</Button>}
            />
        </Container>
    );
}
