import { TestText } from "../text/TestText";
import { AnalyzeText } from "../text/AnalyzeText";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../theme";

//Styles the stepper.
export const StepperStyles = makeStyles({
    stepperIcon : (props) => ({
        "&:active": {
            color: "#227a76",
        }
    }),
    button: (props) => ({
        margin: DnfTheme.spacing(3),
        paddingBottom: DnfTheme.spacing(5),
        backgroundColor: "#3c469f",
        color: "#fff"
    }),
    actionsContainer: (props) => ({
        marginBottom: DnfTheme.spacing(2)
    }),
    resetContainer: (props) => ({
        padding: DnfTheme.spacing(3)
    }),
    instructions: (props) => ({
        minWidth: "650px",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        marginTop: 50,
        marginBottom: 50,
        fontSize: 30
    })
});

//All steps from test
export const TestSteps = () => {
    let options = [TestText.steps.step0, TestText.steps.step1, TestText.steps.step2, TestText.steps.step3];
    return options;
};

//Content from each step in test
export const TestStepsContent = (step: number) => {
    switch (step) {
        case 0:
            return TestText.stepsContent.sc0;
        case 1:
            return undefined;
        case 2:
            return TestText.stepsContent.sc3;
        case 3:
            return TestText.stepsContent.sc4;
        default:
            return TestText.stepsContent.default;
    }
};

//All steps from analyze
export const AnalyzeSteps = () => {
    return [AnalyzeText.steps.step0, AnalyzeText.steps.step1, AnalyzeText.steps.step2];
};

//Content from each step in analyze
export const AnalyzeStepsContent = (stepIndex: number) => {
    switch (stepIndex) {
        case 0:
            return AnalyzeText.stepsContent.sC0;
        case 1:
            return AnalyzeText.stepsContent.sC1;
        case 2:
            return AnalyzeText.stepsContent.sC2;
        default:
            return AnalyzeText.stepsContent.defualt;
    }
};
