import { Backdrop, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Dispatch, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import Webcam from "react-webcam";
import InformationBox from "src/components/Analyzing/Results/InformationBox";
import TranscriptInputBox from "src/components/TextFields/TranscriptInputBox";
import { Rule } from "src/model/Rule";
import RulesService from "src/Services/RulesService";
import { useHistory } from "react-router-dom";
import QuestionService from "../../../Services/QuestionService";
import { TestText } from "../../../text/TestText";
import { MyContext, TestParticipantInfo } from "./Context";
import ControlBar from "./ControlBar";
import Image from "./Image";
import { DnfTheme } from "src/theme";
import TestService from "src/Services/TestService";

const useStyles = makeStyles({
    transcript: () => ({
        backgroundColor: "rgba(114,110,110,0.09)",
        borderBlockColor: "black",
        borderWidth: "12px"
    }),
    webcam: () => ({
        marginRight: "2%",
        marginTop: "320px",
        borderRadius: 20,
        align: "bottom"
    }),
    divider: (props) => ({
        borderColor: "#165b58",
        borderWidth: "0.5px",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "1%",
        marginRight: "3%",
        border: "solid",
        height: ""
    }),
    backdrop: (props) => ({
        zIndex: DnfTheme.zIndex.drawer + 1,
        color: "#fff"
    }),
    progressBar: (props) => ({
        color: "inherit",
        textAlign: "center"
    })
});
type TranscriptProps = {
    testInfo: TestParticipantInfo;
    setActiveStep: Dispatch<number>;
};
/**
 * Component to transcribe sound values.
 * */
export default function Transcript({ testInfo, setActiveStep }: TranscriptProps): any {
    // TODO: Refactor to fetch the whole series of questions when starting.
    //       For each answer send the answer back to backend.
    //       Backend stores the answers - this is to make it possible to analyze a
    //       completed test with a new ruleset.
    //       Current ruleset should also be fetched here and the
    //       auto-transcriber-thingy should be setup with the ruleset just
    //       fetched from the backend.
    const classes = useStyles();
    const [cookies] = useCookies(["jwt", "id_sp"]);
    const [userRules, setUserRules] = useState<Rule[]>();
    const history = useHistory();
    // ikke i bruk?

    const { questionIndexValue, questionListValue, transcriptValue, nextValue, tpValue } = useContext(MyContext);

    if (!questionIndexValue || !questionListValue || !transcriptValue || !nextValue || !tpValue) {
        throw new Error("Context missing value.");
    }

    const [questionIndex] = questionIndexValue;
    const [questionList, setQuestionList] = questionListValue;
    const [tpInfo, setTpInfo] = tpValue;

    // TODO: Rewrite as actual function
    const [next, setNext] = nextValue;
    const [loading, setLoading] = useState(true);
    const [orthographic, setOrthographic]: [string[], Dispatch<any>] = useState([]);
    const [phonetic, setPhonetic]: [string[], Dispatch<any>] = useState([]);
    let id_test: string | null = localStorage.getItem("id_test");

    //State for checkbox
    const [isCorrectPronunciation, setCorrectPronunciation] = useState<boolean>(true);
    const [isImitation, setImitation] = useState(false);
    //Transcribed value
    let [transcribedWord, setTranscribedWord] = useState(["", "", "", "", "", "", "", "", "", ""]);
    let [mappedWord, setMappedWord] = useState<Array<string>>([]);

    //TextFields
    const [input0, setInput0] = useState<string>("");
    const [input1, setInput1] = useState<string>("");
    const [input2, setInput2] = useState<string>("");
    const [input3, setInput3] = useState<string>("");
    const [input4, setInput4] = useState<string>("");
    const [input5, setInput5] = useState<string>("");
    const [input6, setInput6] = useState<string>("");
    const [input7, setInput7] = useState<string>("");
    const [input8, setInput8] = useState<string>("");
    const [input9, setInput9] = useState<string>("");
    const [ortographicFields, setOrtographicFields] = useState<Array<string>>(["", "", "", "", "", "", "", "", "", ""]);
    // State for webcam recording
    const webcamRef = useRef<Webcam>(null);
    const mediaRecorderRef = useRef<MediaRecorder>();

    //Feedback form snackbar
    const [finished, setFinished] = useState<boolean>(false);
    const [waiting, setWaiting] = useState<boolean>(false);

    const [videoDataPromise, setVideoDataPromise] = useState<Promise<Blob>>();

    const loadUserRules = useCallback(async () => {
        try {
            const loadedUserRuleset = await RulesService.getRuleset(cookies.jwt);
            setUserRules(loadedUserRuleset.data);
        } catch (error) {
            // TODO: Handle error
            let message = "";
            if (typeof error === "string") {
                message = error;
            } else if (error instanceof Error) {
                message = error.message;
            }

            throw new Error(message);
        }
    }, [cookies.jwt]);

    const updateAllInputFields = useCallback(() => {
        if (mappedWord[0] !== undefined) {
            setInput0(mappedWord[0]);
        }
        if (mappedWord[1] !== undefined) {
            setInput1(mappedWord[1]);
        }
        if (mappedWord[2] !== undefined) {
            setInput2(mappedWord[2]);
        }
        if (mappedWord[3] !== undefined) {
            setInput3(mappedWord[3]);
        }
        if (mappedWord[4] !== undefined) {
            setInput4(mappedWord[4]);
        }
        if (mappedWord[5] !== undefined) {
            setInput5(mappedWord[5]);
        }
        if (mappedWord[6] !== undefined) {
            setInput6(mappedWord[6]);
        }
        if (mappedWord[7] !== undefined) {
            setInput7(mappedWord[7]);
        }
        if (mappedWord[8] !== undefined) {
            setInput8(mappedWord[8]);
        }
        if (mappedWord[9] !== undefined) {
            setInput9(mappedWord[9]);
        }
    }, [mappedWord]);

    //Wipes out input values in textFields. Not working ?????
    const wipeOutTextFields = useCallback(() => {
        setMappedWord([]);
        setInput0("");
        setInput1("");
        setInput2("");
        setInput3("");
        setInput4("");
        setInput5("");
        setInput6("");
        setInput7("");
        setInput8("");
        setInput9("");
        setOrtographicFields(["", "", "", "", "", "", "", "", "", ""]);
        setTranscribedWord(["", "", "", "", "", "", "", "", "", ""]);
    }, [setInput0, setInput1, setInput2, setInput4, setInput3, setInput5, setInput6, setInput7, setInput8, setInput9]);

    useEffect(() => {
        console.log("running effect in transcript, loadRules");

        loadUserRules();
    }, [loadUserRules]);

    useEffect(() => {
        if (mediaRecorderRef.current?.state === "inactive") {
            setLoading(true);
        }
    }, []);

    // Sends the speech therapists feedback back to the database
    const setTranscript = useCallback(async () => {
        // VideoRecordings...
        if (!mediaRecorderRef.current) {
            throw new Error("No media recorder reference found");
        }

        if (mediaRecorderRef.current.state === "inactive") {
            console.log("current mediarecorder state", mediaRecorderRef.current.state);
            setInterval(function () {}, 1000);
            return;
        }

        console.log(mediaRecorderRef.current.state);

        mediaRecorderRef.current.requestData();
        const videoBlob = await videoDataPromise;

        console.log("videoBlob", videoBlob);

        if (!videoBlob) {
            throw new Error("Video is null");
        }

        // Recursive method for slicing unused input fields.
        const sliceString: any = (input: string) => {
            let helpString = input;
            if (!helpString.endsWith("-", helpString.length)) {
                return helpString;
            } else {
                helpString = helpString.slice(0, -1);
                return sliceString(helpString);
            }
        };

        let transcribed_values: string;
        let temp: string[];
        let transcribed_orthographic: string;

        // Data from textFields id validated.
        if (input0 || input1 || input2 || input3 || input4 || input5 || input6 || input7 || input8 || input9) {
            temp = [input0, input1, input2, input3, input4, input5, input6, input7, input8, input9];

            for (const y in temp) {
                if (temp[y] === "" && phonetic[y]) {
                    temp[y] = phonetic[y];
                }
            }
            transcribed_values = temp.join("-");
        } else {
            transcribed_values = mappedWord.join("-");
        }

        transcribed_orthographic = ortographicFields.join("-");
        let predictedValue = sliceString(transcribed_values);
        let transcribedValue = sliceString(transcribed_orthographic);

        if (transcribedValue === "") {
            transcribedValue = orthographic.join("-");
        }

        // If checkbox is checked the expected sound value is set to predicted value.
        if (isCorrectPronunciation) {
            predictedValue = phonetic.join("-");
            transcribedValue = orthographic.join("-");
        }

        try {
            let qi: number = questionIndex - 1;

            if (orthographic.join("") === "tiger") {
                await QuestionService.submitAnswer(
                    questionList[questionIndex].test_id,
                    questionList[questionIndex].question_id,
                    predictedValue,
                    transcribedValue,
                    isCorrectPronunciation,
                    isImitation,
                    videoBlob,
                    cookies.jwt
                );
                setFinished(true);
                return;
            }

            let res = await QuestionService.submitAnswer(
                questionList[qi].test_id,
                questionList[qi].question_id,
                predictedValue,
                transcribedValue,
                isCorrectPronunciation,
                isImitation,
                videoBlob,
                cookies.jwt
            );

            console.log(res);

            if (res.status === 200) {
                //Kun fordi det er satt rekkefølge, må endres til å kun sjekke at det der siste bildet
                setCorrectPronunciation(false);
                setImitation(false);
                wipeOutTextFields();
                setNext(true);
            }
        } catch (error) {
            console.error(error);
            setNext(false);
        } finally {
            wipeOutTextFields();
        }
    }, [
        phonetic,
        orthographic,
        isCorrectPronunciation,
        isImitation,
        ortographicFields,
        input0,
        input1,
        input2,
        input3,
        input4,
        input5,
        input6,
        input7,
        input8,
        input9,
        videoDataPromise,
        mediaRecorderRef,
        mappedWord,
        questionIndex,
        questionList,
        setNext,
        cookies.jwt,
        wipeOutTextFields
    ]);

    useEffect(() => {
        if (questionList.length > 0 && questionIndex !== questionList.length) {
            setOrthographic(questionList[questionIndex].orthographic_transcription.split("-"));
        } else {
        }
    }, [questionList, questionIndex]);

    useEffect(() => {
        if (questionList.length > 0 && questionIndex !== questionList.length) {
            setPhonetic(questionList[questionIndex].phonetic_transcription.split("-"));
        }
    }, [questionList, questionIndex]);

    useEffect(() => {
        if (input0 || input1 || input2 || input3 || input4 || input5 || input6 || input7 || input8 || input9) {
            setCorrectPronunciation(false);
        } else {
            setCorrectPronunciation(true);
        }
    }, [input0, input1, input2, input3, input4, input5, input6, input7, input8, input9]);

    // @ts-ignore
    useEffect(() => {
        const runEffect = async () => {
            if (next && questionIndex <= questionList.length) {
                setNext(false);
                setWaiting(true);
                await setTranscript();
                setWaiting(false);
                setCorrectPronunciation(true);
            }

            if (questionList.length > 0 && finished === false) {
                setNext(false);
            } else {
                try {
                    setLoading(true);
                    const questionList = await QuestionService.getQuestionListByTestId(Number(id_test));
                    setQuestionList(questionList.data);
                } catch (error) {
                    console.error(error);
                    throw error;
                } finally {
                    setLoading(false);
                }
            }
        };

        runEffect();
        updateAllInputFields();
    }, [
        phonetic,
        questionList,
        tpInfo,
        next,
        transcribedWord,
        setNext,
        finished,
        questionIndex,
        setQuestionList,
        setTpInfo,
        setTranscript,
        updateAllInputFields,
        id_test,
        cookies.jwt,
        testInfo
    ]);

    useEffect(() => {
        let participant_id = localStorage.getItem("id_tp");
        let test_id = localStorage.getItem("id_test");

        if (finished) {
            TestService.registerCompletedTest(
                Number(cookies.id_sp),
                Number(participant_id),
                Number(test_id),
                cookies.jwt
            )
                .then((response) => {
                    if (response.status === 200) {
                        setFinished(false);
                        history.push("/results");
                    } else {
                        setFinished(false);
                        history.push("/analyze");
                    }
                })
                .catch((err) => {
                    setFinished(false);
                    console.error("avslutt test failet", err);
                    localStorage.removeItem("id_test");
                    history.push("/analyze");
                });
        }
    });

    const videoConstraints = {
        width: 800,
        height: 400,
        facingMode: "user"
    };
    const audioConstraints: MediaTrackConstraints = {
        suppressLocalAudioPlayback: true
    };

    const startRecording = (webcamStream: MediaStream) => {
        console.log("Capture called, start Recording..");
        mediaRecorderRef.current = new MediaRecorder(webcamStream, {
            mimeType: "video/webm"
        });

        const dataAvailablePromise = new Promise<Blob>((resolve) => {
            mediaRecorderRef.current?.addEventListener(
                "dataavailable",
                (blobEvent: BlobEvent) => {
                    resolve(blobEvent.data);
                },
                { once: true }
            );
        });
        setVideoDataPromise(dataAvailablePromise);

        mediaRecorderRef.current.start();
    };

    return (
        <>
            {loading ? (
                <CircularProgress />
            ) : waiting ? (
                <>
                    <Backdrop className={classes.backdrop} open={waiting}>
                        <CircularProgress className={classes.progressBar} />
                        {"   "}
                        <Typography>Lagrer transkripsjon og opptak..</Typography>
                    </Backdrop>
                </>
            ) : (
                <>
                    <Grid container direction="row" justifyContent="space-evenly">
                        <Grid item container direction="column" justifyContent="start">
                            <Grid item lg={6} md={3} xs={1} className={classes.webcam}>
                                <Webcam
                                    mirrored
                                    audio={true}
                                    height={400}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    width={800}
                                    audioConstraints={audioConstraints}
                                    videoConstraints={videoConstraints}
                                    onUserMedia={startRecording}
                                    muted={true}
                                />
                                <InformationBox
                                    testNumber={id_test}
                                    birthDate={new Date(testInfo.date_of_birth).toLocaleDateString()}
                                    participantName={testInfo.firstname + " " + testInfo.lastname}
                                    motherTongue={testInfo.motherTongue}
                                    otherLanguages={testInfo.languages}
                                    parent={testInfo.parent === "" ? "ikke registret" : testInfo.parent}
                                />
                            </Grid>
                            <Divider className={classes.divider} orientation="vertical" />

                            <Grid item lg={6} md={3} xs={1}>
                                <Image />
                                <Grid item lg={6} md={3} xs={1}>
                                    <TranscriptInputBox
                                        ortographicFields={ortographicFields}
                                        setOrtographicFields={setOrtographicFields}
                                        ortText={TestText.info.ort}
                                        phonText={TestText.info.fon}
                                        loading={loading}
                                        orthographic={orthographic}
                                        phonetic={phonetic}
                                        userRules={userRules}
                                        transcribedWord={transcribedWord}
                                        mappedWord={mappedWord}
                                        setMappedWord={setMappedWord}
                                        input0={input0}
                                        input1={input1}
                                        input2={input2}
                                        input3={input3}
                                        input4={input4}
                                        input5={input5}
                                        input6={input6}
                                        input7={input7}
                                        input8={input8}
                                        input9={input9}
                                        setInput0={setInput0}
                                        setInput1={setInput1}
                                        setInput2={setInput2}
                                        setInput3={setInput3}
                                        setInput4={setInput4}
                                        setInput5={setInput5}
                                        setInput6={setInput6}
                                        setInput7={setInput7}
                                        setInput8={setInput8}
                                        setInput9={setInput9}
                                    />

                                    <Grid>
                                        <ControlBar
                                            correct={isCorrectPronunciation}
                                            onNext={setNext}
                                            onCorrect={setCorrectPronunciation}
                                            onImitation={() => setImitation(true)}
                                            setFinished={setFinished}
                                            finished={finished}
                                            setActiveStep={setActiveStep}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
