import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "./Context";
import QuestionService from "../../../Services/QuestionService";
import clsx from "clsx";
import { TestText } from "../../../text/TestText";
import { Grid, CardMedia, Typography, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../../theme";

const useStyles = makeStyles({
    contents: (props) => ({
        flexGrow: 100,
        padding: DnfTheme.spacing(1),
        borderRadius: 10
    }),
    button: (props) => ({
        margin: DnfTheme.spacing(1),
        backgroundColor: "#21b6ae"
    }),
    cardMedia: (props) => ({
        maxWidth: 200,
        maxHeight: 200,
        minWidth: 200,
        minHeight: 200,
        marginRight: "30px",
        marginTop: 50,
        paddingBottom: "10px",

        borderRadius: 50
    }),
    text: (props) => ({
        fontSize: "0.9rem",
        paddingBottom: DnfTheme.spacing(3)
    })
});
/**
 * Displays image to pronounce on speech therapists screen.
 * */
export default function Image() {
    const classes = useStyles();
    // const { imageValue, imageListValue } = useContext(MyContext);
    const { questionIndexValue, questionListValue } = useContext(MyContext);
    const [currentImage, setCurrentImage] = useState("");
    const [currentImageWord, setCurrentImageWord] = useState("");
    const [currentImagePhonetic, setCurrentImagePhon] = useState("");

    const [questionIndex] = questionIndexValue !== undefined ? questionIndexValue : [undefined];
    const [questionList, setQuestionList] =
        questionListValue !== undefined ? questionListValue : [undefined, undefined];

    useEffect(() => {
        if (questionIndex === undefined || !questionList || !setQuestionList) {
            console.group("todo error");
            console.log("questionIndex", questionIndex);
            console.log("questionList", questionList);
            console.log("setQuestionList", setQuestionList);
            console.groupEnd();
            return;
        }

        console.log("running effect in image");
        if (questionList.length > 0 && questionIndex !== questionList.length) {
            const currentQuestion = questionList[questionIndex];
            setCurrentImage(currentQuestion.image_link);
            setCurrentImageWord(currentQuestion.word);
            setCurrentImagePhon(currentQuestion.phonetic_transcription);
        } else {
            // TODO: Parse to int and handle error
            const id = parseInt(localStorage.getItem("id_test") ?? "");
            if (Number.isNaN(id)) {
                // TODO: Handle error
                throw new Error("`id_test` is invalid");
            }

            if (questionIndex === questionList.length) {
                return;
            }

            QuestionService.getQuestionListByTestId(id)
                .then((res) => {
                    setQuestionList(res.data);
                    setCurrentImage(res.data[questionIndex].image_link);
                    setCurrentImageWord(res.data[questionIndex].word);
                    setCurrentImagePhon(res.data[questionIndex].phonetic_transcription);
                })
                .catch((error) => console.log(error));
        }
    }, [questionIndex, questionList, setQuestionList]);

    if (!questionList || questionIndex === undefined) {
        return (
            <Grid container alignItems="center">
                <Typography>Error</Typography>
            </Grid>
        );
    }

    return (
        <Grid container alignItems="center">
            {questionList.length > 0 ? (
                <Grid container direction="row" alignItems="center">
                    <CardMedia
                        component="img"
                        src={currentImage}
                        alt={currentImageWord}
                        className={clsx(classes.cardMedia)}
                    />
                    <Grid>
                        <Typography variant="h5" color="" className={classes.text}>
                            {TestText.info.image}
                            {questionIndex + 1} av {questionList.length}
                        </Typography>
                        <Typography variant="h2" style={{ fontWeight: "bold" }} color="textPrimary">
                            {currentImageWord}
                        </Typography>
                        <Typography variant="h2" style={{ fontWeight: "normal" }} color="#165b58">
                            {currentImagePhonetic}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid item>
                    <CircularProgress />
                </Grid>
            )}
        </Grid>
    );
}
