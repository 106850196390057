import { Link } from "react-router-dom";
import ManualCard from "../../containers/ManualCard";
import { HomeText } from "../../text/HomeText";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../theme";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

const useStyles = makeStyles({
    root: (props) => ({
        height: "inherit"
    }),
    contents: (props) => ({
        width: "100%",
        height: "100%"
    }),
    grid: (props) => ({
        backgroundColor: "#b7bbbe",
        minHeight: "300px",
        maxWidth: "80%",
        margin: "auto",
        textAlign: "left",
        padding: DnfTheme.spacing(4),
        borderRadius: 10
    }),
    gridItem: (props) => ({
        margin: DnfTheme.spacing(3),
        marginLeft: DnfTheme.spacing(3)
    }),
    title: (props) => ({
        fontSize: 40,
        marginBottom: "20px"
    }),
    underTitle: (props) => ({
        textTransform: "none",
        fontSize: 20,
        marginBottom: "10px"
    }),
    ingress: (props) => ({
        fontSize: 15
    }),
    text: (props) => ({
        marginLeft: "20px",
        marginBottom: "10px"
    }),
    link: (props) => ({
        textTransform: "none",
        color: "#000000",
        cursor: "pointer"
    })
});

/**
 * Deep user manual.
 * */
export default function Manual() {
    const classes = useStyles();

    //Manual for 'Start test'
    const TestManual = () => {
        return (
            <Grid item className={classes.gridItem}>
                <Link to="/setuptest" className={classes.link}>
                    <Typography variant="h6" className={classes.underTitle}>
                        <KeyboardArrowRightIcon />
                        {HomeText.startTest.underTitle}
                    </Typography>
                </Link>
                <Typography className={classes.text}>{HomeText.startTest.introText}</Typography>
                <ManualCard inputText={HomeText.startTest} />
            </Grid>
        );
    };

    //Manual for 'Analyze'
    const AnalyzeManual = () => {
        return (
            <Grid item className={classes.gridItem}>
                <Link to="/analyze" className={classes.link}>
                    <Typography variant="h6" className={classes.underTitle}>
                        <TimelineOutlinedIcon />
                        {HomeText.analyze.underTitle}
                    </Typography>
                </Link>
                <Typography className={classes.text}>{HomeText.analyze.introText}</Typography>
                <ManualCard inputText={HomeText.analyze} />
            </Grid>
        );
    };

    //Manual for 'Settings'
    const SettingsAnalyze = () => {
        return (
            <Grid item className={classes.gridItem}>
                <Link to="/settings" className={classes.link}>
                    <Typography variant="h6" className={classes.underTitle}>
                        <SettingsOutlinedIcon />
                        {HomeText.settings.underTitle}
                    </Typography>
                </Link>
                <Typography className={classes.text}>{HomeText.settings.introText}</Typography>
                <ManualCard inputText={HomeText.settings} />
            </Grid>
        );
    };

    return (
        <div className={classes.root}>
            <main className={classes.contents}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                    {/* <ImageHeader /> */}
                </Grid>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.grid}>
                    <Grid item className={classes.gridItem}>
                        <Typography variant="h2" className={classes.title}>
                            {HomeText.info.title}
                        </Typography>
                        <Typography className={classes.ingress}>{HomeText.info.ingress}</Typography>
                    </Grid>
                    <TestManual />
                    <AnalyzeManual />
                    <SettingsAnalyze />
                </Grid>
            </main>
        </div>
    );
}
