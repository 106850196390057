import { axiosRules } from "../Shared/Axios";

class RuleService {
    // TODO: Fix return type
    getRuleset = async (token: string) => {
        const rawRuleSet = await axiosRules.get("/", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return rawRuleSet;
    };

    // TODO: Fix return type
    getAllRulesets = async (token: string) => {
        const rulesets = await axiosRules.get("/all", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return rulesets;
    };

    // TODO: Fix return type
    uploadRuleset = async (ruleFile: File, shortDescription: string, token: string) => {
        console.log("Do the upload", ruleFile);
        const formData = new FormData();
        formData.append("ruleFile", ruleFile, ruleFile.name);
        // formData.append("shortDescription", shortDescription);

        const response = await axiosRules.post("/upload", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
        });

        // TODO: Handle response

        return response;
    };

    setActiveRuleset = async (rulesetFileHash: string, token: string) => {
        console.log("setActiveRuleset with token ", token);
        return await axiosRules.post(
            `/${rulesetFileHash}/activate`,
            {}, // Empty payload
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    };

    setDefaultRuleset = async (rulesetFileHash: string, token: string) => {
        return await axiosRules.post(
            `/${rulesetFileHash}/default`,
            {}, // Empty payload
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    };
}

export default new RuleService();
