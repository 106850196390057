import {
    Grid,
    List,
    CircularProgress,
    Skeleton,
    ListItemText,
    ListItemAvatar,
    Avatar,
    ListItemButton
} from "@mui/material";
import { Dispatch, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import TestParticipantService, { TestParticipant } from "../../../Services/TestParticipantService";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    grid: (props) => ({
        marginLeft: 0,
        paddingLeft: 0
    }),
    chooseTp: (props) => ({
        background: "transparent",
        color: "rgba(114,110,110,0.8)",
        "&:focus": {
            backgroundColor: "rgb(36, 150, 145, 0.5)",

            color: "#2d4a46"
        },
        "&:active": {
            backgroundColor: "rgb(36, 150, 145, 0.1)",
            color: "rgb(36, 150, 145, 0.75)"
        },
        "&:hover": {
            color: "rgb(36, 150, 145, 0.75)"
        }
    })
});

type tpProps = {
    setTestParticipant: Dispatch<TestParticipant>;
    setActiveStep: Dispatch<number>;
};
export default function ChooseTP({ setTestParticipant, setActiveStep }: tpProps) {
    const classes = useStyles();
    const [cookies, ,] = useCookies(["jwt", "id_sp"]);
    const [loading, setLoading] = useState(true);
    const [testParticipantList, setTestParticipantList] = useState<Array<TestParticipant>>();
    const [emptyList, setEmptyList] = useState<boolean>(false);
    const [selected, setSelected] = useState<number>();

    useEffect(() => {
        TestParticipantService.getAllTestParticipantsFromSpeechTherapistID(cookies.id_sp, cookies.jwt)
            .then((data) => {
                if (data.data.length > 0) {
                    setTestParticipantList(data.data);
                } else {
                    setEmptyList(true);
                }
            })
            .catch((e) => console.error(e))
            .finally(() => setLoading(false));
    }, [cookies.id_sp, cookies.jwt]);

    if (testParticipantList === undefined) {
        console.error("Test participant list is empty");
        return (
            <Grid item>
                <CircularProgress />
            </Grid>
        );
    }

    const handleListItemSelect = (e, index) => {
        setSelected(index);
    };

    return (
        <>
            {loading || !testParticipantList || emptyList ? (
                <Grid item>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Grid>
            ) : (
                <Grid item>
                    <List
                        sx={{
                            maxWidth: 400,
                            minWidth: 350,
                            overflow: "auto",
                            cursor: "pointer",
                            marginTop: "5%"
                        }}>
                        {testParticipantList.map((participant, index) => (
                            <ListItemButton
                                className={classes.chooseTp}
                                focusVisibleClassName={classes.chooseTp}
                                selected={selected === index}
                                key={participant.id_tp}
                                alignItems="flex-start"
                                onClick={(e) => {
                                    setTestParticipant(participant);
                                    handleListItemSelect(e, index);
                                    setActiveStep(1);
                                }}>
                                <ListItemAvatar key={participant.firstname + participant.lastname}>
                                    <Avatar alt="Remy Sharp" src="https://pbs.twimg.com/media/EqB8qn1UcAIeSFq.jpg" />
                                </ListItemAvatar>
                                <ListItemText
                                    key={participant.firstname}
                                    primary={participant.lastname + " " + participant.firstname}
                                    secondary={
                                        "Fødselsdato: " + new Date(participant.date_of_birth).toLocaleDateString()
                                    }></ListItemText>
                            </ListItemButton>
                        ))}
                    </List>
                </Grid>
            )}
        </>
    );
}
