//@flow
import { QuestionData } from "src/components/Test/Therapist/Context";
import { axiosQuestion as axios } from "../Shared/Axios";

class QuestionService {
    getAllQuestions = async () => {
        return await axios.get("/");
    };

    getQuestionListByTestId = async (test_id: number) => {
        
        return await axios.get<Array<QuestionData>>("/list/" + test_id);
    };

    createQuestionList = async (test_id: number) => {
        return axios.post("/list", { test_id });
    };

    setPredictedValue = async (
        test_id: number,
        question_id: number,
        video_id: string,
        answer: string,
        ort_answer: string,
        correct: number,
        imitation: number
    ) => {
        return axios.put("/list", {
            test_id,
            question_id,
            video_id,
            answer,
            ort_answer,
            correct,
            imitation
        });
    };

    setVideoClip = async (videoClip: string) => {
        return axios.put("/list", { videoClip });
    };

    // const answer = {
    //     test_id: questionList[questionIndex].id_test,
    //     question_id: questionList[questionIndex].id_question,
    //     predicted_value: predictedValue,
    //     is_correct: isCorrect(isCorrectPronunciation),
    //     is_imitation: isImitation(isImitation)
    //     videoBlob
    // };
    submitAnswer = async (
        test_id: number,
        question_id: number,
        answer: string,
        ort_answer,
        correct: boolean,
        imitation: boolean,
        videoBlob: Blob,
        token: string
    ) => {
        console.log("Submitting answer", test_id, question_id, answer,ort_answer, correct, imitation, videoBlob);
        const formData = new FormData();        
        formData.append("test_id", test_id.toString());
        formData.append("question_id", question_id.toString());
        formData.append("answer", answer);
        formData.append("ort_answer", ort_answer);
        formData.append("correct", correct ? "1" : "0");
        formData.append("imitation", imitation ? "1" : "0");
        formData.append("videoBlob", videoBlob);

        const response = await axios.post("/answer", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
        });

        // TODO: Handle response

        return response;
    };

    getAnswer = async (
        test_id: number,
        question_id: number,
        token: string
    ) => {
        console.log(`Retrieving video for ${test_id}-${question_id}.webm`);

        const response = await axios.get("/answer/" + test_id + "/" + question_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return response;
    };

    updateAnswer = async (
        test_id: number,
        question_id: number,
        answer: string,
        ort_answer: string,
        token: string
    ) => {
        console.log("updating answer", test_id, question_id, answer, ort_answer);
        const response = await axios.put("/answer", {
            test_id,
            question_id,
            answer,
            ort_answer,
            token
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        console.log(response);
        
        return response;
    };

}

export default new QuestionService();
