import { axiosTest as axios } from "../Shared/Axios";
import { TestData } from "../model/TestData";

class TestService {
    getAllQuestions = async (token: string) => {
        return await axios.get("/questions", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    getTestInformation = async (id: number, token: string) => {
        return await axios.get("/testInfo/" + id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    getTestsBySpeechTherapistIDandTestParticipantID = async (tp_id: number, sp_id: number, token: string) => {
        return await axios.get<TestData[]>("/analyze/" + sp_id + "/" + tp_id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    createNewTest = async (speechTherapist_id: number, 	participant_id: number, token: string) => {                
        return await axios.post(
            "/",
            { speechTherapist_id, participant_id },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    };

    registerCompletedTest = async (
        speechTherapist_id: number,
        participant_id: number,
        test_id: number, 
        token: string

    ) => {
        return await axios.put("/" + test_id, {
            speechTherapist_id,
            participant_id,
            test_id, 
            token
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }
}

export default new TestService();
