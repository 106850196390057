import { Box, Card, CardMedia, CircularProgress, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../../theme";
import React, { useEffect, useState } from "react";
import QuestionService from "../../../Services/QuestionService";
import { TestText } from "../../../text/TestText";
import { ImageData } from "../../../model/ImageData";

const useStyles = makeStyles({
    root: (props) => ({
        display: "flex",
        height: "inherit"
    }),
    contents: (props) => ({
        flexGrow: 100,
        maxHeight: "900px",
        marginTop: "10px",
        padding: DnfTheme.spacing(3),
        backgroundColor: "#274d6e"
    }),
    box: (props) => ({
        maxWidth: "50%",
        marginTop: DnfTheme.spacing(20),
        margin: "auto",
        padding: DnfTheme.spacing(10),
        borderColor: "#ffffff"
    }),
    button: (props) => ({
        margin: DnfTheme.spacing(1),
        backgroundColor: "#21b6ae"
    }),
    text: (props) => ({
        color: "#ffffff",
        textAlign: "center"
    })
});

/**
 * GUI test participant. Shows images of words to pronounce.
 */
export default function Content() {
    const classes = useStyles();
    const [imageList, setImageList] = useState<ImageData[]>([]);
    const [loading, setLoading] = useState(true);
    const [currImage, setCurrImage] = useState();
    const [currImageText, setCurrImageText] = useState();
    const [idImage, setIdImage] = useState(localStorage.getItem("image"));
    const id_test = parseInt(localStorage.getItem("id_test") ?? "");

    //Fetch imageList or check for updates of image value at localhost.
    useEffect(() => {
        //Time interval for checking for changes in localhost.
        let time = 1000;
        if (idImage !== null) {
            const interval = setInterval(() => {
                if (idImage !== localStorage.getItem("image")) {
                    setIdImage(idImage);
                    window.location.reload();
                }
            }, time);
            if (imageList.length > 0) {
                setCurrImage(imageList[idImage].image_link);
                setCurrImageText(imageList[idImage].word);
            } else {
                QuestionService.getQuestionListByTestId(id_test)
                    .then((res) => {
                        setImageList(res.data);
                        setCurrImage(res.data[idImage].image_link);
                        setCurrImageText(res.data[idImage].word);
                    })
                    .catch((error) => console.log(error))
                    .finally(() => setLoading(false));
            }
            return () => clearInterval(interval);
        }
    }, [imageList, idImage, currImage, id_test]);

    if (idImage !== null) {
        return (
            <div>
                {
                    // eslint-disable-next-line
                    loading ? (
                        <CircularProgress />
                    ) : (
                        <Container>
                            <Grid container justifyContent={"center"}>
                                <Grid item>
                                    <Card className={classes.contents}>
                                        <CardMedia
                                            component="img"
                                            image={currImage}
                                            alt={currImageText}
                                            className="tp-view-image"
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    )
                }
            </div>
        );
    } else {
        return (
            <Card className={classes.contents}>
                <Box className={classes.box} border={4} borderRadius={16}>
                    <Typography variant={"h2"} className={classes.text}>
                        {TestText.endPageParticipant.title}
                    </Typography>
                    <br />
                    <Typography variant={"h4"} className={classes.text}>
                        {TestText.endPageParticipant.text}
                    </Typography>
                </Box>
            </Card>
        );
    }
}
