//React components
import React from "react";
import { useEffect, useState } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";

//Views
import LoginView from "./Views/LoginView";
import TherapistView from "./Views/TherapistView";
import SetUpTestView from "./Views/SetupView";
import AnalyzeView from "./Views/AnalyzeView";
import ParticipantView from "./Views/ParticipantView";
import HomeView from "./Views/HomeView";
import AboutView from "./Views/AboutView";
import ResultsView from "./Views/ResultsView";
//Other
import SpeechTherapistService from "./Services/SpeechTherapistService";
import RuleSettingsView from "./Views/RuleSettingsView";
import { CircularProgress, Grid } from "@mui/material";

export default function App() {
    const [cookie, setCookie, removeCookie] = useCookies(["jwt", "id"]);
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(false);
    const [test, setTest] = useState(true);
    let image = localStorage.getItem("image");
    /**
     * Checks if it exist a token valid token named jwt.
     * If it does the user is authenticated.
     */
    useEffect(() => {
        if (image !== null) {
            setTest(true);
        } else {
            setTest(false);
        }

        if (cookie.jwt) {
            setLoading(true);
            SpeechTherapistService.verifyToken(cookie.jwt, cookie.id)
                .then(({ data }) => {
                    setCookie("jwt", data.token, { path: "/" });
                    setIsAuth(true);
                })
                .catch(() => {
                    setIsAuth(false);
                    removeCookie("jwt", { path: "/" });
                    removeCookie("id", { path: "/" });
                    localStorage.clear();
                })
                .finally(() => setLoading(false));
        } else {
            setIsAuth(false);
        }
    }, [cookie.jwt, cookie.id, removeCookie, setCookie, image]);

    return (
        <CookiesProvider>
            <Router>
                {loading ? (
                    <Grid container>
                        <CircularProgress />
                    </Grid>
                ) : isAuth ? (
                    <Switch>
                        <Route exact path="/home" component={HomeView} />
                        <Route exact path="/test" component={TherapistView} />
                        <Route exact path="/tp" component={ParticipantView} />
                        <Route exact path="/setuptest" component={SetUpTestView} />
                        <Route exact path="/analyze" component={AnalyzeView} />
                        <Route exact path="/results" component={ResultsView} />
                        <Route exact path="/rule-settings" component={RuleSettingsView} />
                        <Route exact path="/about" component={AboutView} />
                        {test ? <Redirect from="/tp" to="/tp" /> : <Redirect to="/home" />}
                    </Switch>
                ) : (
                    <Switch>
                        <Route exact path="/login" component={LoginView} />
                        {test ? <Redirect from="/tp" to="/tp" /> : <Redirect to="/login" />}
                    </Switch>
                )}
            </Router>
        </CookiesProvider>
    );
}
