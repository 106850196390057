export const Vowels = ["A", "E", "I", "O", "U", "Y", "Æ", "Ø", "Å", 
"iː",
"i",
"yː",
"y",
"eː",
"e",
"øː",
"ø",
"œ",
"æː",
"æ",
"ɑː",
"ɑ",
"oː",
"o",
"ɔː",
"ɔ",
"uː",
"u",
"ʉː",
"ʉ",
"ə",
];
export const Consonants = [
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "p","b","t","d","ʈ","ɖ","k","ɡ","f","v","s","ʃ","ç","h","m","n","ɳ","ŋ","l","ɭ","ɾ","j"

];
export const Diphthongs = ["EI", "ØY", "AU", "AI", "OI", "OY", "UI",  "øy",
"æi",
"æʉ",
"ɑi",
"oy",
"ɔy",
"ui",
"ʉi",];
export const Others = [
    "RT",
    "DV",
    "NG",
    "DT",
    "TH",
    "GL",
    "RD",
    "KJ",
    "GJ",
    "RN",
    "SJ",
    "HV",
    "HJ",
    "MJ",
    "LJ",
    "PJ",
    "DJ",
    "RL",
    "DR",
    "SL",
    "PL",
    "KL",
    "BL",
    "TJ",
    "FL",
    "BJ",
    "FJ",
    "BR",
    "TR",
    "FR",
    "GR",
    "KR",
    "PR",
    "PS",
    "KN",
    "KV",
    "TV",
    "SK",
    "SM",
    "SN",
    "SP",
    "ST",
    "SV",
    "NT",
    "KS",
    "LV",
    "VL",
    "SKJ",
    "SCH",
    "KKJ",
    "MST",
    "RST",
    "SKR",
    "STR",
    "SKL",
    "SPL", "pt","ps","ts","ʈʃ","kt","ks","ɡd","mp","mt","mp","md","mf","ms","nt","ns","ɳʈ","ɳʃ","ŋt","ŋk","ŋs","mn","ŋn","ɾp","ɾb","ɾd","ɾk","ɾɡ","ɾf","ɾv","ɾm","ɾj","ft","fs","sp","st","sk","ʃʈ","ʃk","vd","vn","vl","lp","lt","lk","lɡ","lf","ls","lv","lm","ln","lj","psk","tsk","kst","ksk","mpt","mst","msk","nst","nsk","ŋkt","ŋks","ŋst","ɾpt","ɾps","ɾkt","ɾmt","ɾft","fst","skt","ʃkt","vnt","lkt","lmt","lft","lst","lsk"
];