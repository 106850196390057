import styled, { css } from 'styled-components';

const flexColumn = css`
  flex-direction: row;
`;

export const BaseContainer = styled.div`
  ${flexColumn};
  align-items: center;
  padding-top: 40px;
  padding-right: 16px;
  padding-bottom: 120px;
`;

export const ContentContainer = styled.main`
  ${flexColumn};
  flex: auto;
`;
