import React, { Dispatch, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import QuestionService from "../../../Services/QuestionService";
import { MyContext } from "./Context";
import { TestText } from "../../../text/TestText";
import DialogContainer from "../../../containers/CustomDialog";
import { Grid, Button, Backdrop, CircularProgress, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../../theme";
import { ImageData } from "../../../model/ImageData";
import CheckIcon from "@mui/icons-material/Check";

const useStyles = makeStyles({
    button: () => ({
        width: "150px",
        marginBottom: "5px",
        marginTop: "5px",
        paddingLeft: DnfTheme.spacing(2),
        paddingRight: DnfTheme.spacing(2),

        backgroundColor: "#0B5345",
        color: "#ffffff",
        borderRadius: 18,

        fontSize: "30px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#315085",
            color: "#FFF"
        }
    }),

    correct: () => ({
        width: "150px",
        marginBottom: "5px",
        marginTop: "5px",
        paddingLeft: DnfTheme.spacing(2),
        paddingRight: DnfTheme.spacing(2),

        backgroundColor: "green",
        color: "#FFF",
        borderRadius: 18,
        fontSize: "30px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#73C6B6",
            color: "#FFF"
        }
    }),
    imitation: () => ({
        width: "200px",
        marginBottom: "5px",
        marginTop: "5px",
        paddingLeft: DnfTheme.spacing(4),
        paddingRight: DnfTheme.spacing(4),
        backgroundColor: "#16A085",
        color: "#FFF",
        borderRadius: 18,
        fontSize: "30px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#73C6B6",
            color: "#FFF"
        }
    }),
    previous: () => ({
        width: "150px",
        marginBottom: "5px",
        marginTop: "5px",
        paddingLeft: DnfTheme.spacing(2),
        paddingRight: DnfTheme.spacing(2),

        backgroundColor: "#D5DBDB",
        color: "#424949",
        borderRadius: 18,
        fontSize: "30px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#424949",
            color: "#FFF"
        }
    }),

    repeat: () => ({
        width: "150px",
        marginBottom: "5px",
        marginTop: "5px",
        paddingLeft: DnfTheme.spacing(2),
        paddingRight: DnfTheme.spacing(2),

        backgroundColor: " #7F8C8D",
        color: "#424949",
        borderRadius: 18,
        fontSize: "30px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#424949",
            color: "#FFF"
        }
    }),

    cancel: () => ({
        width: "150px",
        marginBottom: "5px",
        marginTop: "5px",
        paddingLeft: DnfTheme.spacing(2),
        paddingRight: DnfTheme.spacing(2),
        color: "#FFF",
        backgroundColor: "#cd1f1f",
        borderRadius: 18,
        fontSize: "30px",
        textTransform: "none",

        "&:hover": {
            backgroundColor: "#8a0f0f",
            color: "#FFF"
        }
    }),
    backdrop: () => ({
        zIndex: DnfTheme.zIndex.drawer + 1,
        color: "#fff"
    })
});

type ControlBarProps = {
    correct: boolean;
    finished: boolean;
    onNext: Dispatch<boolean>;
    onCorrect: Dispatch<boolean>;
    onImitation: () => void;
    setFinished: Dispatch<boolean>;
    setActiveStep: Dispatch<number>;
};

export default function ControlBar(
    { correct, onNext, onCorrect, onImitation, setFinished, setActiveStep, finished }: ControlBarProps,
    param
) {
    const classes = useStyles();
    const history = useHistory();
    const id_test = parseInt(localStorage.getItem("id_test") ?? "");

    const { clipValue, clipListValue, questionIndexValue, nextValue } = useContext(MyContext);

    const [openFinishDialog, setOpenFinishDialog] = useState(false);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [imageList, setImageList] = useState<ImageData[]>([]);
    const [lastImage, setLastImage] = useState(false);
    const [imitationClicked, setImitation] = useState<boolean>(false);

    //Fetch question list and update image number from localstorage.
    useEffect(() => {
        if (!questionIndexValue) return;
        const [img] = questionIndexValue;
        if (imageList.length > 0) {
            localStorage.setItem("image", img.toString());
            if (imageList.length === img + 1) setLastImage(true);
        } else {
            QuestionService.getQuestionListByTestId(id_test)
                .then((res) => setImageList(res.data))
                .catch((error) => console.log(error));
        }
    }, [id_test, imageList.length, questionIndexValue, onCorrect]);

    if (!questionIndexValue || !clipListValue || !nextValue || !clipValue) {
        throw new Error("Something is not set");
    }

    const [image, setImage] = questionIndexValue;
    //shows the next image if a sound value is transcribed.
    const handleNextImage = useCallback(() => {
        onNext(true);
        setImitation(false);
        if (imageList.length > image) {
            setImage(image + 1);
        }
    }, [imageList.length, image, onNext, setImage]);

    const handleCorrectPronounciation = useCallback(() => {
        onCorrect(true);
        handleNextImage();
    }, [onCorrect, handleNextImage]);

    const handleImitation = () => {
        onImitation();
        setImitation(true);
    };

    //shows the previous image if a sound value is transcribed.
    const handlePreviousImage = () => {
        onCorrect(true);
        setImitation(false);
        if (image > 0) {
            setImage(image - 1);
            if (lastImage) setLastImage(false);
        }
    };
    //handles when the last image is shown.
    const handleLastImage = () => {
        setImage(103);
        setOpenFinishDialog(true);
    };

    //Handling closing finish test dialog
    const handleCloseFinishDialog = (val) => {
        setOpenFinishDialog(false);
        if (val) {
            setOpenBackdrop(false);
            setFinished(true);
            setActiveStep(4);
        }
    };
    //Handling closing cancel test dialog
    const handleCloseCancelDialog = (val) => {
        setOpenCancelDialog(false);
        if (val) {
            onNext(true);
            setOpenBackdrop(false);
            setFinished(true);
            localStorage.removeItem("image");
            localStorage.removeItem("analyze");
            localStorage.removeItem("id_tp");
            localStorage.removeItem("id_test");
        }
        history.push("/home");
    };

    return (
        <div>
            <Grid container>
                <Grid item container justifyContent={"center"} alignItems="center" direction="column" width="33%">
                    <Grid item>
                        <Button className={classes.previous} onClick={handlePreviousImage}>
                            {TestText.buttons.previous}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button disabled variant="contained" className={classes.repeat}>
                            {TestText.buttons.repeat}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container justifyContent={"center"} alignItems="center" direction="column" width="33%">
                    <Grid item>
                        <Box sx={{ m: 1, position: "relative" }}>
                            <Button variant="contained" className={classes.imitation} onClick={handleImitation}>
                                {TestText.buttons.imitation}
                                {imitationClicked && <CheckIcon />}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                <Grid item container justifyContent={"center"} alignItems="center" direction="column" width="33%">
                    <Grid item>
                        {lastImage ? (
                            <>
                                <Grid item>
                                    <Button
                                        disabled={!correct}
                                        variant="contained"
                                        className={classes.correct}
                                        onClick={handleLastImage}>
                                        {TestText.buttons.correct}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" className={classes.button} onClick={handleLastImage}>
                                        {TestText.buttons.stop}
                                    </Button>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item>
                                    <Button
                                        disabled={!correct}
                                        variant="contained"
                                        className={classes.correct}
                                        onClick={handleCorrectPronounciation}>
                                        {TestText.buttons.correct}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        onClick={() => handleNextImage()}>
                                        {TestText.buttons.next}
                                    </Button>
                                </Grid>
                                <Grid container item justifyContent={"left"}>
                                    <Button className={classes.cancel} onClick={() => setOpenCancelDialog(true)}>
                                        {TestText.buttons.stop}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <DialogContainer
                isOpen={openFinishDialog}
                handleClose={handleCloseFinishDialog}
                title={TestText.finishTestDialog.title}
                text={TestText.finishTestDialog.text}
                buttonTextList={[TestText.finishTestDialog.goBack, TestText.finishTestDialog.finish]}
                extraFunc={onNext}
            />
            <DialogContainer
                isOpen={openCancelDialog}
                handleClose={handleCloseCancelDialog}
                title={TestText.cancelTestDialog.title}
                text={TestText.cancelTestDialog.text}
                buttonTextList={[TestText.cancelTestDialog.goBack, TestText.cancelTestDialog.cancel]}
                extraFunc={onNext}
            />
            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
