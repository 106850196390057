import React, { useState } from "react";
import Login from "./Login";
import NewPassword from "./NewPassword";
import Register from "./Register";
import { LoginText } from "../../text/LoginText";
import Image from "../../Shared/Images/DNF.png";
import { Alert, AlertTitle, Avatar, CssBaseline, Grid, Snackbar, SnackbarCloseReason } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../theme";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const useStyles = makeStyles({
    root: (props) => ({
        height: "100vh"
    }),
    paper: () => ({
        margin: DnfTheme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }),
    image: (props) => ({
        backgroundImage: `url(${Image})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: DnfTheme.palette.mode === "light" ? DnfTheme.palette.grey[50] : DnfTheme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center"
    }),
    grid: (props) => ({
        backgroundColor: "#0c1a2d"
    }),
    alert: (props) => ({
        paddingBottom: DnfTheme.spacing(3),
        fontSize: "1.3em",
        height: "100px",
        width: "400px"
    }),
    avatar: (props) => ({
        margin: DnfTheme.spacing(1),
        backgroundColor: DnfTheme.palette.secondary.main
    })
});

/**
 * Main file Login directory. Renders Login.js, Register.js and NewPassword.js.
 * */
export default function Content() {
    const classes = useStyles();
    const [login, setLogin] = useState(true);
    const [success, setSuccess] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);

    //Close the pop up feedback.
    const handleClose = (event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason): void => {
        if (reason === "clickaway") {
            return;
        }
        setSuccess(false);
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} className={classes.grid}>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    {login ? (
                        <Login setLogin={setLogin} setResetPassword={setResetPassword} />
                    ) : resetPassword ? (
                        <NewPassword setLogin={setLogin} />
                    ) : (
                        <Register setLogin={setLogin} setSuccess={setSuccess} />
                    )}
                </div>
                <Snackbar open={success} autoHideDuration={4000} onClose={handleClose}>
                    <Alert
                        className={classes.alert}
                        // TODO: onClose={handleClose}
                        severity={"success"}>
                        <AlertTitle>{LoginText.alert.success}</AlertTitle>
                        {LoginText.feedback.registrationOK}
                    </Alert>
                </Snackbar>
            </Grid>
        </Grid>
    );
}
