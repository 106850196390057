/**
 * Text for the login component
 */
const info = {
    loginTitle: "Logg inn",
    registerTitle: "Registrer ny bruker",
    newPasswordTitle: "Nytt passord"
};

const feedback = {
    emptyTextFields: "Alle felt må fylles ut!",
    unrecognizedCredentials: "Passord og/eller epost stemmer ikke!",
    shortPassword: "Passordet må være på minst 8 tegn!",
    notMatchingPasswords: "Passordet må gjentas to ganger!",
    userExist: "Bruker eksisterer allerede i systemet!",
    invalidEmail: "Epost må være gyldig!",
    registrationOK: "Ny bruker ble opprettet"
};

const alert = {
    success: "Suksess!",
    warning: "Advarsel!"
};

const links = {
    login: "Returner til logg inn",
    newPassword: "Glemt passord?",
    register: "Opprett ny bruker"
};

const buttons = {
    login: "Logg inn",
    register: "Registrer",
    newPassword: "Sett nytt passord"
};

const textField = {
    email: "Epost",
    firstname: "Fornavn",
    lastname: "Etternavn",
    password: ["Passord", "Skriv inn passord. Minst 8 tegn", "Gjenta passord"]
};

export const LoginText = {
    info,
    feedback,
    alert,
    links,
    buttons,
    textField
};
