import { Rule } from "../../model/Rule";
import { Token } from "../../model/Token";
import { Vowels, Consonants, Diphthongs, Others } from "../../model/Letter";
import { VowelChecks } from "../norwegian/VowelChecks";
import { Checks } from "./Checks";

type TranscriptionNode = {
    transcription: string;
    token: Token;
    pos: number
};

export type ListEntry = {
    previous: ListEntry | undefined;
    current: TranscriptionNode;
    next: ListEntry | undefined;   
};

export class Word {
    dataListHead: ListEntry;
    transcription: string[];
    phonetic: string[] | undefined;
    ortographic: string[] | undefined;
    ruleSet: Array<Rule>;
    field: number | undefined;
;
    constructor(ruleSet: Array<Rule>, transcription: string[], field?: number, phonetic?: string[], orthographic?: string[]) {
        this.transcription = transcription;
        this.phonetic = phonetic;
        this.ortographic = orthographic;
        this.ruleSet = ruleSet;
        this.field = field;

        // Create linked list of chars and corresponding token
        let first: ListEntry | undefined = undefined;
        let previous: ListEntry | undefined = undefined;
        let counter: number = 0;
        const wordParts = this.transcription;        
        for (const wp of wordParts) {
            const token = this.parseTokens(wp);
            let newEntry: ListEntry = {
                current: {
                    transcription: wp,
                    token: token,
                    pos: counter +=1
                },
                next: undefined,
                previous
            };

            if (!first) {
                first = newEntry;
            }

            if (previous) {
                previous.next = newEntry;
            }

            previous = newEntry;
        }

        if (previous === undefined || first === undefined) {
            throw new Error("Unexpected input");
        }

        // Add end of word token
        previous.next = {
            current: {
                transcription: "",
                token: "WordEnd",
                pos: -1
            },
            next: undefined,
            previous
        };

        this.dataListHead = first;
    }
    
    private parseTokens(part: string): Token {
        if (part.length === 1) {
            if (this.isVowel(part)) return "Vowel";
            else if (this.isConsonant(part)) return "Consonant";
            else if (this.isDiphthong(part)) return "Diphthong";
        } else if (part.length === 2) {
            if (this.isConsonant(part[0]) && part[0] === part[1]) return "DoubleConsonant";
            else if (this.isConsonant(part[0]) && this.isConsonant(part[1])) return "Others";
            else if (this.isVowel(part[0]) && this.isVowel(part[1])) return "Diphthong";
            else if (this.isOther(part)) return "Others";
        } else if (part.length >= 3) {
            if (this.isOther(part)) return "Others";
        } else {
            return "Others";
        }
        
    }

    private checkForRuleMatch(current: ListEntry, rule: Rule): boolean {
        if (current.current.transcription !== rule.current) {
            console.warn("checkForRuleMatch impossible");
            return false;
        }

        let nextIterator = current.next;

        if (!nextIterator) {
            throw Error("No list to iterate over.");
        }

        for (let idx = 0; idx < rule.expectedNext.length; idx++) {
            if (
                rule.expectedNext[idx] === "DoubleConsonant" &&
                nextIterator.current.token === "Consonant" &&
                nextIterator.next?.current.token === "Consonant"
            ) {
                // Double consonant 'tt' also matches 't-t'
                return true;
            } 

            if (nextIterator.current.token !== rule.expectedNext[idx]) {
                return false;
            }
        }

        return true;
    }
     
   public transcribe(): string[] {
        let tempOrthographic = this.ortographic;
        let tempPhonetic = this.phonetic;
        let mappedWord = ["", "", "", "", "", "","", "", "", ""];
        let current = this.dataListHead;
        let vc = new VowelChecks();
        let check = new Checks();
        
        if(!tempOrthographic) {
            throw Error("no orthograpic to transcribe");
        }        

        if (!tempPhonetic) {
            throw Error("no phonetic to transcribe");
        }

        tempPhonetic.forEach((e, i) => {
            mappedWord[i] = e;
        });

        console.log(mappedWord,current);

        const setPhoneticSymbol = (pos: number, s: string): string[]  => {   
            mappedWord.splice(pos, 1, s);     
            return mappedWord;
        }

        //Finn alle reglene som anngår current bokstav
        const ruleMatchtranscription = (r: Rule): boolean => {
            return r.current === current.current.transcription;
        };

        //Finn gjeldende regel
        const matchRule = (r: Rule): boolean => {
            return this.checkForRuleMatch(current, r);
        };        
        
        while (current !== undefined && current.current.token !== "WordEnd" && current.current.transcription !== "") {     
            if (current.current.transcription !== tempOrthographic[current.current.pos-1] && !undefined && current.current.transcription !== "∅") {
                const rules = this.ruleSet.filter(ruleMatchtranscription);                
                let earliestMatchedRule = rules.find(matchRule); 

                if (!earliestMatchedRule) {
                    console.log('inn her ',mappedWord);
                    const recursive = check.recursiveMapping(this.ruleSet, current.current.transcription);                    
                    setPhoneticSymbol(current.current.pos-1, recursive);

                    if (!current.next) {
                        throw new Error("Word ended but never reached WordEnd token");
        
                    }
                    current = current.next;

                } else {
                    //Sett opprinnelig verdi
                    setPhoneticSymbol(current.current.pos-1, earliestMatchedRule.resultsIn);                
                    const correctLetter = check.setTranscribedValue(current);
                    
                    if(correctLetter.string !== "") {  
                        setPhoneticSymbol(correctLetter.position, correctLetter.string );
                    }
                    
                    if(current.current.token === "Vowel" || current.previous?.current.token === "Vowel" || current.current.token === "Diphthong" || current.previous?.current.token === "Diphthong") {
                        const vowel = vc.setVowelLength(current, mappedWord);                
                        setPhoneticSymbol(vowel.position, vowel.string);
                    }
                }
            } else {
                if(current.current.transcription === "∅") {
                    setPhoneticSymbol(current.current.pos-1, current.current.transcription)
                } else {
                    setPhoneticSymbol(current.current.pos-1, tempPhonetic[current.current.pos-1]);
                }

            }
            
            if (!current.next) {
                throw new Error("Word ended but never reached WordEnd token");

            }
            current = current.next;

        }
        
        return mappedWord;
    }

    // TODO: private
    isConsonant(s: string): boolean | string {        
        if (s.length !== 1) {
            return "";
        }
        return Consonants.indexOf(s) > -1 || Consonants.indexOf(s.toUpperCase()) > -1 ;
    }

    // TODO: private
    isVowel(s: string): boolean | string{
        if (s.length !== 1) {
            return "";
        }
        return Vowels.indexOf(s) > -1 || Vowels.indexOf(s.toUpperCase()) > -1;
    }

    // TODO: private
    isDiphthong(s: string): boolean | string {
        if (s.length !== 2) {
           return "";
        }
        return Diphthongs.indexOf(s||s.toUpperCase()) > -1 || Diphthongs.indexOf(s.toUpperCase()) > -1;
    }

    // TODO: private
    isOther(s: string): boolean | string {
        if (s.length > 3) {
            return "";
        }
        return Others.indexOf(s||s.toUpperCase()) > -1 || Others.indexOf(s.toUpperCase()) > -1;
    }


}
