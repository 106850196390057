import React, { useState } from "react";
import { Button, TableCell, TableRow } from "@mui/material";
import { Scoring } from "../../model/PhoneticProcess";
import AnalyzeModal from "src/modals/AnalyzeModal/AnalyzeModal";
import { makeStyles } from "@mui/styles";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import clsx from "clsx";
import { TestParticipantInfo } from "../Test/Therapist/Context";

const useStyles = makeStyles({
    textW: () => ({
        color: "#000",
        backgroundColor: "#bd000026"
    }),
    cellWidth: () => ({
        width: "190px"
    }),
    textR: () => ({
        color: "#000",
        backgroundColor: "rgba(50,167,102,0.15)"
    })
});

type PositionProps = {
    table: Scoring;
    testInfo?: TestParticipantInfo;
};

export default function ProcessTables({ table, testInfo }: PositionProps) {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [id, setId] = useState<number>(0);

    return (
        <>
            <TableRow sx={{ height: "10px", border: "none " }}>
                <TableCell>{table.targetWord.word}</TableCell>
                <TableCell>{table.targetWord.expected}</TableCell>
            </TableRow>
            <TableRow sx={{ height: "10px" }}>
                <TableCell
                    className={
                        table.score === 0
                            ? clsx(classes.cellWidth, classes.textR)
                            : clsx(classes.cellWidth, classes.textW)
                    }>
                    {table.targetWord.predictedOrt}
                </TableCell>
                <TableCell
                    className={
                        table.score === 0
                            ? clsx(classes.cellWidth, classes.textR)
                            : clsx(classes.cellWidth, classes.textW)
                    }>
                    {table.targetWord.predicted}
                </TableCell>
                <TableCell>
                    <Button
                        onClick={() => {
                            setId(table.number);
                            setOpen(true);
                        }}>
                        <PlayCircleFilledWhiteIcon color="action" />
                    </Button>
                </TableCell>
            </TableRow>

            <AnalyzeModal open={open} setOpen={setOpen} id={id} testInfo={testInfo} />
        </>
    );
}
