import { useState } from "react";
import SpeechTherapistService from "../../Services/SpeechTherapistService";
import { LoginText } from "../../text/LoginText";
import {
    Alert,
    AlertTitle,
    Button,
    Divider,
    Grid,
    Link,
    Snackbar,
    SnackbarCloseReason,
    TextField,
    Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../theme";

const useStyles = makeStyles({
    root: (props) => ({
        height: "100vh"
    }),
    typography: (props) => ({
        fontFamily: DnfTheme.typography.fontFamily,
        color: "#fff",
        textAlign: "center",
        fontSize: 40
    }),
    avatar: (props) => ({
        margin: DnfTheme.spacing(1),
        backgroundColor: DnfTheme.palette.secondary.main
    }),
    form: (props) => ({
        width: "100%",
        marginTop: DnfTheme.spacing(1)
    }),
    cssLabel: (props) => ({
        color: "#fff"
    }),
    notchedOutline: (props) => ({
        borderWidth: "1px",
        borderColor: "white !important"
    }),
    cssOutlinedInput: (props) => ({
        color: "#fff",
        "&$cssFocused $notchedOutline": {
            borderColor: "#fff",
            color: "#fff"
        }
    }),
    cssFocused: (props) => ({
        color: "#fff"
    }),
    divider: (props) => ({
        alignment: "center",
        color: "#000",
        length: 5
    }),
    submit: (props) => ({
        margin: DnfTheme.spacing(3, 0, 2)
    }),
    grid: (props) => ({
        backgroundColor: "#0c1a2d"
    }),
    alert: (props) => ({
        paddingBottom: DnfTheme.spacing(3),
        fontSize: "1.3em",
        height: "100px",
        width: "400px"
    }),
    link: (props) => ({
        color: "#ffffff",
        cursor: "pointer"
    })
});

export default function Register(params: { setLogin: (arg0: boolean) => void; setSuccess: (arg0: boolean) => void }) {
    const classes = useStyles();

    //Credentials
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatedPassword, setRepeatedPassword] = useState("");

    //Snackbar for feedback
    const [open, setOpen] = useState(false);
    const [feedback, setFeedback] = useState("");

    //Close the pop up feedback.
    const handleClose = (event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    //Validate input data for the textFields.
    const validateInput = () => {
        let status = false;
        if (firstname === "" || lastname === "" || email === "" || password === "" || repeatedPassword === "") {
            setFeedback(LoginText.feedback.emptyTextFields);
            setOpen(true);
        } else if (password.length < 8) {
            setFeedback(LoginText.feedback.shortPassword);
            setOpen(true);
        } else if (password !== repeatedPassword) {
            setFeedback(LoginText.feedback.notMatchingPasswords);
            setOpen(true);
        } else {
            status = true;
        }
        return status;
    };

    //Checks if email is already registered in th db.
    const checkIfUserAlreadyExist = async (): Promise<boolean> => {
        try {
            const { data } = await SpeechTherapistService.getUserByeEmail(email);
            if (data) {
                setFeedback(LoginText.feedback.userExist);
                setOpen(true);
                return true;
            } else {
                return false;
            }
        } catch (err) {
            console.error(err);
            return false;
        }
    };

    //Register new user.
    const registerUser = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        let correctInput = validateInput();
        if (correctInput) {
            let userAlreadyExist = await checkIfUserAlreadyExist();
            if (!userAlreadyExist) {
                SpeechTherapistService.register(email, firstname, lastname, password)
                    .then(() => {
                        params.setLogin(true);
                        params.setSuccess(true);
                    })
                    .catch(() => {
                        setFeedback(LoginText.feedback.invalidEmail);
                        setOpen(true);
                    });
            }
        }
    };

    return (
        <div>
            <Typography component="h1" variant="h5" className={classes.typography}>
                {LoginText.info.registerTitle}
            </Typography>
            <br />
            <Divider className={classes.divider} />
            <br />
            <form className={classes.form}>
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    label={LoginText.textField.email}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                        }
                    }}
                    InputProps={{
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused
                        }
                    }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    label={LoginText.textField.firstname}
                    name="text"
                    autoComplete="name"
                    autoFocus
                    InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                        }
                    }}
                    InputProps={{
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused
                        }
                    }}
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                />
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    label={LoginText.textField.lastname}
                    name="text"
                    autoComplete="name"
                    autoFocus
                    InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                        }
                    }}
                    InputProps={{
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused
                        }
                    }}
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={LoginText.textField.password[1]}
                    type="password"
                    autoComplete="current-password"
                    InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                        }
                    }}
                    InputProps={{
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused
                        }
                    }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={LoginText.textField.password[2]}
                    type="password"
                    autoComplete="current-password"
                    InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                        }
                    }}
                    InputProps={{
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused
                        }
                    }}
                    value={repeatedPassword}
                    onChange={(e) => setRepeatedPassword(e.target.value)}
                />
                <div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={registerUser}>
                        {LoginText.buttons.register}
                    </Button>
                </div>
                <Grid container>
                    <Grid item>
                        <Link className={classes.link} onClick={() => params.setLogin(true)}>
                            <Typography>{LoginText.links.login}</Typography>
                        </Link>
                    </Grid>
                </Grid>
                <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                    <Alert
                        className={classes.alert}
                        // TODO onClose={handleClose}
                        severity={"warning"}>
                        <AlertTitle>{LoginText.alert.warning}</AlertTitle>
                        {feedback}
                    </Alert>
                </Snackbar>
                );
            </form>
        </div>
    );
}
