import { Grid, Typography } from "@mui/material";

type InformationProps = {
    testNumber: string | null;
    birthDate: string | undefined;
    participantName: string | undefined;
    motherTongue: string | undefined;
    otherLanguages: string | undefined;
    parent: string | undefined;
};

export default function InformationBox({
    testNumber,
    birthDate,
    participantName,
    motherTongue,
    otherLanguages,
    parent
}: InformationProps) {
    return (
        <Grid item sx={{ marginTop: "10px", color: "#165b58" }}>
            <Typography style={{ fontSize: "20px", padding: "5px" }}>
                Testnummer: <b>{testNumber}</b>
            </Typography>
            <Typography style={{ fontSize: "20px", padding: "5px" }}>
                Navn: <b>{participantName}</b>
            </Typography>
            <Typography style={{ fontSize: "20px", padding: "5px" }}>
                Fødselsdato: <b>{birthDate}</b>
            </Typography>
            <Typography style={{ fontSize: "20px", padding: "5px" }}>
                Morsmål: <b>{motherTongue}</b>
            </Typography>
            <Typography style={{ fontSize: "20px", padding: "5px" }}>
                Andre språk: <b>{otherLanguages}</b>
            </Typography>
            <Typography style={{ fontSize: "20px", padding: "5px" }}>
                Navn foresatt: <b>{parent}</b>
            </Typography>
        </Grid>
    );
}
