import "../../css/ipa_chart_consonants.css";
import { Grid, Typography } from "@mui/material";

type ConsonantProps = {
    setIPAValue: (i: string) => void;
};

export default function Consonants({ setIPAValue }: ConsonantProps) {
    return (
        <Grid container>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td className="cpmetaL">
                                <div className="colhead">&nbsp;</div>
                            </td>
                            <td colSpan={2} className="cpmeta_colL">
                                <div className="colhead">
                                    <Typography>Bilab.</Typography>
                                </div>
                            </td>
                            <td colSpan={2} className="cpmeta_colL">
                                <div className="colhead">
                                    <Typography> Lab-dent.</Typography>
                                </div>
                            </td>
                            <td colSpan={2} className="cpmeta_colL">
                                <div className="colhead">
                                    <Typography>Dent.</Typography>
                                </div>
                            </td>
                            <td colSpan={2} className="cpmeta_colL">
                                <div className="colhead">
                                    <Typography>Alv.</Typography>
                                </div>
                            </td>
                            <td colSpan={2} className="cpmeta_colL">
                                <div className="colhead">
                                    <Typography>Postalv.</Typography>
                                </div>
                            </td>
                            <td colSpan={2} className="cpmeta_colL">
                                <div className="colhead">
                                    <Typography>Retrofl.</Typography>
                                </div>
                            </td>
                            <td colSpan={2} className="cpmeta_colL">
                                <div className="colhead">
                                    <Typography>Pal.</Typography>
                                </div>
                            </td>
                            <td colSpan={2} className="cpmeta_colL">
                                <div className="colhead">
                                    <Typography>Vel.</Typography>
                                </div>
                            </td>
                            <td colSpan={2} className="cpmeta_colL">
                                <div className="colhead">
                                    <Typography>Uvul.</Typography>
                                </div>
                            </td>
                            <td colSpan={2} className="cpmeta_colL">
                                <div className="colhead">
                                    <Typography>Far.</Typography>
                                </div>
                            </td>
                            <td colSpan={2} className="cpmetaR">
                                <div className="colhead">
                                    <Typography>Glot.</Typography>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="cpmeta_row">
                                <div className="rowhead">
                                    <Typography>Plosiv</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="one" onClick={() => setIPAValue("p")}>
                                <div className="symS">
                                    <Typography>p</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="two" onClick={() => setIPAValue("b")}>
                                <div className="symS">
                                    <Typography>b</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvdnone">&nbsp;</td>
                            <td
                                className="cpvlessnone"
                                id="three"
                                onClick={(event) => {
                                    console.log(event);

                                    setIPAValue("t");
                                }}>
                                <div className="symS">
                                    <Typography>t</Typography>
                                </div>
                            </td>
                            <td className="cpvdnone" id="four" onClick={() => setIPAValue("d")}>
                                <div className="symS">
                                    <Typography>d</Typography>
                                </div>
                            </td>
                            <td className="cpvlessnone">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless" id="five" onClick={() => setIPAValue("ʈ")}>
                                <div className="symS">
                                    <Typography>ʈ</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="six" onClick={() => setIPAValue("ɖ")}>
                                <div className="symS">
                                    <Typography>ɖ</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="seven" onClick={() => setIPAValue("c")}>
                                <div className="symS">
                                    <Typography>c</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="eigth" onClick={() => setIPAValue("ɟ")}>
                                <div className="symS">
                                    <Typography>ɟ</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="nine" onClick={() => setIPAValue("k")}>
                                <div className="symS">
                                    <Typography>k</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="ten" onClick={() => setIPAValue("ɡ")}>
                                <div className="symS">
                                    <Typography>ɡ</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="elleven" onClick={() => setIPAValue("q")}>
                                <div className="symS">
                                    <Typography>q</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="twelve" onClick={() => setIPAValue("ɢ")}>
                                <div className="symS">
                                    <Typography>ɢ</Typography>
                                </div>
                            </td>
                            <td className="cpall">&nbsp;</td>
                            <td className="cpallblank">&nbsp;</td>
                            <td className="cpall" id="thirteen" onClick={() => setIPAValue("ʔ")}>
                                <div className="symS">
                                    <Typography>ʔ</Typography>
                                </div>
                            </td>
                            <td className="cpallblank">&nbsp;</td>
                        </tr>
                        <tr>
                            <td className="cpmeta_row">
                                <div className="rowhead">
                                    <Typography>Nasal</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="fourteen" onClick={() => setIPAValue("m")}>
                                <div className="symS">
                                    <Typography>m</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="fifthteen" onClick={() => setIPAValue("ɱ")}>
                                <div className="symS">
                                    <Typography>ɱ</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvdnone">&nbsp;</td>
                            <td className="cpvlessnone">&nbsp;</td>

                            <td className="cpvdnone" id="sixteen" onClick={() => setIPAValue("n")}>
                                <div className="symS">
                                    <Typography>n</Typography>
                                </div>
                            </td>
                            <td className="cpvlessnone">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="seventeen" onClick={() => setIPAValue("ɳ")}>
                                <div className="symS">
                                    <Typography>ɳ</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="eightteen" onClick={() => setIPAValue("ɲ")}>
                                <div className="symS">
                                    <Typography>ɲ</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="nineteen" onClick={() => setIPAValue("ŋ")}>
                                <div className="symS">
                                    <Typography>ŋ</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="twenty" onClick={() => setIPAValue("ɴ")}>
                                <div className="symS">
                                    <Typography>ɴ</Typography>
                                </div>
                            </td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                        </tr>
                        <tr>
                            <td className="cpmeta_row">
                                <div className="rowhead">
                                    <Typography>Vibrant</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="twentyone" onClick={() => setIPAValue("ʙ")}>
                                <div className="symS">
                                    <Typography>ʙ</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvdnone">&nbsp;</td>
                            <td className="cpvlessnone">&nbsp;</td>
                            <td className="cpvdnone" id="twentytwo" onClick={() => setIPAValue("r")}>
                                <div className="symS">
                                    <Typography>r</Typography>
                                </div>
                            </td>
                            <td className="cpvlessnone">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="twentythree" onClick={() => setIPAValue("ʀ")}>
                                <div className="symS">
                                    <Typography>ʀ</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                        </tr>
                        <tr>
                            <td className="cpmeta_row">
                                <div className="rowhead">
                                    <Typography>Tapp Flapp</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="twentyfour" onClick={() => setIPAValue("ⱱ")}>
                                <div className="symS">
                                    <Typography>ⱱ</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvdnone">&nbsp;</td>
                            <td className="cpvlessnone">&nbsp;</td>
                            <td className="cpvdnone" id="twentyfive" onClick={() => setIPAValue("ɾ")}>
                                <div className="symS">
                                    <Typography>ɾ</Typography>
                                </div>
                            </td>
                            <td className="cpvlessnone">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="twentysix" onClick={() => setIPAValue("ɽ")}>
                                <div className="symS">
                                    <Typography>ɽ</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                        </tr>
                        <tr>
                            <td className="cpmeta_row">
                                <div className="rowhead">
                                    <Typography>Frikativ</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="twentyseven" onClick={() => setIPAValue("ɸ")}>
                                <div className="symS">
                                    <Typography>ɸ</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="twentyeight" onClick={() => setIPAValue("β")}>
                                <div className="symS">
                                    <Typography>β</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="twentynine" onClick={() => setIPAValue("f")}>
                                <div className="symS">
                                    <Typography>f</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="thirty" onClick={() => setIPAValue("v")}>
                                <div className="symS">
                                    <Typography>v</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="thirtyone" onClick={() => setIPAValue("θ")}>
                                <div className="symS">
                                    <Typography>θ</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="thirtytwo" onClick={() => setIPAValue("ð")}>
                                <div className="symS">
                                    <Typography>ð</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="thirtythree" onClick={() => setIPAValue("s")}>
                                <div className="symS">
                                    <Typography>s</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="thirtyfour" onClick={() => setIPAValue("z")}>
                                <div className="symS">
                                    <Typography>z</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="thirtyfive" onClick={() => setIPAValue("⎰")}>
                                <div className="symS">
                                    <Typography>⎰</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="thirtysix" onClick={() => setIPAValue("ʒ")}>
                                <div className="symS">
                                    <Typography>ʒ</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="thirtyseven" onClick={() => setIPAValue("ʂ")}>
                                <div className="symS">
                                    <Typography>ʂ</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="thirtyeight" onClick={() => setIPAValue("ʐ")}>
                                <div className="symS">
                                    <Typography>ʐ</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="thirtynine" onClick={() => setIPAValue("ç")}>
                                <div className="symS">
                                    <Typography>ç</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="fourty" onClick={() => setIPAValue("ʝ")}>
                                <div className="symS">
                                    <Typography>ʝ</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="fourtyone" onClick={() => setIPAValue("x")}>
                                <div className="symS">
                                    <Typography>x</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="fourtytwo" onClick={() => setIPAValue("ɣ")}>
                                <div className="symS">
                                    <Typography>ɣ</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="fourtythree" onClick={() => setIPAValue("X")}>
                                <div className="symS">
                                    <Typography>X</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="fourtyfour" onClick={() => setIPAValue("ʁ")}>
                                <div className="symS">
                                    <Typography>ʁ</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="fourtyfive" onClick={() => setIPAValue("ħ")}>
                                <div className="symS">
                                    <Typography>ħ</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="fourtysix" onClick={() => setIPAValue("ʕ")}>
                                <div className="symS">
                                    <Typography>ʕ</Typography>
                                </div>
                            </td>
                            <td className="cpvless" id="fourtyseven" onClick={() => setIPAValue("h")}>
                                <div className="symS">
                                    <Typography>h</Typography>
                                </div>
                            </td>
                            <td className="cpvd" id="fourtyeight" onClick={() => setIPAValue("ɦ")}>
                                <div className="symS">
                                    <Typography>ɦ</Typography>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="cpmeta_row">
                                <div className="rowhead">
                                    <Typography>Lateral frikativ</Typography>
                                </div>
                            </td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvdnone">&nbsp;</td>
                            <td className="cpvlessnone" id="fourtynine" onClick={() => setIPAValue("ɬ")}>
                                <div className="symS">
                                    <Typography>ɬ</Typography>
                                </div>
                            </td>
                            <td className="cpvdnone" id="fifty" onClick={() => setIPAValue("ɮ")}>
                                <div className="symS">
                                    <Typography>ɮ</Typography>
                                </div>
                            </td>
                            <td className="cpvlessnone">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                        </tr>
                        <tr>
                            <td className="cpmeta_row">
                                <div className="rowhead">
                                    <Typography fontSize={20}>Approks.</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="fiftyone" onClick={() => setIPAValue("ʋ")}>
                                <div className="symS">
                                    <Typography>ʋ</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvdnone">&nbsp;</td>
                            <td className="cpvlessnone">&nbsp;</td>
                            <td className="cpvdnone" id="fiftytwo" onClick={() => setIPAValue("ɹ")}>
                                <div className="symS">
                                    <Typography>ɹ</Typography>
                                </div>
                            </td>
                            <td className="cpvlessnone">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="fiftythree" onClick={() => setIPAValue("ɻ")}>
                                <div className="symS">
                                    <Typography>ɻ</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="fiftyfour" onClick={() => setIPAValue("j")}>
                                <div className="symS">
                                    <Typography>j</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="fiftyfive" onClick={() => setIPAValue("ɰ")}>
                                <div className="symS">
                                    <Typography>ɰ</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                        </tr>
                        <tr>
                            <td className="cpmeta_row">
                                <div className="rowhead">
                                    <Typography fontSize={20}>Lateral approks.</Typography>
                                </div>
                            </td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvdnone">&nbsp;</td>
                            <td className="cpvlessnone">&nbsp;</td>
                            <td className="cpvdnone" id="fiftysix" onClick={() => setIPAValue("l")}>
                                <div className="symS">
                                    <Typography>l</Typography>
                                </div>
                            </td>
                            <td className="cpvlessnone">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="fiftyseven" onClick={() => setIPAValue("ɭ")}>
                                <div className="symS">
                                    <Typography>ɭ</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="fiftyeight" onClick={() => setIPAValue("ʎ")}>
                                <div className="symS">
                                    <Typography>ʎ</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd" id="fiftynine" onClick={() => setIPAValue("ʟ")}>
                                <div className="symS">
                                    <Typography>ʟ</Typography>
                                </div>
                            </td>
                            <td className="cpvless">&nbsp;</td>
                            <td className="cpvd">&nbsp;</td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                            <td className="cpvlessblank">&nbsp;</td>
                            <td className="cpvdblank">&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Grid>
    );
}
