import { ResultData } from "src/model/ResultData";
import { Scoring, Process, Data } from "../../model/PhoneticProcess";

export class ProcessAnalytics {
    private processSet: Process[];
    readonly data: ResultData;

    constructor(
        processSet: Process[],
        data: ResultData, 
    ) {
        this.data = data;
        this.processSet = processSet;
    }

    public getProcessConfusion(): Process[] {
        return this.processSet;
    }

    public setProcessConfusion(): Process[] {
        let newSet: Process[] = this.processSet;
        let element = this.data;
        let positions: string[] = ["initial", "medial", "final"];

        const phonetic = element.phonetic_transcription.split("-");
        const answer = element.answer.split("-");

        for (let pos of positions) {
            if(pos === "initial") {
                for (let p of this.processSet) {
                    for (let c of p.process.confusion) {                        
                        if(c.expected === phonetic[0] && (c.predicted === answer[0] || c.predicted === !(answer[0] !== c.expected))) {                            
                            this.updateProcessSet("initial", p, 1, element);
                            p.collectedScore += 1;
                            newSet = this.processSet;
                        } else 
                        if(p.process.words) {
                            for (let w of p.process.words) {                                
                                if(w.word === element.word && w.position.includes("initial")) {        
                                    this.updateProcessSet("initial", p, 0, element);
                                    p.collectedScore +=1;
                                    newSet = this.processSet;
                                    
                                }                                    
                            }
                        }
                    }   
                }
            } else

            if(pos === "medial") {
                for (let p of this.processSet) {
                    for (let c of p.process.confusion) {
                        for( let i: number = 1; i <= (answer.length - 2); i ++) {
                            if(c.expected === phonetic[i] && (c.predicted === answer[i] || c.predicted === !(answer[i] !== c.expected))) {
                                this.updateProcessSet("medial", p, 1, element);
                                p.collectedScore += 1;
                                newSet = this.processSet;
                            } else 
                            if(p.process.words) {
                                for (let w of p.process.words) {
                                    if(w.word === element.word && w.position.includes("medial") ) {
                                        this.updateProcessSet("medial", p, 0, element);
                                        p.collectedScore +=1;
                                        newSet = this.processSet;
                                    } 
                                }
                            }
                        }
                    }
                }
            } else

            if(pos === "final") {
    
                for (let p of newSet) {
                    for (let c of p.process.confusion) {
                        if(c.expected === phonetic[phonetic.length - 1] && (c.predicted === answer[answer.length - 1] || c.predicted === !(answer[answer.length - 1] !== c.expected))) {
                            this.updateProcessSet("final", p, 1, element);
                            p.collectedScore += 1;
                            newSet = this.processSet;
                        }else
                        if(p.process.words) {
                            for (let w of p.process.words) {
                                if(w.word === element.word && w.position.includes("final")) {
                                    this.updateProcessSet("final", p, 0, element);
                                    newSet = this.processSet;
                                    p.collectedScore +=1;
                                    
                                } 
                            }
                        }
                    }   
                }
            }
        }
        return newSet;   
    }
    
          
    public updateProcessSet(pos: "initial" | "medial" | "final", pp: Process, score: number, element: ResultData ): void {
        let tw: Data = {
            id: element.answer_id,
            word: element.word,
            expected: element.phonetic_transcription,
            predicted: element.answer,
            predictedOrt:element.ort_answer,
            link: element.video_id
        };

        let addScore: Scoring = {
            number : element.question_id,
            targetWord : tw,
            score: score
        };

        switch (pos) {
            case 'initial' : {
                let bool = pp.position.initial.find(
                    (obj: Scoring) =>
                        obj.number === addScore.number && 
                        obj.targetWord.word === addScore.targetWord.word && 
                        obj.targetWord.expected === addScore.targetWord.expected &&
                        obj.targetWord.predicted === addScore.targetWord.predicted &&
                        obj.targetWord.link === addScore.targetWord.link
                );
                if(bool && pp.position.initial.find((obj:Scoring) => 
                    obj.number === addScore.number && obj.score === 0 && addScore.score === 1)) {
                 
                    let index = pp.position.initial.findIndex((obj:Scoring) => 
                    obj.number === addScore.number && obj.score === 0 && addScore.score === 1);
                    
                    pp.position.initial.splice(index, 1, addScore);
                
                } 
                
                if(!bool) {
                    pp.position.initial.push(addScore);
                }
                break;
            };

            case 'medial' : {
                let bool = pp.position.medial.find(
                    (obj: Scoring) => 
                        obj.number === addScore.number && 
                        obj.targetWord.word === addScore.targetWord.word && 
                        obj.targetWord.expected === addScore.targetWord.expected &&
                        obj.targetWord.predicted === addScore.targetWord.predicted &&
                        obj.targetWord.link === addScore.targetWord.link 
                );

                if(bool && pp.position.medial.find((obj:Scoring) => 
                obj.number === addScore.number && obj.score === 0 && addScore.score === 1)) {
             
                let index = pp.position.medial.findIndex((obj:Scoring) => 
                obj.number === addScore.number && obj.score === 0 && addScore.score === 1);
                
                pp.position.medial.splice(index, 1, addScore);
            
            } 
                if (!bool) {
                    pp.position.medial.push(addScore);
                }
                break;
            }

            case "final": 
                let bool = pp.position.final.find(
                    (obj: Scoring) =>
                        obj.number === addScore.number && 
                        obj.targetWord.word === addScore.targetWord.word && 
                        obj.targetWord.expected === addScore.targetWord.expected &&
                        obj.targetWord.predicted === addScore.targetWord.predicted &&
                        obj.targetWord.link === addScore.targetWord.link
                );

                if(bool && pp.position.final.find((obj:Scoring) => 
                obj.number === addScore.number && obj.score === 0 && addScore.score === 1)) {
             
                let index = pp.position.final.findIndex((obj:Scoring) => 
                obj.number === addScore.number && obj.score === 0 && addScore.score === 1);
                
                pp.position.final.splice(index, 1, addScore);
            
            } 
                if (!bool) {
                    pp.position.final.push(addScore);
     
                }
                break;
        }
    }
}
