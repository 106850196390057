import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles({
    underTitle: (props) => ({
        fontWeight: "bold",
        marginTop: "20px",
        marginBottom: "10px",
        marginLeft: "20px"
    }),
    text: (props) => ({
        marginLeft: "20px",
        marginBottom: "10px"
    })
});

/**
 * Container for displaying user instructions.
 * */
export default function ManualCard({ inputText }) {
    const classes = useStyles();
    return (
        <div>
            <Typography className={classes.underTitle}>{inputText.text[0]}</Typography>
            {inputText.text.slice(1, inputText.length).map((point, index) => {
                return (
                    <Typography key={index} className={classes.text}>
                        {index + 1}. {point}
                    </Typography>
                );
            })}
        </div>
    );
}
