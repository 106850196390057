//React
import { Grid } from "@mui/material";
import React from "react";
import DrawerBar from "../components/Navigation/DrawerBar";
import Content from "../components/Test/Therapist/Content";

export default function TherapistView() {
    return (
        <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
                <DrawerBar />
            </Grid>
            <Grid item>
                <Content />
            </Grid>
        </Grid>
    );
}
