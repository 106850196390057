/**
 * Text for the navigation component
 */
const links = {
    home: "Digital Fonemtest"
};

const dialog = {
    yes: "Ja, logg ut",
    no: "Nei, ikke logg ut",
    title: "Logg ut",
    text: "Du ønsker du å logge ut?"
};

const routes = {
    home: "Hjem",
    test: "Start test",
    analyze: "Analyser",
    settings: "Innstillinger",
    manual: "Manual",
    rule_settings: "Rule settings"
};
const buttons = {
    logout: "Logg ut"
};

export const NavText = {
    links,
    routes,
    dialog,
    buttons
};
