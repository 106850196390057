import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

//Services
import QuestionService from "../../../Services/QuestionService";
import TestService from "../../../Services/TestService";
import { TestText } from "../../../text/TestText";
import ExistingTp from "./ExistingTp";
import RegisterNewTp from "./RegisterNewTp";
import { TestSteps, TestStepsContent, StepperStyles } from "../../../utils/StepperFunctions";
import { Button, Container, Divider, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../../theme";
import { TestParticipant } from "src/Services/TestParticipantService";

const useStyles = makeStyles({
    divider: (props) => ({
        borderColor: "#000",
        borderWidth: "0.5px",
        marginRight: "10%",
        border: "solid"
    }),

    button: (props) => ({
        width: "300px",
        padding: DnfTheme.spacing(4),
        borderRadius: 10,
        color: "#000",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "rgb(36, 150, 145, 0.75)",
            color: "#FFF"
        },
        "&:active": {
            backgroundColor: "rgb(36, 150, 145, 0.75)",
            color: "#FFF"
        },
        "&:focus": {
            backgroundColor: "rgb(36, 150, 145, 0.75)",
            color: "#FFF"
        }
    }),
    nonActive: (props) => ({
        margin: DnfTheme.spacing(1),
        padding: DnfTheme.spacing(4),
        backgroundColor: "#818181",
        color: "#fff",
        width: "300px",
        borderRadius: 10,
        textTransform: "none"
    }),
    active: (props) => ({
        margin: DnfTheme.spacing(1),
        padding: DnfTheme.spacing(4),
        backgroundColor: "#147D24CD",
        color: "#fff",
        width: "300px",
        borderRadius: 10,
        textTransform: "none",
        "&:hover": {
            backgroundColor: "rgba(38,171,62,0.8)",
            color: "#FFF"
        }
    })
});
/**
 * Main file TestSetup directory. Renders existingTp.js and RegisterNewTp.js.
 * */
export default function Content() {
    const classes = useStyles();
    const history = useHistory();
    const [cookies, ,] = useCookies(["jwt", "id_sp"]);
    const steps = TestSteps();
    const steppers = StepperStyles();
    let id_test = localStorage.getItem("id_test");
    const [activeStep, setActiveStep] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [newTp, setNewTp] = useState(false);
    const [tp, setTp] = useState<TestParticipant>();
    const [tpId, setTpId] = useState<number>();
    const [startTest, setStartTest] = useState(false);
    const [id, setId] = useState<number>(-1);

    //Handle  button
    const returnButtonClick = useCallback(() => {
        if (id_test !== null) {
            setDisabled(false);
        } else setDisabled(true);
    }, [id_test]);

    useEffect(() => {
        //Creates new test.
        const createTest = async () => {
            if (!tp?.id_tp && !tpId) {
                console.error("tp not set");
                return;
            } else if (!tp?.id_tp && tpId) {
                localStorage.setItem("id_tp", tpId.toString());
                setId(tpId);
            } else if (tp?.id_tp && !tpId) {
                localStorage.setItem("id_tp", tp.id_tp.toString());
                setId(tp.id_tp);
            }

            TestService.createNewTest(Number(cookies.id_sp), id, cookies.jwt)
                .then((res) => {
                    if (res.status === 200) {
                        let id_test = res.data.insertId;
                        createNewQuestionList(id_test)
                            .then((res) => {
                                if (res) {
                                    // TODO: Move to context
                                    localStorage.setItem("image", "0");
                                    localStorage.setItem("id_test", id_test);
                                    let link = "https://dnf.hf.ntnu.no/#/tp";
                                    history.push("/test");
                                    window.open(link);
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                                throw err;
                            });
                    } else {
                        console.log("nei");
                    }
                })
                .catch((err) => console.log(err));
        };

        returnButtonClick();
        if (startTest) {
            createTest();
        }
    }, [cookies.id_sp, cookies.jwt, history, tp, returnButtonClick, startTest, id_test, tpId, id]);

    //Opens page with ongoing test
    const returnToTest = () => {
        if (localStorage.getItem("image") === null) {
            setDisabled(true);
        } else {
            history.push("/test");
        }
        console.log(localStorage.getItem("image"), localStorage.getItem("id_test"));
    };

    //Create new question list for new test.
    const createNewQuestionList = async (id_test) => {
        let testId = !id_test ? 0 : id_test;

        try {
            await QuestionService.createQuestionList(testId);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    return (
        <Container>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel
                            StepIconProps={{
                                sx: { color: "#206b64" }
                            }}>
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Grid container direction="row">
                <Grid container item direction="column" justifyContent="center" alignContent="center">
                    <Typography variant="h4" className={steppers.instructions}>
                        {activeStep === 1
                            ? newTp
                                ? TestText.stepsContent.sc1NewTp
                                : TestText.stepsContent.sc1OldTp
                            : TestStepsContent(activeStep)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid direction="row" container justifyContent="center" alignContent="center">
                <Grid item direction="column" justifyContent="center" alignContent="center" xs={5}>
                    <Grid item>
                        <Button
                            className={classes.button}
                            onClick={() => {
                                setNewTp(false);
                                setActiveStep(1);
                            }}>
                            <Typography>{TestText.buttons.existingTp}</Typography>
                        </Button>
                    </Grid>
                    <Grid>
                        <Button
                            className={classes.button}
                            onClick={() => {
                                setNewTp(true);
                                setActiveStep(1);
                            }}>
                            <Typography>{TestText.buttons.newTp}</Typography>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={disabled}
                            onClick={returnToTest}
                            className={disabled ? classes.nonActive : classes.active}>
                            <Typography>
                                {disabled ? TestText.buttons.noActiveTest : TestText.buttons.continueTest}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Divider flexItem className={classes.divider} variant="fullWidth" orientation="vertical" />
                <Grid item direction="column" justifyContent="center" alignContent="center" xs={5}>
                    {newTp ? (
                        <Grid item>
                            <RegisterNewTp
                                setTestParticipant={setTpId}
                                setActiveStep={setActiveStep}
                                setStartTest={setStartTest}
                            />
                        </Grid>
                    ) : (
                        <Grid item>
                            <ExistingTp
                                setTestParticipant={setTp}
                                setActiveStep={setActiveStep}
                                setStartTest={setStartTest}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}
