/**
 * Text for analyze components
 */
const steps = {
    step0: "Velg kandidat",
    step1: "Velg test for videre analyse",
    step2: "Analyser resultat"
};

const stepsContent = {
    sC0: "Velg et navn fra lista",
    sC1: "Klikk på en tidligere gjennomført test for en nærmere kikk",
    sC2: "Resultat",
    defualt: "default"
};

const chooseTest = {
    'row': 'Test ID',
    'row2': 'Gjenommførings dato',
    'row3': 'Velg',
};

const noTest = {
    line0: "Du har ikke gjennomført noen tester 🙂 ",
    line1: "Velg",
    line2: "START TEST",
    line3: "i meny lista for å gjennomføre en fonemtest."
};

const reviewTest = {
    transcription: "Realisert transkripsjon ",
    fon: "Forventet transkripsjon"
};

const buttons = {
    goBack: "Tilbake",
    next: "Neste",
    changeTranscription: "Endre transkripsjon"
};

const constants = {
    final: {
        predicted: [
            "I/R",
            "∅",
            "p",
            "b",
            "t",
            "d",
            "ʈ",
            "ɖ",
            "k",
            "ɡ",
            "f",
            "v",
            "s",
            "ʃ",
            "ç",
            "h",
            "m",
            "n",
            "ɳ",
            "ŋ",
            "l",
            "ɭ",
            "ɾ",
            "j",
            "pt",
            "ps",
            "ts",
            "ʈʃ",
            "kt",
            "ks",
            "ɡd",
            "mp",
            "mt",
            "mp",
            "md",
            "mf",
            "ms",
            "nt",
            "ns",
            "ɳʈ",
            "ɳʃ",
            "ŋt",
            "ŋk",
            "ŋs",
            "mn",
            "ŋn",
            "ɾp",
            "ɾb",
            "ɾd",
            "ɾk",
            "ɾɡ",
            "ɾf",
            "ɾv",
            "ɾm",
            "ɾj",
            "ft",
            "fs",
            "sp",
            "st",
            "sk",
            "ʃʈ",
            "ʃk",
            "vd",
            "vn",
            "vl",
            "lp",
            "lt",
            "lk",
            "lɡ",
            "lf",
            "ls",
            "lv",
            "lm",
            "ln",
            "lj",
            "psk",
            "tsk",
            "kst",
            "ksk",
            "mpt",
            "mst",
            "msk",
            "nst",
            "nsk",
            "ŋkt",
            "ŋks",
            "ŋst",
            "ɾpt",
            "ɾps",
            "ɾkt",
            "ɾmt",
            "ɾft",
            "fst",
            "skt",
            "ʃkt",
            "vnt",
            "lkt",
            "lmt",
            "lft",
            "lst",
            "lsk",
            "tot"
        ],
        expected: [
            "∅",
            "p",
            "b",
            "t",
            "d",
            "ʈ",
            "ɖ",
            "k",
            "ɡ",
            "f",
            "v",
            "s",
            "ʃ",
            "ç",
            "h",
            "m",
            "n",
            "ɳ",
            "ŋ",
            "l",
            "ɭ",
            "ɾ",
            "j",
            "pt",
            "ps",
            "ts",
            "ʈʃ",
            "kt",
            "ks",
            "ɡd",
            "mp",
            "mt",
            "mp",
            "md",
            "mf",
            "ms",
            "nt",
            "ns",
            "ɳʈ",
            "ɳʃ",
            "ŋt",
            "ŋk",
            "ŋs",
            "mn",
            "ŋn",
            "ɾp",
            "ɾb",
            "ɾd",
            "ɾk",
            "ɾɡ",
            "ɾf",
            "ɾv",
            "ɾm",
            "ɾj",
            "ft",
            "fs",
            "sp",
            "st",
            "sk",
            "ʃʈ",
            "ʃk",
            "vd",
            "vn",
            "vl",
            "lp",
            "lt",
            "lk",
            "lɡ",
            "lf",
            "ls",
            "lv",
            "lm",
            "ln",
            "lj",
            "psk",
            "tsk",
            "kst",
            "ksk",
            "mpt",
            "mst",
            "msk",
            "nst",
            "nsk",
            "ŋkt",
            "ŋks",
            "ŋst",
            "ɾpt",
            "ɾps",
            "ɾkt",
            "ɾmt",
            "ɾft",
            "fst",
            "skt",
            "ʃkt",
            "vnt",
            "lkt",
            "lmt",
            "lft",
            "lst",
            "lsk"
        ]
    },
    initial: {
        predicted: [
            "I/R",
            "∅",
            "p",
            "b",
            "t",
            "d",
            "ʈ",
            "ɖ",
            "k",
            "ɡ",
            "f",
            "v",
            "s",
            "ʃ",
            "ç",
            "h",
            "m",
            "n",
            "ɳ",
            "ŋ",
            "l",
            "ɭ",
            "ɾ",
            "j",
            "pl",
            "bl",
            "kl",
            "ɡl",
            "fl",
            "ʃl",
            "pɾ",
            "bɾ",
            "tɾ",
            "dɾ",
            "kɾ",
            "ɡɾ",
            "fɾ",
            "vɾ",
            "pj",
            "bj",
            "tj",
            "dj",
            "fj",
            "mj",
            "tv",
            "dv",
            "kv",
            "sv",
            "kn",
            "ɡn",
            "fn",
            "sp",
            "st",
            "sk",
            "sf",
            "sm",
            "sn",
            "spl",
            "skl",
            "spɾ",
            "stɾ",
            "skɾ",
            "spj",
            "stj",
            "skv",
            "tot"
        ],
        expected: [
            "∅",
            "p",
            "b",
            "t",
            "d",
            "ʈ",
            "ɖ",
            "k",
            "ɡ",
            "f",
            "v",
            "s",
            "ʃ",
            "ç",
            "h",
            "m",
            "n",
            "ɳ",
            "ŋ",
            "l",
            "ɭ",
            "ɾ",
            "j",
            "pl",
            "bl",
            "kl",
            "ɡl",
            "fl",
            "ʃl",
            "pɾ",
            "bɾ",
            "tɾ",
            "dɾ",
            "kɾ",
            "ɡɾ",
            "fɾ",
            "vɾ",
            "pj",
            "bj",
            "tj",
            "dj",
            "fj",
            "mj",
            "tv",
            "dv",
            "kv",
            "sv",
            "kn",
            "ɡn",
            "fn",
            "sp",
            "st",
            "sk",
            "sf",
            "sm",
            "sn",
            "spl",
            "skl",
            "spɾ",
            "stɾ",
            "skɾ",
            "spj",
            "stj",
            "skv"
        ]
    },
    medial: {
        predicted: [
            "I/R",
            "∅",
            "p",
            "b",
            "t",
            "d",
            "ʈ",
            "ɖ",
            "k",
            "ɡ",
            "f",
            "v",
            "s",
            "ʃ",
            "ç",
            "h",
            "m",
            "n",
            "ɳ",
            "ŋ",
            "l",
            "ɭ",
            "ɾ",
            "j",
            "pl",
            "bl",
            "kl",
            "ɡl",
            "fl",
            "ʃl",
            "pɾ",
            "bɾ",
            "tɾ",
            "dɾ",
            "kɾ",
            "ɡɾ",
            "fɾ",
            "vɾ",
            "pj",
            "bj",
            "tj",
            "dj",
            "fj",
            "mj",
            "tv",
            "dv",
            "kv",
            "sv",
            "kn",
            "ɡn",
            "fn",
            "sp",
            "st",
            "sk",
            "sf",
            "sm",
            "sn",
            "spl",
            "skl",
            "spɾ",
            "stɾ",
            "skɾ",
            "spj",
            "stj",
            "skv",
            "tot"
        ],
        expected: [
            "∅",
            "p",
            "b",
            "t",
            "d",
            "ʈ",
            "ɖ",
            "k",
            "ɡ",
            "f",
            "v",
            "s",
            "ʃ",
            "ç",
            "h",
            "m",
            "n",
            "ɳ",
            "ŋ",
            "l",
            "ɭ",
            "ɾ",
            "j",
            "pl",
            "bl",
            "kl",
            "ɡl",
            "fl",
            "ʃl",
            "pɾ",
            "bɾ",
            "tɾ",
            "dɾ",
            "kɾ",
            "ɡɾ",
            "fɾ",
            "vɾ",
            "pj",
            "bj",
            "tj",
            "dj",
            "fj",
            "mj",
            "tv",
            "dv",
            "kv",
            "sv",
            "kn",
            "ɡn",
            "fn",
            "sp",
            "st",
            "sk",
            "sf",
            "sm",
            "sn",
            "spl",
            "skl",
            "spɾ",
            "stɾ",
            "skɾ",
            "spj",
            "stj",
            "skv"
        ]
    }
};
const vowels = {
    predicted: [
        "I/R",
        "∅",
        "iː",
        "i",
        "yː",
        "y",
        "eː",
        "e",
        "øː",
        "ø",
        "œ",
        "æː",
        "æ",
        "ɑː",
        "ɑ",
        "oː",
        "o",
        "ɔː",
        "ɔ",
        "uː",
        "u",
        "ʉː",
        "ʉ",
        "øy",
        "æi",
        "æʉ",
        "ɑi",
        "oy",
        "ɔy",
        "ui",
        "ʉi",
        "ə",
        "tot"
    ],
    expected: [
        "∅",
        "∅",
        "iː",
        "i",
        "yː",
        "y",
        "eː",
        "e",
        "øː",
        "ø",
        "œ",
        "æː",
        "æ",
        "ɑː",
        "ɑ",
        "oː",
        "o",
        "ɔː",
        "ɔ",
        "uː",
        "u",
        "ʉː",
        "ʉ",
        "øy",
        "æi",
        "æʉ",
        "ɑi",
        "oy",
        "ɔy",
        "ui",
        "ʉi",
        "ə",
    ]
};

export const AnalyzeText = {
    steps,
    stepsContent,
    chooseTest,
    reviewTest,
    noTest,
    buttons,
    constants,
    vowels
};
