import { ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { DnfTheme } from "./theme";

// TODO: Fix usage of ThemeProvider
ReactDOM.render(
    <ThemeProvider theme={DnfTheme}>
        <App />
    </ThemeProvider>,
    document.getElementById("root")
);
