//React
import React from "react";

//Component
import Content from "../components/Test/Participant/Content";

export default function ParticipantView() {
    return (
        <div>
            <Content />
        </div>
    );
}
