//@flow
import axios from "axios";

//common start url.
const url: string = "https://dnf.hf.ntnu.no/api/";

//Axios for analyze service
export const axiosAnalyze = axios.create({
    baseURL: `${url}analyze`
});

//Axios for question service
export const axiosQuestion = axios.create({
    baseURL: `${url}question`
});

//Axios for speech therapist service
export const axiosSpeechTherapist = axios.create({
    baseURL: `${url}speechTherapist`
});

//Axios for test participant service
export const axiosTestParticipant = axios.create({
    baseURL: `${url}testParticipant`
});

//Axios for test service.
export const axiosTest = axios.create({
    baseURL: `${url}test`
});

export const axiosRules = axios.create({
    baseURL: `${url}rules`
});
