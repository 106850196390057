import { Dispatch, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import TestParticipantService, { TestParticipant } from "../../../Services/TestParticipantService";
import { Avatar, CircularProgress, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";

/**
 * Page for choosing test participants.
 * */
type ExistingTpProps = {
    setActiveStep: Dispatch<number>;
    setTestParticipant: Dispatch<TestParticipant>;
    setStartTest: Dispatch<boolean>;
};
export default function ExistingTp({ setActiveStep, setTestParticipant, setStartTest }: ExistingTpProps) {
    const [cookies, ,] = useCookies(["jwt", "id_sp"]);
    const [loading, setLoading] = useState(true);
    const [tpList, setTpList] = useState<TestParticipant[]>([]);

    //Fetches all test participants from db
    useEffect(() => {
        TestParticipantService.getAllTestParticipantsFromSpeechTherapistID(cookies.id_sp, cookies.jwt)
            .then(({ data }) => {
                setTpList(data);
            })
            .catch((e) => console.error(e))
            .finally(() => setLoading(false));
    }, [cookies.id_sp, cookies.jwt]);

    //Sets testParticipant and opens the next page
    const handleButton = useCallback(
        (testParticipant: TestParticipant) => {
            setTestParticipant(testParticipant);
            setStartTest(true);
            setActiveStep(2);
        },
        [setActiveStep, setStartTest, setTestParticipant]
    );

    return (
        <Grid>
            <Grid container>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <List sx={{ width: "100%", maxWidth: 400, minWidth: 400, overflow: "auto", cursor: "pointer" }}>
                        {tpList
                            .sort((a, b) => a.id_tp - b.id_tp)
                            .map((selected) => (
                                <>
                                    <ListItem
                                        key={selected.id_tp}
                                        alignItems="flex-start"
                                        onClick={() => handleButton(selected)}>
                                        <ListItemAvatar key={selected.lastname}>
                                            <Avatar
                                                alt="Remy Sharp"
                                                src="https://pbs.twimg.com/media/EqB8qn1UcAIeSFq.jpg"
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            key={selected.firstname}
                                            primary={selected.lastname + " " + selected.firstname}
                                            secondary={
                                                "Fødselsdato: " + new Date(selected.date_of_birth).toLocaleDateString()
                                            }></ListItemText>
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </>
                            ))}
                    </List>
                )}
            </Grid>
        </Grid>
    );
}
