import React, { createContext, useState } from "react";

/**
 * Context page for passing data between components in test directory
 * */

// TODO: This needs link to question, answer, and participant
// TODO: Move to model file
export interface ClipData {
    id: string;
    videoUrl: string;
    testId: number;
    questionId: number;
}

// TODO: This is not image, but just misused as
export interface ImageData {
    id_test: number; // TODO: rename
    id_question: number; // TODO: rename
    question_nr: number; // TODO: rename
    orthographic_transcription: string;
    phonetic_transcription: string;
}

export interface QuestionData {
    answer: string;
    ort_answer: string;
    test_id: number;
    question_id: number; // TODO: rename
    question_nr: number; // TODO: rename
    image_link: string;
    word: string;
    phonetic_transcription: string;
    orthographic_transcription: string;
}

export interface TestParticipantInfo {
    completion_date: string;
    date_of_birth: string;
    firstname: string;
    id_test: number;
    id_tp: number;
    languages: string;
    lastname: string;
    motherTongue: string;
    parent: string;
}

export interface TherapistContext {
    clipValue?: [ClipData | undefined, React.Dispatch<React.SetStateAction<ClipData | undefined>>];
    clipListValue?: [Array<ClipData>, React.Dispatch<React.SetStateAction<ClipData[]>>];
    questionIndexValue?: [number, React.Dispatch<React.SetStateAction<number>>];
    questionListValue?: [Array<QuestionData>, React.Dispatch<React.SetStateAction<QuestionData[]>>];
    transcriptValue?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    transcribedValue?: [string, React.Dispatch<React.SetStateAction<string>>];
    disabledValue?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    nextValue?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    guideValue?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    tpValue?: [Array<TestParticipantInfo>, React.Dispatch<React.SetStateAction<TestParticipantInfo[]>>];
}

export interface AnalyzeContext {}

export const MyContext = createContext<TherapistContext>({
    clipValue: undefined,
    clipListValue: undefined,
    // imageValue: undefined,
    questionIndexValue: undefined,
    // imageListValue: undefined,
    questionListValue: undefined,
    transcriptValue: undefined,
    transcribedValue: undefined,
    disabledValue: undefined,
    nextValue: undefined,
    guideValue: undefined,
    tpValue: undefined
});

export const MyAnalyzeContext = createContext<AnalyzeContext>({});

export default function Context(props: { children?: React.ReactNode }) {
    const [clip, setClip] = useState<ClipData>();
    const [clipList, setClipList] = useState<ClipData[]>([]);

    // TODO: Handle error
    const [questionIndex, setQuestionIndex] = useState(parseInt(localStorage.getItem("image") ?? ""));
    const [questionList, setQuestionList] = useState<Array<QuestionData>>([]);
    const [transcript, setTranscript] = useState(false);
    const [transcribedVal, setTranscribedValue] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [next, setNext] = useState(false);
    const [openGuide, setOpenGuide] = useState(false);
    const [tpInfo, setTpInfo] = useState<Array<TestParticipantInfo>>([]);

    return (
        <MyContext.Provider
            value={{
                clipValue: [clip, setClip],
                clipListValue: [clipList, setClipList],
                questionIndexValue: [questionIndex, setQuestionIndex],
                questionListValue: [questionList, setQuestionList],
                transcriptValue: [transcript, setTranscript],
                transcribedValue: [transcribedVal, setTranscribedValue],
                disabledValue: [disabled, setDisabled],
                nextValue: [next, setNext],
                guideValue: [openGuide, setOpenGuide],
                tpValue: [tpInfo, setTpInfo]
            }}>
            {props.children}
        </MyContext.Provider>
    );
}
