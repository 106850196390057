export type Data = {
    id: number,
    word: string;
    expected: string;
    predicted: string;
    predictedOrt: string;
    link: string;
}

export type TestedWord = {
    word: string;
    position: string[];
}

export type Scoring = {
    number: number;
    targetWord: Data;
    score: number;
};

export type Position = {
    initial: Scoring[];
    medial: Scoring[];
    final: Scoring[];
};

export type Confusion = {
    expected: string;
    predicted: string | boolean;
};

export type Features = {
    name: string;
    confusion: Confusion[];
    words?: TestedWord[];
};

export type Process = {
    process: Features;
    position: Position;
    collectedScore: number; // Må være satt på forhånd ut i fra ordene som er satt
};

//TODO: Hva gjøres med stavelser


export class PhoneticProcess {
    private phoneticProcessSet: Process[] = [
        {
            process: {
                name: "1. Dentalisering, fronting av velarer",
                confusion: [
                    {
                        expected: "k",
                        predicted: "t"
                    },
                    {
                        expected: "k",
                        predicted: "d"
                    },
                    {
                        expected: "ɡ",
                        predicted: "d"
                    },
                    {
                        expected: "ɡ",
                        predicted: "t"
                    },
                    {
                        expected: "ŋ",
                        predicted: "n"
                    }
                ],
                words: [
                    {
                        word: "gardin",
                        position: [ "initial"]
                    },
                    {
                        word: "gutt",
                        position: [ "initial"]
                    },
                    {
                        word: "ku",
                        position: [ "initial"]
                    },
                    {
                        word: "gaffel",
                        position: [ "initial"]
                    },
                    {
                        word: "garasje",
                        position: [ "initial"]
                    },
                    {
                        word: "kam",
                        position: [ "initial"]
                    },
                    {
                        word: "drage",
                        position: [ "medial"]
                    },
                    {
                        word: "sykkel",
                        position: [ "medial"]
                    },
                    {
                        word: "finger",
                        position: [ "medial"]
                    },
                    {
                        word: "klokke",
                        position: [ "medial"]
                    },
                    {
                        word: "drikke",
                        position: [ "medial"]
                    },
                    {
                        word: "strikke",
                        position: [ "medial"]
                    },
                    {
                        word: "sykebil",
                        position: [ "medial"]
                    },
                    {
                        word: "bok",
                        position: [ "final"]
                    },
                    {
                        word: "tog",
                        position: [ "final"]
                    },
                    {
                        word: "seng",
                        position: [ "final"]
                    },
                    {
                        word: "ballong",
                        position: [ "final"]
                    },
                    {
                        word: "smokk",
                        position: [ "final"]
                    },
    
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "2. Dentalisering, fronting av retroflekser",
                confusion: [
                    {
                        expected: "ʈ",
                        predicted: "t"
                    },
                    {
                        expected: "ʈ",
                        predicted: "d"
                    },
                    {
                        expected: "ɖ",
                        predicted: "d"
                    },
                    {
                        expected: "ɖ",
                        predicted: "t"
                    },
                    {
                        expected: "ɳ",
                        predicted: "n"
                    }
                ],
                words: [
                    {
                        word: "gardin",
                        position: [ "medial"]
                    },
                    {
                        word: "stjerne",
                        position: [ "medial"]
                    },
                    {
                        word: "fort",
                        position: [ "final"]
                    },
                    {
                        word: "tårn",
                        position: [ "final"]
                    },
                    {
                        word: "bjørn",
                        position: [ "final"]
                    },
                    {
                        word: "svart",
                        position: [ "final"]
                    },
                ]
    
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "3. /ʃ/-erstatninger",
                confusion: [
                    {
                        expected: "ʃ",
                        predicted: !"ʃ"
                    }
                ],
                words: [
                    {
                        word: "sjiraff",
                        position: [ "initial"]
                    },
                    {
                        word: "garasje",
                        position: [ "medial"]
                    },
                    {
                        word: "dusj",
                        position: [ "final"]
                    },
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "4. /ç/-erstatninger",
                confusion: [
                    {
                        expected: "ç",
                        predicted: !"ç"
                    }
                ],
                words: [
                    {
                        word: "kirke",
                        position: [ "initial"]
                    },
                    {
                        word: "bikkje",
                        position: [ "medial"]
                    },
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "5. Velarisering, backing av dentaler",
                confusion: [
                    {
                        expected: "t",
                        predicted: "k"
                    },
                    {
                        expected: "t",
                        predicted: "ɡ"
                    },
                    {
                        expected: "d",
                        predicted: "ɡ"
                    },
                    {
                        expected: "d",
                        predicted: "k"
                    }
                ],
                words: [
                    {
                        word: "teve",
                        position: [ "initial"]
                    },
                    {
                        word: "dør",
                        position: [ "initial"]
                    },
                    {
                        word: "tog",
                        position: [ "initial"]
                    },
                    {
                        word: "dusj",
                        position: [ "initial"]
                    },
                    {
                        word: "tårn",
                        position: [ "initial"]
                    },
                    {
                        word: "tøfler",
                        position: [ "initial"]
                    },
                    {
                        word: "telefon",
                        position: [ "initial"]
                    },
                    {
                        word: "dokke",
                        position: [ "initial"]
                    },
                    {
                        word: "tiger",
                        position: [ "initial"]
                    },
                    {
                        word: "potte",
                        position: [ "medial"]
                    },
                    {
                        word: "bade",
                        position: [ "medial"]
                    },
                    {
                        word: "hatt",
                        position: [ "final"]
                    },
                    {
                        word: "redd",
                        position: [ "final"]
                    },
                    {
                        word: "gutt",
                        position: [ "final"]
                    },
                    {
                        word: "vott",
                        position: [ "final"]
                    },
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "6. Klusilering, stopping av frikativer",
                confusion: [
                    {
                        expected: "f",
                        predicted: "p"
                    },
                    {
                        expected: "f",
                        predicted: "b"
                    },
                    {
                        expected: "f",
                        predicted: "t"
                    },
                    {
                        expected: "f",
                        predicted: "d"
                    },
                    {
                        expected: "f",
                        predicted: "ʈ"
                    },
                    {
                        expected: "f",
                        predicted: "ɖ"
                    },
                    {
                        expected: "f",
                        predicted: "k"
                    },
                    {
                        expected: "f",
                        predicted: "ɡ"
                    },
                    {
                        expected: "v",
                        predicted: "p"
                    },
                    {
                        expected: "v",
                        predicted: "b"
                    },
                    {
                        expected: "v",
                        predicted: "d"
                    },
                    {
                        expected: "v",
                        predicted: "t"
                    },
                    {
                        expected: "v",
                        predicted: "ɖ"
                    },
                    {
                        expected: "v",
                        predicted: "ʈ"
                    },
                    {
                        expected: "v",
                        predicted: "ɡ"
                    },
                    {
                        expected: "v",
                        predicted: "k"
                    },
                    {
                        expected: "s",
                        predicted: "t"
                    },
                    {
                        expected: "s",
                        predicted: "p"
                    },
                    {
                        expected: "s",
                        predicted: "d"
                    },
                    {
                        expected: "s",
                        predicted: "b"
                    },
                    {
                        expected: "s",
                        predicted: "ɖ"
                    },
                    {
                        expected: "s",
                        predicted: "ʈ"
                    },
                    {
                        expected: "s",
                        predicted: "ɡ"
                    },
                    {
                        expected: "s",
                        predicted: "k"
                    },
                    {
                        expected: "ʃ",
                        predicted: "t"
                    },
                    {
                        expected: "ʃ",
                        predicted: "p"
                    },
                    {
                        expected: "ʃ",
                        predicted: "d"
                    },
                    {
                        expected: "ʃ",
                        predicted: "b"
                    },
                    {
                        expected: "ʃ",
                        predicted: "ɖ"
                    },
                    {
                        expected: "ʃ",
                        predicted: "ʈ"
                    },
                    {
                        expected: "ʃ",
                        predicted: "ɡ"
                    },
                    {
                        expected: "ʃ",
                        predicted: "k"
                    },
                    {
                        expected: "ç",
                        predicted: "t"
                    },
                    {
                        expected: "ç",
                        predicted: "p"
                    },
                    {
                        expected: "ç",
                        predicted: "d"
                    },
                    {
                        expected: "ç",
                        predicted: "b"
                    },
                    {
                        expected: "ç",
                        predicted: "ɖ"
                    },
                    {
                        expected: "ç",
                        predicted: "ʈ"
                    },
                    {
                        expected: "ç",
                        predicted: "ɡ"
                    },
                    {
                        expected: "ç",
                        predicted: "k"
                    }
                ],
                words: [
                    {
                        word: "sopp",
                        position: [ "initial"]
                    },
                    {
                        word: "fort",
                        position: [ "initial"]
                    },
                    {
                        word: "sykkel",
                        position: [ "initial"]
                    },
                    {
                        word: "fugl",
                        position: [ "initial"]
                    },
                    {
                        word: "sjiraff",
                        position: [ "initial", "final"]
                    },
                    {
                        word: "vott",
                        position: [ "initial"]
                    },
                    {
                        word: "sol",
                        position: [ "initial"]
                    },
                    {
                        word: "kirke",
                        position: [ "initial"]
                    },
                    {
                        word: "finger",
                        position: [ "initial"]
                    },
                    {
                        word: "seng",
                        position: [ "initial"]
                    },
                    {
                        word: "sau",
                        position: [ "initial"]
                    },
                    {
                        word: "visp",
                        position: [ "initial"]
                    },
                    {
                        word: "sykebil",
                        position: [ "initial"]
                    },
                    {
                        word: "sjokolade",
                        position: [ "initial"]
                    },
                    {
                        word: "saks",
                        position: [ "initial"]
                    },
                    {
                        word: "vask",
                        position: [ "initial"]
                    },
                    {
                        word: "løve",
                        position: [ "medial"]
                    },
                    {
                        word: "gaffel",
                        position: [ "medial"]
                    },
                    {
                        word: "teve",
                        position: [ "medial"]
                    },
                    {
                        word: "garasje",
                        position: [ "medial"]
                    },
                    {
                        word: "nese",
                        position: [ "medial"]
                    },
                    {
                        word: "bikkje",
                        position: [ "medial"]
                    },
                    {
                        word: "sløyfe",
                        position: [ "medial"]
                    },
                    {
                        word: "fryse",
                        position: [ "medial"]
                    },
                    {
                        word: "rev",
                        position: [ "final"]
                    },
                    {
                        word: "hus",
                        position: [ "final"]
                    },
                    {
                        word: "dusj",
                        position: [ "final"]
                    },
                    {
                        word: "lys",
                        position: [ "final"]
                    },
                    {
                        word: "glass",
                        position: [ "final"]
                    },
                    {
                        word: "gris",
                        position: [ "final"]
                    },
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "7. Avstemming",
                confusion: [
                    {
                        expected: "b",
                        predicted: "p"
                    },
                    {
                        expected: "b",
                        predicted: "t"
                    },
                    {
                        expected: "b",
                        predicted: "ʈ"
                    },
                    {
                        expected: "b",
                        predicted: "k"
                    },
                    {
                        expected: "d",
                        predicted: "p"
                    },
                    {
                        expected: "d",
                        predicted: "t"
                    },
                    {
                        expected: "d",
                        predicted: "ʈ"
                    },
                    {
                        expected: "d",
                        predicted: "k"
                    },
                    {
                        expected: "ɡ",
                        predicted: "p"
                    },
                    {
                        expected: "ɡ",
                        predicted: "t"
                    },
                    {
                        expected: "ɡ",
                        predicted: "ʈ"
                    },
                    {
                        expected: "ɡ",
                        predicted: "k"
                    }
                ],
                words: [
                    {
                        word: "bok",
                        position: [ "initial"]
                    },
                    {
                        word: "baby",
                        position: [ "initial", "medial"]
                    },
                    {
                        word: "dør",
                        position: [ "initial"]
                    },
                    {
                        word: "gardin",
                        position: [ "initial"]
                    },
                    {
                        word: "gutt",
                        position: [ "initial"]
                    },
                    {
                        word: "gaffel",
                        position: [ "initial"]
                    },
                    {
                        word: "garasje",
                        position: [ "initial"]
                    },
                    {
                        word: "dusj",
                        position: [ "initial"]
                    },
                    {
                        word: "bikkje",
                        position: [ "initial"]
                    },
                    {
                        word: "bade",
                        position: [ "medial"]
                    },
                    {
                        word: "drage",
                        position: [ "medial"]
                    },
                    {
                        word: "nebb",
                        position: [ "final"]
                    },
                    {
                        word: "redd",
                        position: [ "final"]
                    },
                    {
                        word: "tog",
                        position: [ "final"]
                    },
                    
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "8. Stemming",
                confusion: [
                    {
                        expected: "p",
                        predicted: "b"
                    },
                    {
                        expected: "p",
                        predicted: "d"
                    },
                    {
                        expected: "p",
                        predicted: "ɖ"
                    },
                    {
                        expected: "p",
                        predicted: "ɡ"
                    },
                    {
                        expected: "t",
                        predicted: "b"
                    },
                    {
                        expected: "t",
                        predicted: "d"
                    },
                    {
                        expected: "t",
                        predicted: "ɖ"
                    },
                    {
                        expected: "t",
                        predicted: "ɡ"
                    },
                    {
                        expected: "k",
                        predicted: "b"
                    },
                    {
                        expected: "k",
                        predicted: "d"
                    },
                    {
                        expected: "k",
                        predicted: "ɖ"
                    },
                    {
                        expected: "k",
                        predicted: "ɡ"
                    }
                ],
                words :[
                    {
                        word: "potte",
                        position: [ "initial", "medial"]
                    },
                    {
                        word: "teve",
                        position: [ "initial"]
                    },
                    {
                        word: "tog",
                        position: [ "initial"]
                    },
                    {
                        word: "ku",
                        position: [ "initial"]
                    },
                    {
                        word: "kam",
                        position: [ "initial"]
                    },
                    {
                        word: "tårn",
                        position: [ "initial"]
                    },
                    {
                        word: "tiger",
                        position: [ "initial"]
                    },
                    {
                        word: "ape",
                        position: [ "medial"]
                    },
                    {
                        word: "sykkel",
                        position: [ "medial"]
                    },
                    {
                        word: "klokke",
                        position: [ "medial"]
                    },
                    {
                        word: "drikke",
                        position: [ "medial"]
                    },
                    {
                        word: "strikke",
                        position: [ "medial"]
                    },
                    {
                        word: "dokke",
                        position: [ "medial"]
                    },
                    {
                        word: "bok",
                        position: [ "final"]
                    },
                    {
                        word: "hatt",
                        position: [ "final"]
                    },
                    {
                        word: "gutt",
                        position: [ "final"]
                    },
                    {
                        word: "vott",
                        position: [ "final"]
                    },
                    {
                        word: "kvakk",
                        position: [ "final"]
                    },
                    {
                        word: "smokk",
                        position: [ "final"]
                    },
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "9. /l/-erstatninger",
                confusion: [
                    {
                        expected: "l",
                        predicted: !"l"
                    }
                ],
                words: [
                    {
                        word: "løve",
                        position: [ "initial"]
                    },
                    {
                        word: "lomme",
                        position: [ "initial"]
                    },
                    {
                        word: "lys",
                        position: [ "initial"]
                    },
                    {
                        word: "ballong",
                        position: [ "medial"]
                    },
                    {
                        word: "elefant",
                        position: [ "medial"]
                    },
                    {
                        word: "telefon",
                        position: [ "medial"]
                    },
                    {
                        word: "sjokolade",
                        position: [ "medial"]
                    },
                    {
                        word: "helikopter",
                        position: [ "medial"]
                    },
                    {
                        word: "sykkel",
                        position: [ "final"]
                    },
                    {
                        word: "fugl",
                        position: [ "final"]
                    },
                    {
                        word: "gaffel",
                        position: [ "final"]
                    },
                    {
                        word: "sol",
                        position: [ "final"]
                    },
                    {
                        word: "bil",
                        position: [ "final"]
                    },
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "10. /ɾ/-erstatninger",
                confusion: [
                    {
                        expected: "ɾ",
                        predicted: !"ɾ"
                    }
                ],
                words: [
                    {
                        word: "redd",
                        position: [ "initial"]
                    },
                    {
                        word: "rev",
                        position: [ "initial"]
                    },
                    {
                        word: "rød",
                        position: [ "initial"]
                    },
                    {
                        word: "sjiraff",
                        position: [ "medial"]
                    },
                    {
                        word: "garasje",
                        position: [ "medial"]
                    },
                    {
                        word: "øre",
                        position: [ "medial"]
                    },
                    {
                        word: "dør",
                        position: [ "final"]
                    },
                    {
                        word: "hår",
                        position: [ "final"]
                    },
                    {
                        word: "plaster",
                        position: [ "final"]
                    },
                    {
                        word: "nøkler",
                        position: [ "final"]
                    },
                    {
                        word: "tøfler",
                        position: [ "final"]
                    },
                    {
                        word: "fjær",
                        position: [ "final"]
                    },
                    {
                        word: "støvler",
                        position: [ "final"]
                    },
                    {
                        word: "tiger",
                        position: [ "final"]
                    },
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "11. Utelatelser eller h-isering av initial klusil",
                confusion: [
                    {
                        expected: "p",
                        predicted: "Ø"
                    },
                    {
                        expected: "p",
                        predicted: "h"
                    },
                    {
                        expected: "b",
                        predicted: "Ø"
                    },
                    {
                        expected: "b",
                        predicted: "h"
                    },
                    {
                        expected: "t",
                        predicted: "Ø"
                    },
                    {
                        expected: "t",
                        predicted: "h"
                    },
                    {
                        expected: "d",
                        predicted: "Ø"
                    },
                    {
                        expected: "d",
                        predicted: "h"
                    },
                    {
                        expected: "k",
                        predicted: "Ø"
                    },
                    {
                        expected: "k",
                        predicted: "h"
                    },
                    {
                        expected: "ɡ",
                        predicted: "Ø"
                    },
                    {
                        expected: "ɡ",
                        predicted: "h"
                    }
                ],
                words: [
                    {
                        word: "potte",
                        position: [ "initial"]
                    },
                    {
                        word: "bok",
                        position: [ "initial"]
                    },
                    {
                        word: "baby",
                        position: [ "initial"]
                    },
                    {
                        word: "teve",
                        position: [ "initial"]
                    },
                    {
                        word: "dør",
                        position: [ "initial"]
                    },
                    {
                        word: "bade",
                        position: [ "initial"]
                    },
                    {
                        word: "gardin",
                        position: [ "initial"]
                    },
                    {
                        word: "gutt",
                        position: [ "initial"]
                    },
                    {
                        word: "tog",
                        position: [ "initial"]
                    },
                    {
                        word: "ku",
                        position: [ "initial"]
                    },
                    {
                        word: "gaffel",
                        position: [ "initial"]
                    },
                    {
                        word:"garasje",
                        position:["initial"]
                    },
                    {
                        word: "bikkje",
                        position: [ "initial"]
                    },
                    {
                        word: "kam",
                        position: [ "initial"]
                    },
                    {
                        word: "tårn",
                        position: [ "initial"]
                    },
                    {
                        word: "ballong",
                        position: [ "initial"]
                    },
                    {
                        word: "bil",
                        position: [ "initial"]
                    },
                    {
                        word: "kalv",
                        position: [ "initial"]
                    },
                    {
                        word: "tiger",
                        position: [ "initial"]
                    },
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "12. Utelatelser eller h-isering av initial frikativ",
                confusion: [
                    {
                        expected: "f",
                        predicted: "Ø"
                    },
                    {
                        expected: "f",
                        predicted: "h"
                    },
                    {
                        expected: "v",
                        predicted: "Ø"
                    },
                    {
                        expected: "v",
                        predicted: "h"
                    },
                    {
                        expected: "s",
                        predicted: "Ø"
                    },
                    {
                        expected: "s",
                        predicted: "h"
                    },
                    {
                        expected: "ʃ",
                        predicted: "Ø"
                    },
                    {
                        expected: "ʃ",
                        predicted: "h"
                    }
                ],
                words: [
                    {
                        word: "sopp",
                        position: [ "initial"]
                    },
                    {
                        word: "fort",
                        position: [ "initial"]
                    },
                    {
                        word: "sykkel",
                        position: [ "initial"]
                    },
                    {
                        word: "fugl",
                        position: [ "initial"]
                    },
                    {
                        word: "sjiraff",
                        position: [ "initial"]
                    },
                    {
                        word: "vott",
                        position: [ "initial"]
                    },
                    {
                        word: "sol",
                        position: [ "initial"]
                    },
                    {
                        word: "finger",
                        position: [ "initial"]
                    },
                    {
                        word: "seng",
                        position: [ "initial"]
                    },
                    {
                        word: "sau",
                        position: [ "initial"]
                    },
                    {
                        word: "visp",
                        position: [ "initial"]
                    },
                    {
                        word: "sykebil",
                        position: [ "initial"]
                    },
                    {
                        word: "sjokolade",
                        position: [ "initial"]
                    },
                    {
                        word: "vask",
                        position: [ "initial"]
                    },
                    {
                        word: "saks",
                        position: [ "initial"]
                    },
                    {
                        word: "veps",
                        position: [ "initial"]
                    },
    
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "13. Klusterreduksjon, /r/-kombinasjoner",
                confusion: [
                    {
                        expected: "pɾ",
                        predicted: "p"
                    },
                    {
                        expected: "pɾ",
                        predicted: "ɾ"
                    },
                    {
                        expected: "bɾ",
                        predicted: "b"
                    },
                    {
                        expected: "bɾ",
                        predicted: "ɾ"
                    },
                    {
                        expected: "tɾ",
                        predicted: "t"
                    },
                    {
                        expected: "tɾ",
                        predicted: "ɾ"
                    },
                    {
                        expected: "dɾ",
                        predicted: "d"
                    },
                    {
                        expected: "dɾ",
                        predicted: "ɾ"
                    },
                    {
                        expected: "ɡɾ",
                        predicted: "ɡ"
                    },
                    {
                        expected: "ɡɾ",
                        predicted: "ɾ"
                    },
                    {
                        expected: "fɾ",
                        predicted: "f"
                    },
                    {
                        expected: "fɾ",
                        predicted: "ɾ"
                    },
                    {
                        expected: "vɾ",
                        predicted: "v"
                    },
                    {
                        expected: "vɾ",
                        predicted: "ɾ"
                    },
                    {
                        expected: "kɾ",
                        predicted: "ɾ"
                    },
                    {
                        expected: "kɾ",
                        predicted: "k"
                    }
                ],
                words: [
                    {
                        word: "drage",
                        position: [ "initial"]
                    },
                    {
                        word: "brille",
                        position: [ "initial"]
                    },
                    {
                        word: "drikke",
                        position: [ "initial"]
                    },
                    {
                        word: "tromme",
                        position: [ "initial"]
                    },
                    {
                        word: "fryse",
                        position: [ "initial"]
                    },
                    {
                        word: "gris",
                        position: [ "initial"]
                    },
                    {
                        word: "krone",
                        position: [ "initial"]
                    },
                    {
                        word: "prest",
                        position: [ "initial"]
                    },
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "14. Klusterreduksjon, andre kombinasjoner",
                confusion: [
                    {
                        expected: "pj",
                        predicted: "p"
                    },
                    {
                        expected: "pj",
                        predicted: "j"
                    },
                    {
                        expected: "bj",
                        predicted: "b"
                    },
                    {
                        expected: "bj",
                        predicted: "j"
                    },
                    {
                        expected: "tj",
                        predicted: "t"
                    },
                    {
                        expected: "tj",
                        predicted: "j"
                    },
                    {
                        expected: "dj",
                        predicted: "d"
                    },
                    {
                        expected: "dj",
                        predicted: "j"
                    },
                    {
                        expected: "fj",
                        predicted: "f"
                    },
                    {
                        expected: "fj",
                        predicted: "j"
                    },
                    {
                        expected: "mj",
                        predicted: "m"
                    },
                    {
                        expected: "mj",
                        predicted: "j"
                    },
                    {
                        expected: "tv",
                        predicted: "v"
                    },
                    {
                        expected: "tv",
                        predicted: "t"
                    },
                    {
                        expected: "dv",
                        predicted: "v"
                    },
                    {
                        expected: "dv",
                        predicted: "d"
                    },
                    {
                        expected: "kv",
                        predicted: "v"
                    },
                    {
                        expected: "kv",
                        predicted: "k"
                    }
                ],
                words: [
                    {
                        word: "bjørn",
                        position: [ "initial"]
                    },
                    {
                        word: "fjær",
                        position: [ "initial"]
                    },
                    {
                        word: "kvakk",
                        position: [ "initial"]
                    },
                    {
                        word: "tvilling",
                        position: [ "initial"]
                    },
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "15. Klusterreduksjon, /s/-kombinasjoner",
                confusion: [
                    {
                        expected: "sp",
                        predicted: "s"
                    },
                    {
                        expected: "sp",
                        predicted: "p"
                    },
                    {
                        expected: "st",
                        predicted: "s"
                    },
                    {
                        expected: "st",
                        predicted: "t"
                    },
                    {
                        expected: "sk",
                        predicted: "s"
                    },
                    {
                        expected: "sk",
                        predicted: "jk"
                    },
                    {
                        expected: "sm",
                        predicted: "s"
                    },
                    {
                        expected: "sm",
                        predicted: "m"
                    },
                    {
                        expected: "sn",
                        predicted: "s"
                    },
                    {
                        expected: "sn",
                        predicted: "n"
                    },
                    {
                        expected: "spɾ",
                        predicted: "sp"
                    },
                    {
                        expected: "spɾ",
                        predicted: "pr"
                    },
                    {
                        expected: "spɾ",
                        predicted: "s"
                    },
                    {
                        expected: "spɾ",
                        predicted: "p"
                    },
                    {
                        expected: "spɾ",
                        predicted: "ɾ"
                    },
                    {
                        expected: "stɾ",
                        predicted: "st"
                    },
                    {
                        expected: "stɾ",
                        predicted: "tɾ"
                    },
                    {
                        expected: "stɾ",
                        predicted: "s"
                    },
                    {
                        expected: "stɾ",
                        predicted: "t"
                    },
                    {
                        expected: "stɾ",
                        predicted: "ɾ"
                    },
                    {
                        expected: "skɾ",
                        predicted: "sk"
                    },
                    {
                        expected: "skɾ",
                        predicted: "kɾ"
                    },
                    {
                        expected: "skɾ",
                        predicted: "s"
                    },
                    {
                        expected: "skɾ",
                        predicted: "k"
                    },
                    {
                        expected: "skɾ",
                        predicted: "ɾ"
                    },
                    {
                        expected: "spl",
                        predicted: "sp"
                    },
                    {
                        expected: "spl",
                        predicted: "pl"
                    },
                    {
                        expected: "spl",
                        predicted: "s"
                    },
                    {
                        expected: "spl",
                        predicted: "p"
                    },
                    {
                        expected: "spl",
                        predicted: "l"
                    },
                    {
                        expected: "skl",
                        predicted: "sk"
                    },
                    {
                        expected: "skl",
                        predicted: "kl"
                    },
                    {
                        expected: "skl",
                        predicted: "s"
                    },
                    {
                        expected: "skl",
                        predicted: "k"
                    },
                    {
                        expected: "skl",
                        predicted: "l"
                    },
                    {
                        expected: "spj",
                        predicted: "sp"
                    },
                    {
                        expected: "spj",
                        predicted: "pj"
                    },
                    {
                        expected: "spj",
                        predicted: "s"
                    },
                    {
                        expected: "spj",
                        predicted: "p"
                    },
                    {
                        expected: "spj",
                        predicted: "j"
                    },
                    {
                        expected: "stj",
                        predicted: "st"
                    },
                    {
                        expected: "stj",
                        predicted: "tj"
                    },
                    {
                        expected: "stj",
                        predicted: "s"
                    },
                    {
                        expected: "spj",
                        predicted: "t"
                    },
                    {
                        expected: "spj",
                        predicted: "j"
                    },
                    {
                        expected: "skv",
                        predicted: "sk"
                    },
                    {
                        expected: "skv",
                        predicted: "kv"
                    },
                    {
                        expected: "skv",
                        predicted: "s"
                    },
                    {
                        expected: "skv",
                        predicted: "k"
                    },
                    {
                        expected: "skv",
                        predicted: "v"
                    }
                ],
                words: [
                    {
                        word: "sko",
                        position: [ "initial"]
                    },
                    {
                        word: "smokk",
                        position: [ "initial"]
                    },
                    {
                        word: "snø",
                        position: [ "initial"]
                    },
                    {
                        word: "spøkelse",
                        position: [ "initial"]
                    },
                    {
                        word: "stol",
                        position: [ "initial"]
                    },
                    {
                        word: "svart",
                        position: [ "initial"]
                    },
                    {
                        word: "skrive",
                        position: [ "initial"]
                    },
                    {
                        word: "strikke",
                        position: [ "initial"]
                    },
                    {
                        word: "veske",
                        position: [ "medial"]
                    },
                    {
                        word: "plaster",
                        position: [ "medial"]
                    },
                    {
                        word: "fisk",
                        position: [ "final"]
                    },
                    {
                        word: "visp",
                        position: [ "final"]
                    },
                    {
                        word: "hest",
                        position: [ "final"]
                    },
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "16. Klusterreduksjon, /l/-kombinasjoner",
                confusion: [
                    {
                        expected: "pl",
                        predicted: "p"
                    },
                    {
                        expected: "pl",
                        predicted: "l"
                    },
                    {
                        expected: "bl",
                        predicted: "b"
                    },
                    {
                        expected: "bl",
                        predicted: "l"
                    },
                    {
                        expected: "kl",
                        predicted: "k"
                    },
                    {
                        expected: "kl",
                        predicted: "l"
                    },
                    {
                        expected: "ɡl",
                        predicted: "ɡ"
                    },
                    {
                        expected: "ɡl",
                        predicted: "l"
                    },
                    {
                        expected: "fl",
                        predicted: "f"
                    },
                    {
                        expected: "fl",
                        predicted: "l"
                    }
                ],
                words: [
                    {
                        word: "plaster",
                        position: [ "initial"]
                    },
                    {
                        word: "blomst",
                        position: [ "initial"]
                    },
                    {
                        word: "klokke",
                        position: [ "initial"]
                    },
                    {
                        word: "glass",
                        position: [ "initial"]
                    },
                    {
                        word: "fly",
                        position: [ "initial"]
                    },
                    {
                        word: "paraply",
                        position: [ "medial"]
                    },
                    {
                        word: "boble",
                        position: [ "medial"]
                    },
                    {
                        word: "nøkler",
                        position: [ "medial"]
                    },
                    {
                        word: "ugle",
                        position: [ "medial"]
                    },
                    {
                        word: "tøfler",
                        position: [ "medial"]
                    },
                ]
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "17. /s/-erstatninger",
                confusion: [
                    {
                        expected: "s",
                        predicted: !"s"
                    }
                ],
                words: []
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "18. Stavelsesforenkling",
                confusion: [],
                words: []
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        },
        {
            process: {
                name: "19. Metatese",
                confusion: [
                    {
                        expected: "sp",
                        predicted: "ps"
                    },
                    {
                        expected: "ps",
                        predicted: "sp"
                    },
                    {
                        expected: "st",
                        predicted: "ts"
                    },
                    {
                        expected: "ts",
                        predicted: "st"
                    },
                    {
                        expected: "ʃt",
                        predicted: "tʃ"
                    },
                    {
                        expected: "tʃ",
                        predicted: "ʃt"
                    },
                    {
                        expected: "sk",
                        predicted: "ks"
                    },
                    {
                        expected: "ks",
                        predicted: "sk"
                    },
                    {
                        expected: "vl",
                        predicted: "lv"
                    },
                    {
                        expected: "lv",
                        predicted: "vl"
                    }
                ],
                words: []
            },
            position: {
                initial: [],
                medial: [],
                final: []
            },
            collectedScore: 0
        }
    ];
    

    public constructPhoneticProcessSet(): Process[] {
        return this.phoneticProcessSet;
    }
}

