//@flow

import { axiosTestParticipant as axios } from "../Shared/Axios";

export interface TestParticipant {
    id_tp: number;
    firstname: string;
    lastname: string;
    date_of_birth: string;
    motherTongue: string; 
    languages: string; 
    parent: string;
}

class TestParticipantService {
    registerNewTestParticipant(firstname: string, lastname: string, dateOfBirth: string, motherTongue: string, languages: string, parent: string,  token: string) {
        return axios.post(
            "/",
            {
                firstname: firstname,
                lastname: lastname,
                date_of_birth: dateOfBirth,
                motherTongue: motherTongue,
                languages: languages, 
                parent: parent
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    }

    getAllTestParticipantsFromSpeechTherapistID = async (id_sp: number, token: string) => {
        return axios.get<Array<TestParticipant>>("/analyze/" + id_sp, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    getTestParticipant
}

export default new TestParticipantService();
