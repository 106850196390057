import { Rule } from "src/model/Rule";
import { ListEntry } from "./Rules";

type TranscribedValue = {
    string: string;
    position: number;
};

let defaultValue = {
    string: "",
    position: -1
};
export class Checks {
    //Check if current transrcibed value is "k"
    private currentTokenIsK = (current: ListEntry): boolean =>  {
        return current.current.transcription === "k"
    }
    private currentTokenIsG = (current: ListEntry): boolean =>  {
        return current.current.transcription === "g"
    }
    private currentTokenIsY = (current: ListEntry): boolean =>  {
        return current.current.transcription === "y"
    }
    private currentTokenisE = (current: ListEntry): boolean => {
        return current.current.transcription === "e"
    }

    private currentTokenisR = (current: ListEntry): boolean => {
        return current.current.transcription === "r"
    }
    
    // checks for next value
    private nextTokenisI = (current: ListEntry) => {
        return current.next?.current.transcription === "i"
    }
    private nextTokenisY = (current: ListEntry) => {
        return current.next?.current.transcription === "y"
    }
    private nextTokenisEI = (current: ListEntry) => {
        return current.next?.current.transcription === "ei"
    }
    private nextTokenisE = (current: ListEntry) => {
        return current.next?.current.transcription === "e"
    }
    private nextTokenisA = (current: ListEntry) => {
        return current.next?.current.transcription === "a"
    }
    private nextTokenisO = (current: ListEntry) => {
        return current.next?.current.transcription === "o"
    }
    private nextTokenisR = (current: ListEntry) => {
        return current.next?.current.transcription === "r"
    }

    private nextNextTokenisConsonant = (current: ListEntry) => {
        return current.next?.next?.current.token === ("Consonant" || "DoubleConsonant" || "Others" );
    }

    private currentTokenisConsonant = (current: ListEntry) => {
        return current.current.token === "Consonant";
    }

    private nextTokenisConsonant = (current: ListEntry) => {
        return current.next?.current.token === "Consonant";
    }
    
    private innerCheck = (current: ListEntry) => {
       return current.current.transcription[0] === "r" && current.current.token === "Others";
    }
    public setTranscribedValue(current: ListEntry ): TranscribedValue {
        let s = defaultValue;

        if (!current.previous) {
            return {string: "", position: current.current.pos-1 };
        }

        if (!current.previous.previous) {
            return {string: "", position: current.current.pos-1 };
        }

        if (!current.next) {
            return {string: current.current.transcription, position: current.current.pos-1 };
        }

        if(this.currentTokenIsK(current) && (this.nextTokenisI(current)||this.nextTokenisY(current)||this.nextTokenisEI(current))) {
            return {string: "ç", position: current.current.pos-1};
        }

        if(this.currentTokenIsG(current) && (this.nextTokenisI(current)||this.nextTokenisY(current)||this.nextTokenisEI(current))) {
            return {string: "j", position: current.current.pos-1};
        }

        if(this.currentTokenIsY(current) && (this.nextTokenisE(current)||this.nextTokenisA(current)||this.nextTokenisO(current))) {
            return {string: "j", position: current.current.pos-1};
        }

        if(this.currentTokenisE(current) && ((this.nextTokenisR(current))  && this.nextNextTokenisConsonant(current))) {
            return {string: "æ", position: current.current.pos-1};

        } else if ((this.currentTokenisR(current)) && this.currentTokenisE(current.previous) && this.nextTokenisConsonant(current)) {
            return {string: "æ", position: current.previous.current.pos-1};

        } else if (this.currentTokenisConsonant(current) && this.currentTokenisR(current.previous) &&  this.currentTokenisE(current.previous.previous)) {
            return {string: "æ", position: current.previous.previous.current.pos-1};

        } else if (this.currentTokenisE(current) && this.innerCheck(current.next)) {
            return {string: "æ", position: current.current.pos-1};
        
        } else if (this.innerCheck(current) && this.currentTokenisE(current.previous)) {
            return {string: "æ", position: current.previous.current.pos-1};

        }
       return s;
   }

   private ruleFind = (ruleSet: Array<Rule> ,s: string): Rule[] => {
       return ruleSet.filter(x=> x.current === s);
   }

   public recursiveMapping = (ruleSet: Array<Rule>, s: string): string => {
       const n = s.length;
       let str = "";
       let one = s.substring(n-1); // last letter in a word 
       let two = s.substring(n-1, -1); //The whole word but the last 
       let three = s.substring(n-n,n-(n-1));  // first letter in word
       let four  = s.substring(n,n-(n-1)); // The whole word but the first 

       const getResultsIn = (ruleSet: Rule[], s:string):  string => {           
           const rs = this.ruleFind(ruleSet,s);   
           if (s === "") {
               return"";
           }
                   
           if(rs.length === 0) {
               return "";
           }
           return rs[0].resultsIn;
       };

       let rule2 = this.ruleFind(ruleSet,two);       
       
       if (rule2.length > 0) { //rule1.length > 0 alltid true?
            if (getResultsIn(ruleSet,two) === getResultsIn(ruleSet,one)) {
                 str = getResultsIn(ruleSet,two);
            } else {
                const rm = two + one;
                const rule = getResultsIn(ruleSet,rm);
              
                if(rule || rule !== "") {
                    str = rule;
                } else {
                    str = getResultsIn(ruleSet,two)  + getResultsIn(ruleSet,one);
                }
            }            
        } else {

            if (three === "") {
                console.log("inn her ? ");
                
                str = getResultsIn(ruleSet, four);

            } else

            if (four === "") {
                console.log("eller inn her ? ");

                str = getResultsIn(ruleSet, three);

            } else {
                str = getResultsIn(ruleSet,three) + this.recursiveMapping(ruleSet,four);
            }
        }
    return str;
   }
}
