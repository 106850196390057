import React, { Dispatch } from "react";
import { Dialog, DialogTitle, DialogContentText, DialogActions, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../theme";

const useStyles = makeStyles({
    text: (props) => ({}),

    dialog: (props) => ({
        justifyContent: "center"
    }),
    button: (props) => ({
        margin: DnfTheme.spacing(1),
        padding: DnfTheme.spacing(2),
        backgroundColor: "#112139",
        color: "#ffffff",
        borderRadius: 10,
        minWidth: "95px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#315085",
            color: "#FFF"
        }
    })
});

/**
 * Container for pop up dialogs
 * */
export default function DialogContainer(props: {
    isOpen: boolean;
    handleClose: (val: any) => void;
    title: string;
    text: string;
    buttonTextList: Array<string>;
    extraFunc: Dispatch<boolean>; // TODO: any
    extraFuncText?: any; // TODO: any
}) {
    const { isOpen, handleClose, title, text, buttonTextList, extraFunc, extraFuncText } = props;
    const classes = useStyles();
    const handleButtonClick = () => {
        extraFunc(true);
        handleClose(true);
    };

    return (
        <div>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContentText>{text}</DialogContentText>
                <DialogActions>
                    {extraFunc === undefined ? (
                        <div>
                            <Button className={classes.button} onClick={() => handleClose(false)}>
                                {buttonTextList[0]}
                            </Button>
                            <Button className={classes.button} onClick={() => handleClose(true)}>
                                {buttonTextList[1]}
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Button className={classes.button} onClick={() => handleClose(true)}>
                                {buttonTextList[0]}
                            </Button>
                            <Button className={classes.button} onClick={handleButtonClick}>
                                {buttonTextList[1] || extraFuncText}
                            </Button>
                        </div>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}
