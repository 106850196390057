import { ListEntry } from "./Rules";

type VowelLength = {
    isLong: boolean;
    position: number;
};

type CurrentVowelLength = {
    string: string;
    position: number;
}

let defaultVowelLength = {
    isLong: true, 
    position: -1
};


export class VowelChecks {
    // checks for current trancribed value 
    private currentTokenIsConsonant = (current: ListEntry): boolean =>  {
        return current.current.token === "Consonant";
    }

    private currentTokenIsDoubleConsonant = (current: ListEntry): boolean =>  {
        return current.current.token === "DoubleConsonant";
    }

    private currentTokenIsOther= (current: ListEntry): boolean =>  {
        return current.current.token === "Others";
    }

    private currentTokenIsVowel= (current: ListEntry): boolean =>  {
        return current.current.token === "Vowel";
    }

    private currentTokenIsDipthong= (current: ListEntry): boolean =>  {
        return current.current.token === "Diphthong";
    }

    // checks for previous trancribed value 

    private previousTokenisVowel = (current: ListEntry) => {
        return current.previous?.current.token === "Vowel";
    }

    private previousTokenisDiphthong = (current: ListEntry) => {
        return current.previous?.current.token === "Diphthong";
    }

    // checks for next transcribed value

    private nextTokenisVowel = (current: ListEntry) => {
        return current.next?.current.token === "Vowel";
    }

    private nextTokenisDiphthong = (current: ListEntry) => {
        return current.next?.current.token === "Diphthong";
    }

    private nextTokenisConsonant = (current: ListEntry) => {
        return current.next?.current.token === "Consonant";
    }

    private nextTokenisDoubleConsonant = (current: ListEntry) => {
        return current.next?.current.token === "DoubleConsonant";
    }

    private nextTokenisOthers = (current: ListEntry) => {
        return current.next?.current.token === "Others";
    }

    private isLongVowel(current: ListEntry): VowelLength {        
        let res = defaultVowelLength;

        if (!current.next) {
            return {isLong: true, position: current.current.pos -1};

        }

        if (!current.previous) {
            return {isLong: false, position: current.current.pos -1};

        }

        if (this.currentTokenIsVowel(current)) {                 
            res.position = current.current.pos-1;
            if((this.nextTokenisDoubleConsonant(current) || this.nextTokenisOthers(current))) {      
                res.isLong = false;
            }else if((this.nextTokenisConsonant(current) && this.nextTokenisConsonant(current) )) {   
                res.isLong = false;
            } else if( current.next.current.transcription.toLocaleLowerCase() === "m") {
                res.isLong = false;
            } else {
                res.isLong = true;
            }
        
        } 
        
        if (this.currentTokenIsDipthong(current)) {
            res.position = current.current.pos-1;
            if((this.nextTokenisDoubleConsonant(current) || this.nextTokenisOthers(current))) {
                res.isLong = false;
                
            } else if((this.nextTokenisConsonant(current) && this.nextTokenisConsonant(current) )) {
                res.isLong = false;
            
            } else if( current.next.current.transcription.toLocaleLowerCase() === "m") {
                res.isLong = false;
            } else {
                res.isLong = true;
            }
        } 
        
        if(this.currentTokenIsConsonant(current) && (this.previousTokenisDiphthong(current) || this.previousTokenisVowel(current))) {
            res.position = current.previous?.current.pos-1;
            res.isLong = false;
        }

        if(this.currentTokenIsConsonant(current) && this.nextTokenisConsonant(current) && (this.previousTokenisDiphthong(current) || this.previousTokenisVowel(current))) {
            res.position = current.previous?.current.pos-1;
            res.isLong = false;
        }

        if(this.currentTokenIsConsonant(current) && this.nextTokenisDoubleConsonant(current) && (this.previousTokenisDiphthong(current) || this.previousTokenisVowel(current))) {
            res.position = current.previous?.current.pos-1;
            res.isLong = false;
        }

        if(this.currentTokenIsConsonant(current) && this.nextTokenisOthers(current) && (this.previousTokenisDiphthong(current) || this.previousTokenisVowel(current))) {
            res.position = current.previous?.current.pos-1;
            res.isLong = false;
        }

        if(this.currentTokenIsConsonant(current)) {
            res.position = current.previous?.current.pos-1;
            if((this.previousTokenisDiphthong(current) || this.previousTokenisVowel(current)) && (this.nextTokenisConsonant(current) || this.nextTokenisDoubleConsonant(current) || this.nextTokenisOthers(current))) {                    
                res.isLong = false;
            } else if( current.current.transcription.toLocaleLowerCase() === "m") {
                res.isLong = false;
            }else {
                res.isLong = true;
            }
        } 
        
        if(this.currentTokenIsDoubleConsonant(current)) {
            res.position = current.previous?.current.pos-1;
            if((this.previousTokenisDiphthong(current) || this.previousTokenisVowel(current))) {
                res.isLong = false;
            } else if( current.current.transcription.toLocaleLowerCase() === "m") {
                res.isLong = false;
            } else {
                res.isLong = true;
            }
        } 
        
        if(this.currentTokenIsOther(current)) {  
            res.position = current.previous?.current.pos-1;          
            if((this.previousTokenisDiphthong(current) || this.previousTokenisVowel(current))) {
               res.isLong = false;
            } else if( current.current.transcription[0].toLocaleLowerCase() === "m") {
                res.isLong = false;
            } else {
                res.isLong = true;
            }
        }

        return res;
    }
    
    public setVowelLength(current: ListEntry, mappedWord: string[]): CurrentVowelLength {
        let res: string = ""; 

        if(!current.previous?.current) {
            return {string: current.current.transcription, position: current.current.pos-1};
        }
        if(!current.next?.current) {
            return {string: current.current.transcription, position: current.current.pos-1};
        }

        let pos = this.isLongVowel(current).position;
        
        if(this.isLongVowel(current).isLong) {
            console.log(this.isLongVowel(current).position, this.isLongVowel(current).isLong);
            const letter = mappedWord[pos].replace("ː", "");
            res = letter + "ː";
            return {string: res, position: pos} 

        } else {
            const letter = mappedWord[pos].replace("ː", "");
            res = letter;
            return {string: res, position: pos} 

        }
    }
}