/**
 * Text for setup test components
 */

const endPageParticipant = {
    title: "Godt jobbet!",
    text: "Du har gjennomført norsk fonemtest."
};

const info = {
    tp: "Kandidat: ",
    dateOfBirth: "Fødselsdato: ",
    image: "Ord: ",
    titleEndPage: "Avslutt eller gå videre til analysering for å analysere resultat",
    ort: "Ortografisk",
    fon: "Fonetisk",
    tt: "Ipa chart"
};

const feedback = {
    invalidBirthDate: "Fødselsdato er ikke gyldig!",
    tpExist: "Testperson ligger allerede i systemet!",
    clipAlreadySaved: "Klippet er allerede lagret!",
    noTranscription: "Kan ikke gå videre uten en transkripsjon!",
    success: "Svaret ble registrert!",
    error: "Noe gikk galt"
};

const alert = {
    title: "Advarsel!"
};

//Setup
const startTestDialog = {
    title: "Vil du start testen?",
    text: "Det tar litt tid å sette opp testen. Ikke skru av."
};

//Therapist
const finishTestDialog = {
    title: "Vil du fullføre testen?",
    text: "Testen vil arkiveres. Du finner den i listen over mulige tester å analysere.",
    goBack: "Nei, gå tilbake",
    finish: "Ja, fullfør test"
};

//Therapist
const cancelTestDialog = {
    title: "Vil du avslutte testen?",
    text: "Testen vil ikke kunne gjennopptas på et senere tidspunkt.",
    goBack: "Nei, gå tilbake",
    cancel: "Ja, avslutt test"
};

const textFields = {
    label: {
        firstname: "Fornavn",
        lastname: "Etternavn",
        dateOfBirth: "Fødselsdato",
        motherTongue: "Morsmål",
        otherLanguages: "Andre språk",
        parent: "Navn foresatt",
        testleder: "Testleder",
        testDato: "Testdato"
    },
    placeHolder: {
        firstname: "Fyll inn fornavn",
        lastname: "Fyll inn etternavn",
        dateOfBirth: "Fyll inn fødselsdato",
        motherTongue: "Velg kandidatens morsmål",
        otherLanguages: "",
        parent: "Fyll inn foresatt navn",
        testleder: "Testleder",
        testDato: "Testdato"
    },
    language: [
        "Norsk", 
        "Engelsk",
        "Svensk",
        "Dansk",
        "Finsk",
        "Annet"
    ]
};

const testInformation = {
    title: "Test informasjon: ",
    name: "Navn: ",
    dateOfBirth: "Fødselsdato: ",
    speech_tp: "Test gjennomført av:  "
};

const buttons = {
    //Setup
    goBack: "Avbryt",
    register: "Registrer bruker og start test",
    start: "Start test",
    newTp: "Registrer ny testkandidat",
    existingTp: "Eksisterende testkandidat",
    continueTest: "Returner til test",
    noActiveTest: "Ingen aktiv test",

    //Control bar
    previous: "Forrige",
    correct: "Riktig",
    imitation: "Imitasjon",
    next: "Neste",
    nextGIF : "Neste, vis GIF",
    repeat: "Gjenta",
    stop: "Avslutt",

    //End page
    newTest: "Start en ny test",
    analyze: "Gå til analyse",
    home: "Til hovedsiden"
};

const steps = {
    step0: "Start",
    step1: "Kandidat",
    step2: "Gjennomføring",
    step3: "Avslutt"
};

const stepsContent = {
    sc0: "Velg en tidligere kandidat, eller registrer en ny",
    sc1NewTp: "Fyll inn informasjon om testkandidat",
    sc1OldTp: "Velg en kandidat. Testen starter automatisk når kandidat er valgt",
    sc3: "Start test",
    sc4: "Avslutt test, eller gå videre for å analysere resultat",
    default: "Unknown step"
};
const videoClip = {
    default: "Ingen opptak registrert",
    onSuccess: "Videoklipp er lagret ",
    onError: "Videoklippet ble ikke lagret "
};

export const TestText = {
    endPageParticipant,
    info,
    feedback,
    alert,
    textFields,
    startTestDialog,
    finishTestDialog,
    cancelTestDialog,
    buttons,
    steps,
    stepsContent,
    videoClip,
    testInformation
};
