import { useHistory } from "react-router-dom";
import { HomeText } from "../../text/HomeText";
import { Grid, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DnfTheme } from "../../theme";

const useStyles = makeStyles({
    gridItem: (props) => ({
        padding: DnfTheme.spacing(2)
    }),
    button: (props) => ({
        margin: DnfTheme.spacing(1),
        padding: DnfTheme.spacing(4),
        backgroundColor: "#112139",
        borderRadius: 10,
        color: "#ffffff",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "rgb(36, 150, 145)",
            color: "#FFF",
            borderColor: "rgb(36, 150, 145)"
        }
    }),
    title: (props) => ({
        fontSize: 65
    }),
    ingress: (props) => ({
        fontSize: 20,
        marginBottom: DnfTheme.spacing(2),
        alignItems: "center",
        marginTop: DnfTheme.spacing(2)
    })
});

/**
 * Landing page after login.
 * */
export default function Home() {
    const classes = useStyles();
    const history = useHistory();

    //Sends user to manual page.
    const handleButton = () => {
        history.push("/about");
    };

    return (
        <Grid style={{ marginTop: "9%" }}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item className={classes.gridItem}>
                    <Typography variant="h1" className={classes.title}>
                        {HomeText.info.title}
                    </Typography>
                </Grid>
                <Grid item className={classes.gridItem}>
                    <Typography variant="h1" className={classes.ingress}>
                        {HomeText.info.quotation}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                <Grid container justifyContent="center" className={classes.gridItem}>
                    <Button variant={"outlined"} className={classes.button} onClick={handleButton}>
                        <Typography>{HomeText.button.text}</Typography>
                    </Button>
                </Grid>
            </Grid>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item className={classes.gridItem}>
                    <Typography variant="h1" className={classes.ingress}>
                        {HomeText.info.ingress}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}
